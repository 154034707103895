import React from "react";

const SvgMenuRoles = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="menu_roles_svg__clip-path">
        <path
          id="menu_roles_svg__Rectangle_1612"
          d="M0 0h24v24H0z"
          data-name="Rectangle 1612"
          transform="translate(411 378)"
          stroke="#707070"
          fill="#fff"
        />
      </clipPath>
      <style>{".menu_roles_svg__cls-3{fill:#fff}"}</style>
    </defs>
    <g
      id="menu_roles_svg__Mask_Group_33"
      data-name="Mask Group 33"
      transform="translate(-411 -378)"
      clipPath="url(#menu_roles_svg__clip-path)"
    >
      <g id="menu_roles_svg__role" transform="translate(411.563 378)">
        <g id="menu_roles_svg__Layer_2" data-name="Layer 2">
          <g id="menu_roles_svg__roles">
            <circle
              id="menu_roles_svg__Ellipse_325"
              cx={4.392}
              cy={4.392}
              r={4.392}
              className="menu_roles_svg__cls-3"
              data-name="Ellipse 325"
              transform="translate(5.563)"
            />
            <path
              id="menu_roles_svg__Path_782"
              d="M0 22.147v2.782h10.609s-.87-4.868 1.737-6.783c0 0-5.217-1.737-12.346 4.001z"
              className="menu_roles_svg__cls-3"
              data-name="Path 782"
              transform="translate(0 -5.625)"
            />
            <path
              id="menu_roles_svg__Path_783"
              d="M23.325 19.306a4.719 4.719 0 104.719 4.715 4.719 4.719 0 00-4.719-4.715zm0 6.8A2.086 2.086 0 1124.8 25.5a2.086 2.086 0 01-1.478.61z"
              className="menu_roles_svg__cls-3"
              data-name="Path 783"
              transform="translate(-5.848 -6.068)"
            />
            <circle
              id="menu_roles_svg__Ellipse_326"
              cx={1.328}
              cy={1.328}
              r={1.328}
              className="menu_roles_svg__cls-3"
              data-name="Ellipse 326"
              transform="translate(16.306 11.911)"
            />
            <circle
              id="menu_roles_svg__Ellipse_327"
              cx={1.328}
              cy={1.328}
              r={1.328}
              className="menu_roles_svg__cls-3"
              data-name="Ellipse 327"
              transform="translate(20.218 14.614)"
            />
            <circle
              id="menu_roles_svg__Ellipse_328"
              cx={1.328}
              cy={1.328}
              r={1.328}
              className="menu_roles_svg__cls-3"
              data-name="Ellipse 328"
              transform="translate(20.218 18.712)"
            />
            <circle
              id="menu_roles_svg__Ellipse_329"
              cx={1.328}
              cy={1.328}
              r={1.328}
              className="menu_roles_svg__cls-3"
              data-name="Ellipse 329"
              transform="translate(16.15 21.345)"
            />
            <circle
              id="menu_roles_svg__Ellipse_330"
              cx={1.328}
              cy={1.328}
              r={1.328}
              className="menu_roles_svg__cls-3"
              data-name="Ellipse 330"
              transform="translate(12.13 18.712)"
            />
            <circle
              id="menu_roles_svg__Ellipse_331"
              cx={1.328}
              cy={1.328}
              r={1.328}
              className="menu_roles_svg__cls-3"
              data-name="Ellipse 331"
              transform="translate(12.13 14.19)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMenuRoles;
