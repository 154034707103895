import { post } from "axios";
import {
  CREATE, DELETE,
  DELETE_MANY, fetchUtils,
  GET_LIST, GET_MANY,
  GET_MANY_REFERENCE, GET_ONE, UPDATE,
  UPDATE_MANY
} from "react-admin";
import LocalStorage from '../../utils/services/storage';
import log from "../../utils/log";

//export const INACTIVE_MANY = 'INACTIVE_MANY'
//export const GET_ASSET_PERMISSION = 'GET_ASSET_PERMISSION'
//export const SAVE_ASSET_PERMISSION = 'SAVE_ASSET_PERMISSION'
export const PUBLISH_MANY = 'PUBLISH_MANY'
export const UNPUBLISH_MANY = 'UNPUBLISH_MANY'

const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file.rawFile);

  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {};

    log.warn(type, resource, params)

    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const payload = {
          page: page - 1,
          limit: perPage,
          ...params.filter
        }
        url = `${apiUrl}/template/search`;
        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      }
      case GET_ONE:
        const payload = {
          id: params.id
        }
        url = `${apiUrl}/template/detail`;
        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      case GET_MANY: {
        options.method = "POST";
        options.body = JSON.stringify(params.ids)
        /*const query = {
          filter: JSON.stringify({ id: params.ids })
        };*/
        // let idStr = "";
        // idStr = params.ids.map(id => idStr + `ids=${id}`);
        url = `${apiUrl}/template/details`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        // log.debug(params)
        const payload = {
          page: page - 1,
          limit: perPage,
          [params.target]: params.id
        }
        url = `${apiUrl}/${resource}/search`;
        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      }
      case UPDATE:
        url = `${apiUrl}/template/update`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/template/create`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;

      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response;
    // log.debug('convertHTTPResponse');
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!json.hasOwnProperty("totalElements")) {
          throw new Error(
            "The numberOfElements property must be must be present in the Json response"
          );
        }
        return {
          data: json.contents,
          total: parseInt(json.totalElements, 10)
        };
      case CREATE:
        //let rsp = { data: { ...params.data, id: json.id } }
        let rsp = { data: { ...json } }
        // log.debug(rsp);
        return rsp;
      default:
        return { data: json };
    }
  };

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
  const requestHandler = (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead

    if (type === PUBLISH_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/template/publish`, {
            method: "POST",
            body: JSON.stringify({ id : id})
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    if (type === UNPUBLISH_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/template/unpublish`, {
            method: "POST",
            body: JSON.stringify({ id : id})
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params)
    ).catch(e => {
      //// log.debug('server resp error')
      //// log.debug(e.status)
      //// log.debug(e.body)
      const errorJson = e.body;
      if (errorJson && errorJson.messageKey)
        throw new Error(errorJson.messageKey);

      else
        throw new Error("error.default.internal.server.error");

    });
  };

  const addUploadFeature = (type, resource, params) => {
    if (type === 'CREATE' || type === 'UPDATE') {

      // if (params.data.iconUrl) {
      //   // only freshly dropped pictures are instance of File
      //   //// log.debug(params.data.iconUrl);
      //   //// log.debug('1');

      //   return convertFileToBase64(params.data.iconUrl)
      //     .then(base64 => {
      //       //// log.debug(base64);
      //       return requestHandler(type, resource, {
      //         ...params,
      //         data: {
      //           ...params.data,
      //           iconUrl: base64,
      //         }
      //       });
      //     });

      // } else {
      //   return requestHandler(type, resource, params);
      // }

      // log.debug(999)

      let url = '';
      if (type === 'UPDATE') {
        url = `${apiUrl}/template/` + params.data.id + `/update`;  
      } else {
        url = `${apiUrl}/template/create`;
      }
      
      if (url.indexOf('/service-mgmt/') !== -1) {
        url = url.replace(new RegExp('/service-mgmt/', 'g'), '/zuul/service-mgmt/')
      }

      const formData = new FormData();
      //Object.keys(params.data).forEach(k => {
      //  formData.append(k, params.data[k]);
      //})

      let entity = {...params.data};
      // remove iconUrl on create mode
      if (type === 'CREATE') {
        delete entity.iconUrl;
      }

      formData.append('json', JSON.stringify(entity));

      if (params.data.iconUrl && params.data.iconUrl.rawFile) {
        formData.append('iconFile', params.data.iconUrl.rawFile)
      }

      if (type === 'UPDATE' && params.data.iconUrlTemp && params.data.iconUrlTemp.rawFile) {
        formData.append('iconFile', params.data.iconUrlTemp.rawFile)
      }

      //formData.append('bannerfile', params.data.iconUrl.rawFile)
      //// log.debug(params.data.iconUrl.rawFile)
      let token = LocalStorage.instance.getToken();

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Transfer-Encoding': 'chunked',
          'Authorization': 'Bearer ' + token
        }
      }

      return post(url, formData, config)
              .then(rsp => {
                return { data: rsp.data, id: rsp.data.id }
              }).catch(error => {
                
                let response = error.response;
                let errorMessage = {};
                if (response) {
                  errorMessage = response.data;
                }
                
                throw new Error(
                  errorMessage.message
                );
        
              });

    } else {
      // for other request types and resources, fall back to the default request handler
      return requestHandler(type, resource, params);
    }

  };

  return addUploadFeature;

}