import React from 'react'
import {createTheme, MuiThemeProvider, withStyles, withWidth} from "@material-ui/core";
import PropTypes from "prop-types";
import Hoverable from "../../../../components/Hoverable";
import classNames from 'classnames'
import {compose} from "recompose";
import {connect} from "react-redux";
import {lightTheme} from "../../../../layout/themes";
import CheckIcon from "../../../../Icons/CheckIcon";
import log from "../../../../utils/log";

const styles = theme => ({
	cardContainer: {
		minWidth: '110px',
		padding: 5,
		border: '1px solid',
		borderRadius: 5,
		cursor: 'pointer',
		marginRight: 10,
		display: 'inline-block',
		marginBottom: 10,
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			minWidth: 90,
		}
	},
	cardBrand: {
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 12
	},
	cardName: {
		display: "inline-block",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textTransform: "uppercase",
		maxWidth: '12ch'
	},
	cardLast4: {
		textAlign: 'right'
	},
	cardSelected: {
		fontWeight: 'bold',
		borderWidth: 2,
		backgroundColor: '#eaeaea'
	},
	btnClose: {
		position: 'absolute',
		top: -8,
		right: -8,
		width: 16,
		height: 16,
		border: '1px solid',
		borderRadius: '50%',
		textAlign: 'center',
		background: 'white',
		cursor: 'pointer'
	},
	iconPrimary: {
		width: 12,
		color: '#03b102',
		height: 15
	}
})

class StripeSourceCard extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			hovered: false
		}
	}

	render() {
		const {card, classes, isSelected, deleteCard, isPrimary} = this.props
		log.debug("card:", card)
		return (
			<div
				id={isSelected ? "cardDraw" : null}
				className={isSelected ? classNames(classes.cardContainer, classes.cardSelected) : classes.cardContainer}
				/*onClick={() => this.setState({ inputNewCard: false, selectedCard: card })}*/
				onMouseEnter={() => this.setState({hovered: true})}
				onMouseLeave={() => this.setState({hovered: false})}
			>
				<div className={classNames('tag-remove', classes.btnClose)} onClick={() => {
					deleteCard(card)
				}}
					 style={{visibility: this.state.hovered ? 'visible' : 'hidden'}}/>
				<Hoverable {...this.props}>
					<div className={classes.cardBrand}>
						<div style={{flex: 1}}>{card.brand}</div>
						{isPrimary && <CheckIcon className={classes.iconPrimary}/>}
					</div>
					<div className={classes.cardName}>{card.name}</div>
					<div className={classes.cardLast4}>{'**** **** **** ' + card.last4}</div>
				</Hoverable>
			</div>
		);
	}

}

StripeSourceCard.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	deleteCard: PropTypes.func.isRequired
}

const mapStateToProps = state => ({});

const enhance = compose(
	connect(
		mapStateToProps,
		{}
	),
	withStyles(styles),
	withWidth()
);

const EnhanceStripeSourceCard = enhance(StripeSourceCard)

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const StripeSourceCardWithTheme = props => (
	<MuiThemeProvider theme={createTheme(lightTheme)}>
		<EnhanceStripeSourceCard {...props} />
	</MuiThemeProvider>
);

export default StripeSourceCardWithTheme;