
import { all, takeEvery, put, select, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { changeTheme } from '../../configuration/actions';

export function* handleLocationChange(action) {
    
    try {
        //// log.debug('===========> location change', action);

        const pathName = action.payload.pathname;

        if (pathName
            && pathName.startsWith('/my-account/')) {
            // change to portal theme
            //// log.debug('===========> location change - change to portal theme');
            yield put(changeTheme('portal'));
        } else {
            //// log.debug('===========> location change - change to light theme');
            yield put(changeTheme('light'));
        }

    } catch (e) {
        // log.debug('Error:', e);
        yield put(changeTheme('light'));
    }

}

export const themeSaga = function* () {
    yield takeLatest(
        action => action.type === LOCATION_CHANGE,
        handleLocationChange,
    );
};
