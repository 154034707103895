import React from "react";

const SvgForwardArrow = props => (
  <svg viewBox="0 0 20.691 37.097" {...props}>
    <path
      fill="currentColor"
      d="M2.141 37.097l18.551-18.549L2.143-.001.001 2.141l16.406 16.406L.001 34.953z"
      className="forward_arrow_svg__cls-1"
    />
  </svg>
);

export default SvgForwardArrow;
