import {stringify} from 'query-string';
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY
} from 'react-admin';
import LocalStorage from '../utils/services/storage';
import {post} from "axios";
import log from '../utils/log';

export const GET_ALL_CONNECTORS = 'GET_ALL_CONNECTORS'
export const GET_BY_SERVICE_ID = 'GET_BY_SERVICE_ID'

export const GET_BY_WIZARD_STATUS = 'GET_BY_WIZARD_STATUS'

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

    let token = LocalStorage.instance.getToken()

    const convertUserSearchFilter = (resource, payload) => {
        // hack: we need a issue. We can't add 2 React Admin List component on single page.
        // We trick 2 differ resource to point to the same one 'users'
        
        if (resource == 'users' && payload.userServiceListFilter) {
          payload = {...payload, ...payload.userServiceListFilter}
          delete payload.userServiceListFilter
          delete payload.denyUserServiceListFilter
          return payload;
        }
        return payload;
    }

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let customerId = LocalStorage.instance.getCustomerId()
        let url = '';
        const options = {};
        //// log.debug('params', params);
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([
                        (page - 1) * perPage,
                        page * perPage - 1,
                    ]),
                    filter: JSON.stringify(convertUserSearchFilter(resource, params.filter)),
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY: {
                const query = {
                    filter: JSON.stringify({ id: params.ids }),
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([
                        (page - 1) * perPage,
                        page * perPage - 1,
                    ]),
                    filter: JSON.stringify({
                        ...params.filter,
                        [params.target]: params.id,
                    }),
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            case GET_ALL_CONNECTORS:
                url = `${apiUrl}/connectors/list-type`;
                options.method = 'GET';
                break;
            case GET_BY_SERVICE_ID:
                console.log("get-tokenpack-by-service-id/${params.id}: ", {params})
                url = `${apiUrl}/${resource}/get-tokenpack-by-service-id/${params.serviceId}`;
                options.method = "GET";
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        // log.debug('Custom provider convert response ', response)
        const { headers, json } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!headers.has('content-range')) {
                    throw new Error(
                        'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
                    );
                }
               
                return {
                    data: json.data,
                    total: parseInt(
                        headers
                            .get('content-range')
                            .split('/')
                            .pop(),
                        10
                    )
                };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            default:
                return { data: json.data };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    const requestHandler = (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );

        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        ).catch(e => {
            log.error("rest-api", resource, type, e);
            const errorJson = e.body;
            if (errorJson && errorJson.messageKey)
                throw new Error(errorJson.messageKey);
            else throw new Error("error.default.internal.server.error");
        });
    };

    const addUploadFeature = (type, resource, params) => {
        
        //if (params.data && params.data.hasOwnProperty('files')) {
        if (params.data && params.data.hasOwnProperty('iconFileId')) {

            //// log.debug('POST token-pack', params);

            let url;

            if (type == UPDATE)
                url = `${apiUrl}/${resource}/${params.id}`

            else 
                url = `${apiUrl}/${resource}`
          
        //   if (url.indexOf('/service-mgmt/') !== -1) {
        //     url = url.replace(new RegExp('/service-mgmt/', 'g'), '/zuul/service-mgmt/')
        //   }
    
            const formData = new FormData();
            //Object.keys(params.data).forEach(k => {
            //  formData.append(k, params.data[k]);
            //})

            //let entity = {...params.data};
            // remove iconUrl on create mode
            // if (type === 'CREATE') {
            //     delete entity.iconUrl;
            // }
            //delete entity.files
    
            //formData.append('json', JSON.stringify(entity));

            // Add files to formData
            // Object.keys(params.data.files).map(fileSource => {
            //     formData.append(fileSource, params.data.files[fileSource].rawFile)
            // })

            // check if iconFileId is present
            const iconFileId = params.data.iconFileId;
            let iconFile = iconFileId; // iconFileId is raw File

            if (iconFileId && typeof iconFileId !== 'string') {
                // del iconFileId prop
                params.data.iconFileId = null; 
                formData.append('iconFile', iconFile);
            }

            let entity = {...params.data};
            delete entity.files
            formData.append('json', JSON.stringify(entity));

            const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                //   'Transfer-Encoding': 'chunked',
                  'Authorization': 'Bearer ' + LocalStorage.instance.getToken()
                }
            }

            return post(url, formData, config)
                .then(response =>
                    {
                        response.json = response.data
                        return convertHTTPResponse(response, type, resource, params)
                    }).catch(e => {
                    const errorJson = e.response.data;
                    if (errorJson && errorJson.messageKey)
                        throw new Error(errorJson.messageKey);
                    else
                        throw new Error("error.default.internal.server.error");

                });
        } else {
          // for other request types and resources, fall back to the default request handler
          return requestHandler(type, resource, params);
        }
    
      };

    return addUploadFeature
};
