import User from "../../../wla/entities/user";
import log from "../../log";
import appCookies from "./cookies";
import memStorage from "./memory";
import LocalStorage from "./storage";

export { memStorage, LocalStorage, appCookies };

export const SCOPE_VKEY = "VKEY"
export const SCOPE_CUSTOMER = "CUSTOMER"
export const IDM_DASHBOARD = "IDM_DASHBOARD"
export const MY_ACC_DASHBOARD = "MY_ACC_DASHBOARD"

export default class StorageManager {

  static instance = new StorageManager();

  KEYS = {
    USER_LOGGED: "user_logged",
    USER_TOKEN: "token",
    USER_RESPONSE: "user",
    CUSTOMER_ID: "customerId",
    CUSTOMER_NAME: "customerName",
    ACC_PERMISSION: "accountPermission",
    ACC_PERMISSION_EXPIRE: "accountPermissionExpire",
    LOGIN_REDIRECT_PATH: "loginRedirectPath",
    CURRENT_SCOPE: 'current_scope',
    ORIGINAL_SCOPE: 'original_scope',
    CURRENT_DASHBOARD: 'current_dashboard',
    BUY_SERVICE: 'buying_service',
    TOKEN_PACK_ID: 'token_pack_id',
    USING_AZURE_HYBRID: 'using_azure_hybrid',
    POPUP_RESULT: 'popup_result'
  };

  isLogged() {
    log.debug("isLogged: ");
    let token = this.getToken();
    const value = this.getUser();
    if (token && value) {
      return true;
    }
    return false;
  }

  logout() {
    this.setUser(undefined);
    //this.setToken(undefined, undefined);
    this.removeToken();

    localStorage.clear();
  }

  removeToken() {
    //log.debug('remove token item', this.KEYS.USER_TOKEN);
    appCookies.removeItem(this.KEYS.USER_TOKEN);
  }

  setToken(value?: string, expireInSec?: number) {
    // LocalStorage.setItem(this.KEYS.USER_TOKEN, value);
    // appCookies.setItem(this.KEYS.USER_TOKEN, value);
    if (expireInSec) {
      appCookies.setItem(this.KEYS.USER_TOKEN, value, expireInSec);
    } else {
      appCookies.setItem(this.KEYS.USER_TOKEN, value);
    }

  }

  getToken() {
    // const value = localStorage.getItem(this.KEYS.USER_TOKEN);

    const value = appCookies.getItem(this.KEYS.USER_TOKEN);
    return value;
  }

  setUser(value?: any) {
    LocalStorage.setItem(
      this.KEYS.USER_RESPONSE,
      value ? JSON.stringify(value) : undefined
    );
  }

  getUser() {
    const value = LocalStorage.getItem(this.KEYS.USER_RESPONSE);
    if (value === undefined) {
      return undefined;
    }
    try {
      let json = JSON.parse(value!);
      const user = {
          customerId: json.customerId,
          id: json.id,
          accountId: json.id,
          username: json.username,
          firstName: json.firstName,
          lastName: json.lastName,
          email:json.email,
          role:json.role,
          roleIds: json.roleIds,
          roles:json.roles,
          status:json.status,
          isCustomerRoot: json.customerRoot
      } as User;
      return user;
    } catch (err) {
      return undefined;
    }
  }

  setCustomerId(value?: any) {
    LocalStorage.setItem(this.KEYS.CUSTOMER_ID, value)
  }

  getCustomerId() {
    return LocalStorage.getItem(this.KEYS.CUSTOMER_ID)
  }

  setCustomerName(value?: any) {
    LocalStorage.setItem(this.KEYS.CUSTOMER_NAME, value)
  }

  getCustomerName() {
    return LocalStorage.getItem(this.KEYS.CUSTOMER_NAME)
  }

  setLoginRedirectPath(value?: any) {
    LocalStorage.setItem(this.KEYS.LOGIN_REDIRECT_PATH, value)
  }

  getLoginRedirectPath() {
    return LocalStorage.getItem(this.KEYS.LOGIN_REDIRECT_PATH)
  }
  
  setAccountPermission(permissions, expireInSec) {
    // cache to memory
    memStorage.set(this.KEYS.ACC_PERMISSION, permissions);
    // set expire value in seconds
    let expire = new Date().getTime() + (expireInSec * 1000);
    memStorage.set(this.KEYS.ACC_PERMISSION_EXPIRE, expire);

    //log.debug('set expire');
    //log.debug(expire);
  }

  clearAccountPermission() {
    memStorage.remove(this.KEYS.ACC_PERMISSION);
    memStorage.remove(this.KEYS.ACC_PERMISSION_EXPIRE);
  }

  getAccountPermission() {
    // get from memory
    const expire = memStorage.get(this.KEYS.ACC_PERMISSION_EXPIRE);
    //log.debug('get expire');
    //log.debug(expire);

    const now = new Date().getTime();

    let cacheData : any = {};

    if (expire && (now <= expire)) {
      log.debug('--- still not expire - alive in: ' +  (  (expire - now)/1000)  +  'in (s)');

      cacheData.data = memStorage.get(this.KEYS.ACC_PERMISSION);
      cacheData.expire = false; // flag indicate that cache is not expired yet
      return cacheData;
    } else {
      // meet expire - return null to force to refesh data
      log.debug('--- already expire');
      // we still need old data from cache - because the action to get new cache take time to response
      cacheData.data = memStorage.get(this.KEYS.ACC_PERMISSION);
      cacheData.expire = true; // flag indicate that cache is not expired yet
      return cacheData;
    }
  }

  getCurrentScope() {
    return LocalStorage.getItem(this.KEYS.CURRENT_SCOPE)
  }

  setCurrentScope(scope?: string) {
    LocalStorage.setItem(this.KEYS.CURRENT_SCOPE, scope)
  }

  getOriginalScope() {
    return LocalStorage.getItem(this.KEYS.ORIGINAL_SCOPE)
  }

  setOriginalScope(scope?: string) {
    LocalStorage.setItem(this.KEYS.ORIGINAL_SCOPE, scope)
  }

  getCurrentDashboard() {
    return LocalStorage.getItem(this.KEYS.CURRENT_DASHBOARD)
  }

  setCurrentDashboard(dashboard?: string) {
    LocalStorage.setItem(this.KEYS.CURRENT_DASHBOARD, dashboard)
  }

  /**
   * @param json { isBuying, tierId, periodId }
   */
  setBuyingService(json) {
    if (json)
      LocalStorage.setItem(this.KEYS.BUY_SERVICE, JSON.stringify(json))
  }

  getBuyingService() {
    const str = LocalStorage.getItem(this.KEYS.BUY_SERVICE)
    if (str)
      return JSON.parse(str)
    return false
  }

  getTokenPackId() {
    return LocalStorage.getItem(this.KEYS.TOKEN_PACK_ID)
  }

  setTokenPackId(tokenPackId) {
    LocalStorage.setItem(this.KEYS.TOKEN_PACK_ID, tokenPackId)
  }

  getAzureHybrid = (): boolean => {
    return LocalStorage.getItem(this.KEYS.USING_AZURE_HYBRID) === 'true'
  }

  setAzureHybrid(usingHybrid: boolean) {
    LocalStorage.setItem(this.KEYS.USING_AZURE_HYBRID, usingHybrid ? 'true' : 'false')
  }

  getPopupResult = () => {
    try {
      return JSON.parse(LocalStorage.getItem(this.KEYS.POPUP_RESULT) || "{}")
    } catch (e) {
      return {};
    }
  }

  setPopupResult = (objResult: Object) => {
    LocalStorage.setItem(this.KEYS.POPUP_RESULT, JSON.stringify(objResult));
  }
}
