import React from 'react'
import { Filter, DateInput, SelectInput, translate, SearchInput } from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { lightTheme } from '../layout/themes'
import LocalStorage, { SCOPE_VKEY, SCOPE_CUSTOMER } from '../utils/services/storage';
import './styles.css';

const styles = theme => ({
   
})

class OrderFilter extends React.Component {

    constructor(props) {
        super(props)
        
    }

    async UNSAFE_componentWillMount() {
        // log.debug('OrderFilter will mount');
    }

    render() {
        const { classes, displaySearchKeyword } = this.props
        return (<Filter {...this.props} className={classes.halfWidth}>
                    { displaySearchKeyword &&
                        <SearchInput placeholder="Search on customer name" source="customerName" alwaysOn />
                    }

                    <SelectInput alwaysOn={ displaySearchKeyword ? false : true } label="resources.orders.fields.status.label" source="status" choices={[
                        { id: 'OPEN', name: 'Open' },
                        { id: 'SUBMITTED', name: 'Submitted' },
                        { id: 'CANCELED', name: 'Canceled' },
                        { id: 'APPROVED', name: 'Approved' },
                        { id: 'CLOSED', name: 'Closed' }
                    ]} />
                    <DateInput source="createdDate" label="resources.orders.fields.createDate.label" options={{ format: 'MM/YYYY' }}/>
                </Filter>)
    }

}

const mapStateToProps = state => ({
    currentScope: state.menu.scope
});

const enhance = compose(
	connect(
		mapStateToProps,
		{}
	),
	translate,
	withStyles(styles)
);

const EnhancedOrderFilter = enhance(OrderFilter);

const EnhancedOrderFilterWithTheme = props => (
	<MuiThemeProvider theme={createTheme(lightTheme)}>
		<EnhancedOrderFilter {...props} />
	</MuiThemeProvider>
);

export default EnhancedOrderFilterWithTheme;