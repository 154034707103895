import React from "react";

const SvgBackArrow = props => (
  <svg viewBox="0 0 20.691 37.097" {...props}>
    <path
      fill="currentColor"
      d="M18.549 0L-.002 18.549l18.549 18.549 2.142-2.142L4.283 18.55 20.689 2.144z"
      className="back_arrow_svg__cls-1"
    />
  </svg>
);

export default SvgBackArrow;
