import React from "react";

const SvgChangePassword = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="change-password_svg__clip-path">
        <path
          id="change-password_svg__Rectangle_1647"
          d="M0 0h24v24H0z"
          className="change-password_svg__cls-1"
          data-name="Rectangle 1647"
        />
      </clipPath>
      <style>{".change-password_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g
      id="change-password_svg__Mask_Group_65"
      data-name="Mask Group 65"
      clipPath="url(#change-password_svg__clip-path)"
    >
      <g id="change-password_svg__outline-settings_applications-24px">
        <path
          id="change-password_svg__Path_884"
          d="M0 0h24v24H0z"
          data-name="Path 884"
          fill="none"
        />
        <path
          id="change-password_svg__Path_885"
          d="M6.21 13.97l1.2 2.07a.306.306 0 00.37.13l1.49-.6a4.244 4.244 0 001.01.59l.22 1.59a.31.31 0 00.3.25h2.4a.31.31 0 00.3-.26l.22-1.59a4.45 4.45 0 001.01-.59l1.49.6a.306.306 0 00.37-.13l1.2-2.07a.308.308 0 00-.07-.39l-1.27-.99a3.77 3.77 0 00.04-.58 5.845 5.845 0 00-.04-.59l1.27-.99a.306.306 0 00.07-.39l-1.2-2.07a.306.306 0 00-.37-.13l-1.49.6a4.244 4.244 0 00-1.01-.59l-.22-1.59a.31.31 0 00-.3-.25h-2.4a.31.31 0 00-.3.26l-.22 1.59a4.21 4.21 0 00-1.01.58l-1.49-.6a.306.306 0 00-.37.13l-1.2 2.07a.308.308 0 00.07.39l1.27.99a3.915 3.915 0 00-.05.59 5.845 5.845 0 00.04.59l-1.27.99a.306.306 0 00-.06.39zM12 10.29A1.71 1.71 0 1110.29 12 1.716 1.716 0 0112 10.29zM19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm0 16H5V5h14z"
          className="change-password_svg__cls-1"
          data-name="Path 885"
        />
      </g>
    </g>
  </svg>
);

export default SvgChangePassword;
