import { IconButton, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { translate } from "react-admin";
import { connect } from "react-redux";
import { compose } from "recompose";

const styles = (theme) => {
    return {
        button: {
            margin: 2,
        },
        loading: {
            marginRight: 5,
        },
        vkeyIconButton: {
            /*width: 35,
            height: 35,*/
            color: theme.palette.primary.main,
        },
    };
};

const VkeyListButton = ({ title, ...props }) => {
    const {
        classes,
        children,
        handleClick,
        rowAction,
        showNotification,
    } = props;
    return (
        <Fragment>
            <Tooltip title={title}>
                <IconButton
                    // disabled={props.record.type === REST_API_AUTH.id}
                    rowAction={rowAction ? "true" : false}
                    className={classes.vkeyIconButton}
                    onClick={() => {
                        if (handleClick)
                            handleClick(props.record, showNotification);
                    }}
                >
                    {children}
                </IconButton>
            </Tooltip>
        </Fragment>
    );
};

VkeyListButton.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    showNotification: PropTypes.func,
    rowAction: PropTypes.bool,
    disabled: PropTypes.bool
};

const mapStateToProps = (state) => ({});

const enhance = compose(
    connect(mapStateToProps, {}),
    translate,
    withStyles(styles)
);

export default enhance(VkeyListButton);
