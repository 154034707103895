import React from "react";

const SvgDownArrow = props => (
  <svg viewBox="0 0 34.461 19.436" {...props}>
    <path
      fill="currentColor"
      d="M34.461 2.135L32.438-.001 17.3 15.163 2.136-.001 0 2.135l17.3 17.3z"
      className="down_arrow_svg__cls-1"
    />
  </svg>
);

export default SvgDownArrow;
