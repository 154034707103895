import React from 'react'
import {compose} from "recompose";
import {
    Button,
    createTheme,
    IconButton,
    MuiThemeProvider,
    TextField,
    withStyles,
    withWidth
} from "@material-ui/core";
import {lightTheme} from "../../../../layout/themes";
import PropTypes from "prop-types";
import WaitingView from "../../../components/WaitingView";
import { withDataProvider, GET_LIST } from 'react-admin'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {connect} from "react-redux";
import multipleUserSelectionReducer from "./multipleUserSelectionReducer";
import {COMPONENT_MULTIPLE_USER_SEARCH} from "./multipleUserSelectionSaga";
import BackArrowIcon from "../../../../Icons/BackArrow";
import ForwardArrowIcon from "../../../../Icons/ForwardArrow";
import DownArrowIcon from "../../../../Icons/DownArrow";
import UpArrowIcon from "../../../../Icons/UpArrow";

const styles = theme => ({
    root: {
        flexGrow: 1,
        fontSize: '.9rem'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        position: 'relative',
        flex: 1,
        fontSize: '.9rem',
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 32px)',
        }
    },
    multipleSelect: {
        width: '100%',
        border: 'none',
        fontSize: '.9rem'
    }
})

class MultipleUserSelection extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            users: [],
            selectedUsers: []
        }
    }

    UNSAFE_componentWillMount() {
        const { filter, dataProvider, onUsersSelected, searchUsers } = this.props

        searchUsers({
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'DESC' },
            filter: filter,
        })

    }

    render() {

        const { classes, userList, loading, searchUsers, filter, mapUsers, onUsersSelected, width } = this.props
        const { selectedUsers } = this.state

        return (
            <div {...this.props} className={classes.root} >
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <TextField placeholder={'Search'} onChange={e => {
                            searchUsers({
                                pagination: { page: 1, perPage: 100 },
                                sort: { field: 'id', order: 'DESC' },
                                filter: {
                                    ...filter,
                                    query: e.currentTarget.value
                                },
                            })
                        }} />
                    </Grid>
                    <Grid item xs={12} style={{ display: width === 'xs' ? 'block' : 'flex' }}>
                        <Paper className={classes.paper}>
                            <WaitingView loading={loading}>
                                <select multiple={true} className={classes.multipleSelect} id={'user-list'} onChange={() => {
                                    // log.debug('Select changed ')
                                }} size={10}>
                                    { userList.map(user =>
                                        selectedUsers.map(sel => sel.id).indexOf(user.id) === -1 ? <option value={user.id}>{user.displayName ? user.displayName : user.username}</option> : null)
                                    }
                                </select>
                            </WaitingView>
                        </Paper>
                        <div style={{ display: 'flex', flexDirection: width === 'xs' ? 'row' : 'column', justifyContent: 'center' }}>
                            {/*Add user to selected*/}
                            <IconButton onClick={() => {
                                const selected = document.querySelectorAll('#user-list option:checked');
                                Array.from(selected).forEach(el => {
                                    if (selectedUsers.indexOf(el.value) === -1) {
                                        selectedUsers.push(mapUsers[el.value])
                                    }
                                });
                                this.setState({ selectedUsers })
                            }}>{ width === 'xs' ? <DownArrowIcon style={{ width: 25, color: 'rgb(95, 38, 133)' }} /> : <ForwardArrowIcon style={{ width: 13.75 }} /> }</IconButton>


                            {/*Remove user from selected*/}
                            <IconButton onClick={() => {
                                const selected = document.querySelectorAll('#selected-user-list option:checked');
                                Array.from(selected).forEach((el) => {
                                    const index = selectedUsers.indexOf(el.value)
                                    selectedUsers.splice(index, 1)
                                });
                                this.setState({ selectedUsers })
                            }}>{ width === 'xs' ? <UpArrowIcon style={{ width: 25 }}/> : <BackArrowIcon style={{ width: 13.75 }} /> }</IconButton>
                        </div>
                        <Paper className={classes.paper}>
                            <select multiple={true} className={classes.multipleSelect} id={'selected-user-list'} size={10}>
                                {
                                    selectedUsers.map(selUser => {
                                        return (
                                            <option value={selUser.id}>{selUser.displayName ? selUser.displayName : selUser.username}</option>
                                        )
                                    })
                                }
                            </select>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          style={{ display: 'flex' }}
                    >
                        <Button variant={"contained"} color={"primary"} disabled={selectedUsers.length === 0} onClick={() => {
                            // callback
                            if (onUsersSelected) {
                                onUsersSelected(selectedUsers.map(sel => sel.id))
                            }
                        }}>Select ({selectedUsers.length}) users</Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

MultipleUserSelection.propTypes = {
    filter: PropTypes.object,
    onUsersSelected: PropTypes.func
}

const mapStateToProps = state => ({
    loading: state.multipleUsersSelect.loading,
    userList: state.multipleUsersSelect.userList,
    mapUsers: state.multipleUsersSelect.mapUsers,
})

const enhance = compose(
    withDataProvider,
    connect(
        mapStateToProps,
        {
            searchUsers: (data) => ({
                type: COMPONENT_MULTIPLE_USER_SEARCH,
                payload: data
            })
        }
    ),
    withStyles(styles),
    withWidth()
);

const EnhanceMultipleUserSelection = enhance(MultipleUserSelection)

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const StripeSourceCardWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhanceMultipleUserSelection {...props} />
    </MuiThemeProvider>
);

export default StripeSourceCardWithTheme;