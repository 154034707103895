import React, {Fragment} from 'react';
import {ReferenceInput, SelectInput} from 'react-admin';


const SamlReferenceInput = props => (
    <Fragment>
        <ReferenceInput {...props} style={{ width: '100%'}} >
            <SelectInput source="name" />
        </ReferenceInput>

        {/*<SamlQuickCreateButton />*/}
        {/* We use Field to get the current value of the `post_id` field */}
        {/* <Field
            name="post_id"
            component={({ input }) =>
                input.value && <PostQuickPreviewButton id={input.value} />
            }
        /> */}
    </Fragment>
);

export default SamlReferenceInput;