import React, {Component} from 'react'
import {compose} from "recompose";
import {Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {createTheme, MuiThemeProvider, TextField} from "@material-ui/core";
import {lightTheme} from "../../layout/themes";
import VkeyFormToolbar from "../../components/VkeyFormToolbar";
import {DEFAULT_MAX_LENGTH} from "../../constants";
import {
    GET_ONE,
    SaveButton,
    showNotification as showNotificationAction,
    TextInput,
    Toolbar,
    translate,
    UPDATE
} from 'react-admin'
import VkeyTooltipWrapper from "../../components/VkeyTooltipWrapper";
import dataProvider from '../../dataProvider'

const renderTextField = ({
    input,
    label,
    meta: { touched, error },
    custom,
}) => (
        <TextField
            hintText={label}
            floatingLabelText={label}
            errorText={touched && error}
            {...input}
            {...custom}
        />
    )


class SAMLSettingForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }

        this.saveSAMLSetting = this.saveSAMLSetting.bind(this)
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    async UNSAFE_componentWillMount() {
        let { data } = await dataProvider(GET_ONE, 'saml-setting', {})
        this.setState({ 
            ...data 
        })
    }

    async saveSAMLSetting(values) {
        const { data } = await dataProvider(UPDATE, 'saml-setting', { data: this.state })
        this.props.showNotification(this.props.translate('ra.notification.updated', { smart_count: 1 }), 'info')
    }

    render() {
        const { translate, handleSubmit } = this.props
        return (
            <Form
                {...this.props}
                toolbar={<VkeyFormToolbar />}
                onSubmit={handleSubmit(this.saveSAMLSetting)}
                style={{ paddingTop: 16, paddingRight: 16, paddingLeft: 16, }}
                ref={ref => this.form = ref}
            >
                <VkeyTooltipWrapper>
                    <TextInput source="url" label={translate("resources.saml.fields.url.label")} title={translate('resources.saml.fields.url.title')}
                        value={this.state.url}
                        onChange={(value) => this.onChange(value)}
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH, value: this.state.url }} />

                    <TextInput source="protocol" label={translate("resources.saml.fields.protocol.label")} title={translate('resources.saml.fields.protocol.title')}
                        value={this.state.protocol}
                        onChange={(value) => this.onChange(value)}
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH, value: this.state.protocol }} />

                    <TextInput source="signOutPath" label={translate("resources.saml.fields.signOutPath.label")} title={translate('resources.saml.fields.signOutPath.title')}
                        value={this.state.signOutPath}
                        onChange={(value) => this.onChange(value)}
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH, value: this.state.signOutPath }} />

                    <TextInput source="signInPath" label={translate("resources.saml.fields.signInPath.label")} title={translate('resources.saml.fields.signInPath.title')}
                        value={this.state.signInPath}
                        onChange={(value) => this.onChange(value)}
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH, value: this.state.signInPath }} />

                    <TextInput source="metaPath" label={translate("resources.saml.fields.metaPath.label")} title={translate('resources.saml.fields.metaPath.title')}
                        value={this.state.metaPath}
                        onChange={(value) => this.onChange(value)}
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH, value: this.state.metaPath }} />

                    <TextInput source="cert" label={translate("resources.saml.fields.trustedCert.label")} multiline={true}
                        title={translate('resources.saml.fields.trustedCert.title')}
                        onChange={(value) => this.onChange(value)}
                        inputProps={{ maxLength: DEFAULT_MAX_LENGTH, value: this.state.cert }} width={100} />

                </VkeyTooltipWrapper>
                <Toolbar style={{ marginLeft: -16, marginRight: -16 }}>
                    <SaveButton onClick={e => this.form.context._reduxForm.submit()} />
                </Toolbar>
            </Form>
        )
    }
}


SAMLSettingForm.propTypes = {
};

const mapStateToProps = state => ({
});

const enhance = compose(
    translate,
    reduxForm({
        form: 'SAMLSettingForm',
    }),
    connect(
        mapStateToProps,
        {
            showNotification: showNotificationAction
        },
        null,
    ),
    // withStyles(styles)
);

const EnhancedSAMLSettingForm = enhance(SAMLSettingForm);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const SAMLSettingFormWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedSAMLSettingForm {...props} />
    </MuiThemeProvider>
);

export default SAMLSettingFormWithTheme;