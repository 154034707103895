import log from "../../utils/log";
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from "react-admin";
import LocalStorage from '../../utils/services/storage';
import qs from 'query-string';
import axios, { AxiosRequestConfig, post } from "axios";
import { BASE_URL } from "../../dataProvider";

export const GET_CUSTOMER_SERVICE = 'GET_CUSTOMER_SERVICE'
export const GET_UNUSED_CUSTOMER_SERVICE = 'GET_UNUSED_CUSTOMER_SERVICE'
export const INACTIVE_MANY = 'INACTIVE_MANY'
export const START_SERVICE = 'START_SERVICE'
export const STOP_SERVICE = 'STOP_SERVICE'
export const DELETE_SERVICE = 'DELETE_SERVICE'

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {};

    log.warn(type, params)
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const payload = {
          page: page - 1,
          limit: perPage,
          ...params.filter
        }

        const customerId = LocalStorage.instance.getCustomerId();
        if (customerId && customerId.length > 0) { 
          url = `${apiUrl}/${resource}/${customerId}/search`;
        } else {
          url = `${apiUrl}/${resource}/search`;
        }

        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      }
      case GET_ONE:
        const payload = {
          id: params.id
        }
        url = `${apiUrl}/${resource}/detail`;
        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      case GET_MANY: {
        url = `${apiUrl}/${resource}/details`;
        options.method = "POST";
        options.body = JSON.stringify(params.ids);
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        // log.debug(params)
        const payload = {
          page: page - 1,
          limit: perPage,
          [params.target]: params.id
        }
        url = `${apiUrl}/${resource}/search`;
        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/update`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        //// log.debug('Updating instance...', params);
        break;
      case CREATE:
        url = `${apiUrl}/register`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      case GET_CUSTOMER_SERVICE:
        url = `${apiUrl}/${resource}/${params.customerId}/list`;
        options.method = "GET";
        break;
      case GET_UNUSED_CUSTOMER_SERVICE:
        const tokenPackId = params.tokenPackId;
        log.debug('HA:::GET_UNUSED_CUSTOMER_SERVICE')

        url = `${apiUrl}/${resource}/${LocalStorage.instance.getCustomerId()}/unused_list`
          + (tokenPackId && tokenPackId !== "undefined" ? '?tokenPackId=' + tokenPackId : '');
        options.method = "GET";
        break;
      case START_SERVICE:
        const startServicePayload = {
          id: params.serviceInstanceId
        }
        url = `${apiUrl}/${resource}/${params.customerId}/start`;
        options.method = "POST";
        options.body = JSON.stringify(startServicePayload);
        break;
      case STOP_SERVICE:
        const stopServicePayload = {
          id: params.serviceInstanceId
        }
        url = `${apiUrl}/${resource}/${params.customerId}/stop`;
        options.method = "POST";
        options.body = JSON.stringify(stopServicePayload);
        break;
      case DELETE_SERVICE:
        const deleteServicePayload = {
          id: params.serviceInstanceId
        }
        url = `${apiUrl}/${resource}/${params.customerId}/delete`;
        options.method = "DELETE";
        options.body = JSON.stringify(deleteServicePayload);
        break;

      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!json.hasOwnProperty("totalElements")) {
          throw new Error(
            "The numberOfElements property must be must be present in the Json response"
          );
        }
        return {
          data: json.contents,
          total: parseInt(json.totalElements, 10)
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      default:
        return { data: json };
    }
  };

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    const requestHandler = (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === INACTIVE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/deactivate`, {
            method: "POST",
            body: JSON.stringify({ id: id })
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params)
    ).catch(e => {
      //// log.debug('server resp error')
      //// log.debug(e.status)
      //// log.debug(e.body)
      const errorJson = e.body;

      if (typeof errorJson !== 'undefined' && errorJson.messageKey)
        throw new Error(errorJson.messageKey);

      else
        throw new Error("error.default.internal.server.error");

    });
  };

  const addUploadFeature = (type, resource, params) => {

    if (type === 'UPDATE') {
      // it update service instance with icon

      // log.debug('Service Launch Add Upload Feature',params)

      let url = '';
      const customerId = LocalStorage.instance.getCustomerId()
      let token = LocalStorage.instance.getToken()
      let service = 'service-instance';
      let transactionId = '';
      let isPublic = true;
      const query = {
          customerId,
          service,
          transactionId,
          public: isPublic
      }

      const config = {
          headers: {
              'content-type': 'multipart/form-data',
              'Transfer-Encoding': 'chunked',
              'Authorization': 'Bearer ' + token
          }
      }

      const formData = new FormData();
      const iconFile = params.data.iconUrl;

      // log.debug('Service Launch Add Upload Feature - iconFile', iconFile)
      // log.debug('Service Launch Add Upload Feature - typeof iconFile', typeof iconFile)

      if (iconFile && typeof iconFile !== 'string') {
          formData.append('file', iconFile);

          let uploadUrl = `${BASE_URL}/zuul/files/upload?${qs.stringify(query)}`;
          
          return post(uploadUrl, formData, config)
                      .then(rsp => {
                          // log.debug('Service Launch Add Upload Feature - Upload response',rsp)

                          // replace iconUrl with fileId
                          params.data.iconUrl = rsp.data.fileId;

                          // log.debug('Service Launch Add Upload Feature - After Set IconUrl', params)

                          return requestHandler(type, resource, params);

                      }).catch(error => {
                
                          let response = error.response;
                          let errorMessage = {};
                          if (response) {
                            errorMessage = response.data;
                          }
                          
                          throw new Error(
                            errorMessage.message
                          );    
        
                      });
      }

    }
      
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);

  };

  return addUploadFeature;
};