import {subDays} from "date-fns";

export const REPORT_UPDATE_TIMEFILTER = 'REPORT_UPDATE_TIMEFILTER';

const defaultState = {
    from: subDays(new Date(), 1),
    to: new Date()
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case REPORT_UPDATE_TIMEFILTER:
            console.log("ReportReducers :: Date changed", action.data)
            return {
                ...state,
                from: action.data.from,
                to: action.data.to,
            }
            break

        default:
            return state
    }
}

export const updateTimeFilter = ({ from, to }) => {
    return { type: REPORT_UPDATE_TIMEFILTER, data: { from, to } }
}

export const resetDefaultTimeFilter = () => {
    const data = {
        from: subDays(new Date(), 1),
        to: new Date()
    };

    console.log("Reset default time filter", data);
    return { type: REPORT_UPDATE_TIMEFILTER, data: data }
}