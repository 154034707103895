import {
  CREATE, DELETE,
  DELETE_MANY, fetchUtils,
  GET_LIST, GET_MANY,
  GET_MANY_REFERENCE, GET_ONE, UPDATE,
  UPDATE_MANY
} from "react-admin";

export const INACTIVE_MANY = 'INACTIVE_MANY'
export const GET_ASSET_PERMISSION = 'GET_ASSET_PERMISSION'
export const SAVE_ASSET_PERMISSION = 'SAVE_ASSET_PERMISSION'
export const GET_ALL_RESOURCE_TYPE = 'GET_ALL_RESOURCE_TYPE'
export const GET_ALL_PREDEFINE_ACTION = 'GET_ALL_PREDEFINE_ACTION'

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {};
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const payload = {
            page: page - 1,
            limit: perPage,
            ...params.filter
        }
        url = `${apiUrl}/${resource}/search`;
        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      }
      case GET_ONE:
        const payload = {
            id: params.id
        }
        url = `${apiUrl}/${resource}/detail`;
        options.method = "POST";
        options.body =JSON.stringify(payload);
        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ id: params.ids })
        };
        let idStr = "";
        const queryString = params.ids.map(id => idStr + `id=${id}`);
        url = `${apiUrl}/${resource}?${idStr}}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        // log.debug(params)
        const payload = {
          page: page - 1,
          limit: perPage,
          [params.target] : params.id
        }
        url = `${apiUrl}/${resource}/search`;
        options.method = "POST";
        options.body =JSON.stringify(payload);
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/update`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}/create`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      case GET_ALL_RESOURCE_TYPE:
        // log.debug(params);
        url = `${apiUrl}/resource/all`;
        options.method = "GET";
        break;
      case GET_ASSET_PERMISSION:
        //// log.debug(params);
        url = `${apiUrl}/${resource}/${params.assetId}/permission`;
        options.method = "GET";
        break;
      case SAVE_ASSET_PERMISSION:
        const savePermissionPayload = {
          assetId: params.assetId,
          rolePermissionDetails: params.data
        }
        url = `${apiUrl}/${resource}/${params.assetId}/permission`;
        options.method = "POST";
        options.body = JSON.stringify(savePermissionPayload);
        break;
      case GET_ALL_PREDEFINE_ACTION:
        url = `${apiUrl}/action/all`;
        options.method = "GET";
        break;

      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!json.hasOwnProperty("totalElements")) {
          throw new Error(
            "The numberOfElements property must be must be present in the Json response"
          );
        }
        return {
          data: json.contents,
          total: parseInt(json.totalElements, 10)
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      default:
        return { data: json };
    }
  };

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === INACTIVE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/deactivate`, {
            method: "POST",
            body: JSON.stringify({ id : id})
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params)
    ).catch(e => {
      //// log.debug('server resp error')
      //// log.debug(e.status)
      //// log.debug(e.body)
      const errorJson = e.body;
      if (errorJson && errorJson.messageKey)
        throw new Error(errorJson.messageKey);

      else
        throw new Error("error.default.internal.server.error");

    });
  };
};