
export const LOAD_ACC_PROFILE_DATA = 'accountProfile/LOAD_DATA';
export const LOAD_SUBSCRIPTION_DATA = 'subscription/LOAD_SUBSCRIPTION_DATA';
export const LOAD_COMP_INFO_DATA = 'accountProfile/LOAD_COMPANY_DATA'
export const LOAD_SEC_2FA_CFG_DATA = 'accountSecurity/LOAD_SEC_2FA_CFG_DATA'

export const loadAccountProfile = accInfo => ({
    type: LOAD_ACC_PROFILE_DATA,
    payload: accInfo,
});

export const loadSubscriptionDetail = subscriptionDetail => ({
    type: LOAD_SUBSCRIPTION_DATA,
    payload: subscriptionDetail,
});

export const loadCompanyInfo = companyInfo => ({
    type: LOAD_COMP_INFO_DATA,
    payload: companyInfo
})

export const loadSecurity2FAConfigInfoAction = sec2FAConfigInfo => ({
    type: LOAD_SEC_2FA_CFG_DATA,
    payload: sec2FAConfigInfo,
});

