const LocalStorage = {
  setItem,
  removeItem,
  getItem,
  clear,
  setToken,
  getToken,
  clearToken
};
export default LocalStorage;

function setItem(key: string, value?: string) {
  if (value == undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
}

function removeItem(key: string) {
  localStorage.remove(key);
}

function getItem(key: string) {
  return localStorage.getItem(key);
}

function clearToken() {
  removeItem("AppTokenKey");
}

function setToken(value: string) {
  setItem("AppTokenKey", value);
}

function getToken() {
  return getItem("AppTokenKey");
}

function clear() {
  localStorage.clear();
}
