import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import HelpIcon from '@material-ui/icons/Help';
import log from '../../utils/log';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: 'url("/assets/images/sign_in_bak.png")',
        backgroundSize: "cover",
        height: "calc(100% - 250px)",
        paddingTop: 250,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 100,
            height: "calc(100% - 100px)",
        }
    },
    mainContent: {
        width: '80vw',
        maxWidth: '480px',
        padding: '1em 72px',
        position: 'relative',
        background: 'rgba(47,31,59,0.5)',
        minHeight: '10em',
        margin: '0 auto',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: -2,
            left: 0,
            height: 2,
            width: '100%',
            background: 'rgb(180, 152, 219)'
        },
        [theme.breakpoints.down('sm')]: {
            padding: "2em"
        }
    },
    steps: {
        clear: 'both',
        listStyle: 'none',
        paddingLeft: '2%',

    },
    elem: {
        margin: '2em 0',
        paddingTop: '1.2em',
        display: 'block',
        position: 'relative',
        counterIncrement: 'inst',
        color: 'rgb(180, 152, 219)',
        '&:before': {
            content: "counter(inst)",

            background: 'rgb(180, 152, 219)',
            color: '#fff',

            fontWeight: 700,
            fontStyle: 'italic',
            textShadow: '1px 1px rgba(255, 150, 0, 0.5)',

            borderRadius: '0 0.675em 0.675em 0',
            fontSize: '1.2em',
            textAlign: 'center',

            paddingTop: 0,
            paddingLeft: '1.25%',
            paddingRight: '2.25%',
            left: '-5%',
            top: '-0.65em',
            height: '1.35em',
            width: '1.35em',
            position: 'absolute',

            transition: 'all 0.2s ease-in-out',

            // zIndex: -1
        }
    },
    helpIcon: {
        color: "#b498da",
        textAlign: "center"
    },
    title: {
        textAlign: "center",
        color: "white",
        marginBottom: '1em'
    }
}))

const Deeplink = (props: any) => {

    const classes = useStyles()

    return <div className={classes.background}>
        <h1 className={classes.title}>V-OS Trusted Identity</h1>
        <div className={classes.mainContent}>
            <div className={classes.helpIcon}><HelpIcon style={{ width: '70px', height: '70px' }} /></div>
            <ol className={classes.steps}>
                <li className={classes.elem}>
                    Take QR Code screenshot.
                </li>
                <li className={classes.elem}>
                    Open the <strong>V-Key App</strong> & go to <strong>Scan QR Code</strong> screen.
                </li>
                <li className={classes.elem}>
                    Upload the screenshot using <strong>Load From Image</strong> button.
                </li>
            </ol>
            <div style={{width: '100%'}}>
                <Link to="#" style={{
                    display: 'block',
                    fontSize: '1.2em',
                    textDecoration: 'none',
                    color: '#FF9D00',
                    flex: 0,
                    margin: '0 auto',
                    textAlign: "center"
                }} onClick={e => {
                    log.info("You clicked the link")
                    window.close()
                }}>
                    { "Close" }
                </Link>
            </div>
        </div>
    </div>
}

export default Deeplink