import * as React from "react";
import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    ReferenceField,
    SelectInput,
    TextField, useRecordContext,
    useTranslate
} from 'react-admin';
import DashboardPageWrapperWithTheme from '../../components/DashboardPageWrapper';
import {ListProps} from "ra-ui-materialui/src/types";

const FIELD_DISPLAY_MAPPING = {
    account: "email",
    user: "username",
    users: "username",
    oidc: "clientName",
    "service-launch": "instanceName",
    "activation-code": "username",
}

const filters = [
    <SelectInput alwaysOn allowEmpty={false} source="range" choices={[
        {id: '1', name: 'Last 1 week'},
        {id: '2', name: 'Last 2 week'},
        {id: '3', name: 'Last 3 week'},
        {id: '4', name: 'Last 1 month'},
    ]}/>,
    <SelectInput allowEmpty={true} source="table" choices={[
        {id: 'account', name: 'Account'},
        {id: 'users', name: 'User'},
        {id: 'oidc', name: 'OIDC'},
        {id: 'radius', name: 'Radius'},
        {id: 'service-launch', name: 'Service'},
    ]}/>
];


interface ListAuditProps extends ListProps {
    table?: string,
    entityId?: string
}

const LongTextField = ({source}) => {
    const record = useRecordContext();
    return (<div style={{maxWidth: 500, wordWrap: "break-word"}}>{record && record[source]}</div>);
};

const ListAudit = ({table, entityId, ...props}: ListAuditProps) => {
    console.debug("Debug props", props);
    console.debug({table, entityId});

    const translate = useTranslate()
    return (
        <DashboardPageWrapperWithTheme
            title={(table && entityId) ? undefined : translate('resources.audit_log.name', 2)}>
            <List {...props} filters={filters} bulkActionButtons={false} filter={table && entityId ? {table, entityId} : {}}>
                <Datagrid>
                    <DateField source="modifiedDate" showTime/>
                    {(!table && !entityId) && <TextField source="table"/>}
                    <TextField source="action"/>
                    {(!table && !entityId) &&
                        <FunctionField label="Record" render={record =>
                            <ReferenceField label="Record" source="entityId" reference={record.table}
                                            emptyText={record.entityId}>
                                <TextField
                                    source={record.table in FIELD_DISPLAY_MAPPING ? FIELD_DISPLAY_MAPPING[record.table] : "name"} emptyText={record.entityId}/>
                            </ReferenceField>}/>
                    }
                    <ReferenceField source="modifiedBy" reference="account">
                        <TextField source="username"/>
                    </ReferenceField>
                    <LongTextField source="changes"/>
                </Datagrid>
            </List>
        </DashboardPageWrapperWithTheme>
    )
};

export default ListAudit
