import {IConnectorType} from "./styled";

export default class ConnectorType {

    static instance = new ConnectorType();
    private types:Array<IConnectorType> = [
        { id: "1", key: "ldap" },
        { id: "2", key: "radius" },
        { id: "3", key: "saml" },
        { id: "4", key: "oidc" },
        { id: "5", key: "saml2" },
        { id: "7", key: "https" },
    ]

    getById(id: string) {
        const filtered = this.types.filter(type => type.id === id)
        return filtered.length > 0 ? filtered[0] : null
    }

    getByKey(key: string) {
        const filtered = this.types.filter(type => type.key === key)
        return filtered.length > 0 ? filtered[0] : null
    }
}