import {stringify} from "query-string";
import {BASE_URL} from "../../dataProvider";
import httpClient from "../../dataProvider/httpClient";
import {EDIT_ACTION, EXTEND_ACTION} from "../../subscription/SubscriptionList";
import LocalStorage from "../../utils/services/storage";
import {capitalizeFirstLetter} from "../../utils/StringUtils";
import {PACKAGE_TYPE_FREE} from "./constant";
import log from "../../utils/log";
import constants from "../../constants";

export const getListPackages = (payload) => {
    // Get newest token pack wizard = NEW
    // ?nonce=${window.voswebshield.getNonce()}&vos-sign=${window.voswebshield.vkSign()}
    /*return window.vkey.fetch(`https://${constants.WEBSHIELD_DOMAIN}/${constants.WEBSHIELD_PATH}/api/billing/payment_plan`, {
        method: 'GET', headers: {
            'Content-Type': 'application/json'
        }
    })
*/
    /*httpClient(`/api/billing/payment_plan`, {method: 'GET'}).then(rsp => {
        console.log(rsp);
    })*/

    return httpClient(constants.API_URL + `/billing/payment_plan`, {method: 'GET'})
}

export const getListFeatures = () => {
    return httpClient(`${BASE_URL}/billing/service_feature`, {method: 'GET'})
}

export const subscribePackage = (pack) => {
    const {selectedPackage, additionalData} = pack
    const customerId = LocalStorage.instance.getCustomerId()
    const body = {
        customerId,
        numberOfUsers: selectedPackage.tierPlanType === PACKAGE_TYPE_FREE ? 10 : 10,
        paymentPlanIds: []
    }
    body.paymentPlanIds.push(selectedPackage.id)
    let packageType = selectedPackage.tierPlanType.toLowerCase() === 'normal' ? 'Professional' : selectedPackage.tierPlanType.toLowerCase()
    body.tokenPackName = `${capitalizeFirstLetter(packageType)} Token Pack`

    if (additionalData) {
        const {numberOfUsers, paymentPlanIds} = additionalData
        body.numberOfUsers = numberOfUsers
        body.paymentPlanIds = paymentPlanIds
    }

    return httpClient(`${BASE_URL}/billing/order/${customerId}/submit`, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const upgradePackage = (pack) => {
    const {selectedPackage, additionalData} = pack
    const customerId = LocalStorage.instance.getCustomerId()
    const body = {
        customerId,
        numberOfUsers: selectedPackage.tierPlanType === PACKAGE_TYPE_FREE ? 10 : 10,
        paymentPlanIds: []
    }
    body.paymentPlanIds.push(selectedPackage.id)
    let packageType = selectedPackage.tierPlanType.toLowerCase() === 'normal' ? 'Professional' : selectedPackage.tierPlanType.toLowerCase()
    body.tokenPackName = `${capitalizeFirstLetter(packageType)} Token Pack`

    if (additionalData) {
        const {numberOfUsers, paymentPlanIds, subscriptionId} = additionalData
        body.numberOfUsers = numberOfUsers
        body.paymentPlanIds = paymentPlanIds
        body.subscriptionId = subscriptionId
    }

    return httpClient(`${BASE_URL}/billing/subscription/${customerId}/upgrade`, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const extendPackage = (pack) => {
    const {selectedPackage, additionalData} = pack
    const customerId = LocalStorage.instance.getCustomerId()
    const body = {
        customerId,
        numberOfUsers: selectedPackage.tierPlanType === PACKAGE_TYPE_FREE ? 10 : 10,
        paymentPlanIds: []
    }
    body.paymentPlanIds.push(selectedPackage.id)
    let packageType = selectedPackage.tierPlanType.toLowerCase() === 'normal' ? 'Professional' : selectedPackage.tierPlanType.toLowerCase()
    body.tokenPackName = `${capitalizeFirstLetter(packageType)} Token Pack`

    if (additionalData) {
        const {numberOfUsers, paymentPlanIds, subscriptionId, removeUsers} = additionalData
        body.numberOfUsers = numberOfUsers
        body.paymentPlanIds = paymentPlanIds
        body.subscriptionId = subscriptionId
        body.removeUsers = removeUsers
    }

    return httpClient(`${BASE_URL}/billing/subscription/${customerId}/extend`, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const editPackage = (pack) => {

    // log.debug('[editPackage API]', pack)

    const {selectedPackage, additionalData} = pack
    const customerId = LocalStorage.instance.getCustomerId()
    const body = {customerId}

    if (additionalData) {
        const {numberOfUsers, paymentPlanIds, subscriptionId, removeUsers} = additionalData
        body.numberOfUsers = numberOfUsers
        body.subscriptionId = subscriptionId
    }

    return httpClient(`${BASE_URL}/billing/subscription/${customerId}/update`, {
        method: 'POST',
        body: JSON.stringify(body)
    })
}

export const payAnOrder = ({customerId, stripeTokenId, orderId, sourceId}) => {
    customerId = LocalStorage.instance.getCustomerId()
    return httpClient(`${BASE_URL}/billing/stripe/${customerId}/pay`, {
        method: 'POST',
        body: JSON.stringify({customerId, stripeTokenId, orderId, sourceId})
    })
}

export const deleteCard = (cardId) => {
    const customerId = LocalStorage.instance.getCustomerId()
    return httpClient(`${BASE_URL}/billing/stripe/${customerId}/delete-card/${cardId}`, {
        method: 'DELETE'
    })
}

export const setAsDefaultCard = (cardId) => {
    const customerId = LocalStorage.instance.getCustomerId()
    return httpClient(`${BASE_URL}/billing/stripe/${customerId}/set-as-default-card/${cardId}`, {
        method: 'PUT'
    })
}

export const getOrderById = (orderId) => {
    const customerId = LocalStorage.instance.getCustomerId()
    return httpClient(`${BASE_URL}/billing/order/${customerId}/detail/${orderId}`, {method: 'GET'})
}

export const getListPeriodsByPlanId = (planId) => {
    return httpClient(`${BASE_URL}/billing/period_plan?${stringify({tierPlanId: planId})}`)
}

export const getListCustomerCards = () => {
    return httpClient(`${BASE_URL}/billing/stripe/${LocalStorage.instance.getCustomerId()}/get-list-payment-sources`)
}

export const getSubscriptionById = (subId) => {
    const customerId = LocalStorage.instance.getCustomerId()
    return httpClient(`${BASE_URL}/billing/subscription/${subId}/detail`, {method: 'GET'})
}

export const getCurrentActiveUsersOfSubscription = (subId) => {
    const customerId = LocalStorage.instance.getCustomerId()
    return httpClient(`${BASE_URL}/user-management/users/${LocalStorage.instance.getCustomerId()}/count-active-users?${stringify({subscriptionId: subId})}`, {method: 'GET'})
}

export const calculateOrderAmount = (orderDetail) => {
    return calculateAmount(orderDetail);
}

export const preCheckPayment = (subId) => {
    const customerId = LocalStorage.instance.getCustomerId()
    return httpClient(`${BASE_URL}/billing/subscription/${customerId}/precheck_payment?${stringify({subscriptionId: subId})}`, {method: 'POST'})
}

export const calculateAmount = (req) => {

    const {action, numberOfUsers, tierId, periodId, subscriptionId, subscriptionDetail} = req;

    log.debug('In calculateAmount', req)

    let body = {};

    const customerId = LocalStorage.instance.getCustomerId()

    if (action && action === EXTEND_ACTION) {
        // extend - we dont apply trial term
        body = {
            customerId: customerId,
            numberOfUsers: numberOfUsers,
            paymentPlanIds: [
                tierId,
                periodId
            ],
            applyTrial: false
        }
    } else if (action === EDIT_ACTION) {
        // support increase user
        let deltaNumberOfUsers = numberOfUsers - (subscriptionDetail ? subscriptionDetail.numberOfUsers : 0);

        body = {
            customerId: customerId,
            numberOfUsers: deltaNumberOfUsers,
            paymentPlanIds: [
                tierId,
                periodId
            ],
            calculateOnRemainingTime: true,
            subscriptionId: subscriptionId
        }

    } else {
        body = {
            customerId: customerId,
            numberOfUsers: numberOfUsers,
            paymentPlanIds: [
                tierId,
                periodId
            ]
        }
    }

    return httpClient(`${BASE_URL}/billing/subscription/calculate`, {
        method: 'POST',
        body: JSON.stringify(body)
    })


}

export const getExistSubcription = () => {
    return httpClient(`${BASE_URL}/billing/subscription/${LocalStorage.instance.getCustomerId()}/search`, {
        method: 'POST',
        body: JSON.stringify({
            limit: 100,
            page: 0
        })
    })
}
