import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export type ConfirmDialogRef = {
    show: () => void,
    setContent: (strTitle, strBody) => void
}

type ConfirmDialogProps = {
    okCallback: () => void
}

const ConfirmDialog = React.forwardRef<ConfirmDialogRef, ConfirmDialogProps>(({okCallback}, ref) => {
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState("Send selected activation code via email?");
    const [body, setBody] = React.useState(
        `Let Google help apps determine location. This means
        sending anonymous location data to Google, even when no
        apps are running`
    );

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setOpen(false);
        okCallback();
    };

    React.useImperativeHandle(ref, () => ({
        show() {
            setOpen(true)
        },

        setContent(strTitle, strBody) {
            setTitle(strTitle)
            setBody(strBody)
        }
    }));

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOk} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default ConfirmDialog;
