import {Button as MuiButton} from "@material-ui/core";
import AutoRenewIcon from "@material-ui/icons/Autorenew";
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, {createRef, useEffect} from 'react';
import {BulkDeleteButton, useMutation, useNotify, useRefresh, useUnselectAll} from 'react-admin';
import {useParams} from 'react-router-dom';
import {ACTION_DOWNLOAD_ZIP, ACTION_RE_GENERATE, ACTION_SEND_MAIL} from '../../dataProvider/activationCodeDataProvider';
import ConfirmDialog, {ConfirmDialogRef} from "./ConfirmDialog";
import {downloadFileWithAuth} from './ListActivationCode';
import log from '../../utils/log';

const ActivationCodeBulkActions = (props) => {
    const {selectedIds, callback} = props;
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();
    const {tokenPackId} = useParams<{ tokenPackId: string }>();

    const [sendMail, sendMailState] = useMutation(
        {
            type: ACTION_SEND_MAIL,
            resource: "activation-code",
            payload: {data: {ids: selectedIds}},
        },
        {
            onSuccess: (response) => {
                log.info("Done", response);
                notify(`${response.data.length} selected activation code(s) are sent successfully.`);
                unselectAll("activation-code");
            },
        }
    );

    const [reGenerate, reGenerateState] = useMutation(
        {
            type: ACTION_RE_GENERATE,
            resource: "activation-code",
            payload: {data: {ids: selectedIds, tokenPackId, all: false}},
        },
        {
            onSuccess: (response) => {
                log.info("Done", response);
                notify(`Re-generate ${response.data.length} activation codes`);
                unselectAll("activation-code");
                refresh();
            },
        }
    );

    const [downloadZip] = useMutation(
        {
            type: ACTION_DOWNLOAD_ZIP,
            resource: "activation-code",
            payload: {data: {ids: selectedIds, tokenPackId}},
        },
        {
            onSuccess: ({data}) => {
                log.info("Done", data);
                notify(`Zip ${selectedIds.length} activation codes`);
                unselectAll("activation-code");
                refresh();
                downloadFileWithAuth(tokenPackId, data.fileName);
            },
        }
    );

    useEffect(() => {
        callback(reGenerateState.loading || sendMailState.loading);
    }, [reGenerateState.loading, sendMailState.loading]);

    const confirmDialogRef = createRef<ConfirmDialogRef>()

    return (
        <>
            <BulkDeleteButton
                {...props}
                style={{marginTop: 3, marginRight: 3}}
                undoable={false}
            />
            <MuiButton
                variant="text"
                color="primary"
                startIcon={<EmailIcon/>}
                onClick={() => {
                    confirmDialogRef?.current?.setContent(
                        "Send activation code selected.",
                        `We are going send (${selectedIds.length}) activation codes.`
                    );
                    confirmDialogRef?.current?.show();
                }}
                disabled={sendMailState.loading}
            >
                Send Mail
            </MuiButton>
            <MuiButton
                variant="text"
                color="default"
                startIcon={<AutoRenewIcon/>}
                onClick={reGenerate}
                disabled={reGenerateState.loading}
            >
                Re-generate
            </MuiButton>
            <MuiButton
                color="default"
                variant="text"
                onClick={downloadZip}
                startIcon={<GetAppIcon/>}
            >
                Download Zip QR Code
            </MuiButton>
            <ConfirmDialog ref={confirmDialogRef} okCallback={() => {
                sendMail()
            }}/>
        </>
    );
};

export default ActivationCodeBulkActions