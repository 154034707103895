import React from 'react';
import { ReferenceField } from 'react-admin';
import pure from 'recompose/pure';

const FullNameField = ({ record = {}, size }) => (

    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        &nbsp;{record && record.companyName ? record.companyName : ''}
    </div>

);

const PureFullNameField = pure(FullNameField);

PureFullNameField.defaultProps = {
    source: 'companyName',
    label: 'resources.customers.fields.name',
};

const CustomerReferenceField = props => (
    <ReferenceField source="customerId" reference="customer" {...props} allowEmpty>
        <PureFullNameField />
    </ReferenceField>
);

CustomerReferenceField.defaultProps = {
    source: 'customerId',
    addLabel: true,
};

export default CustomerReferenceField;
