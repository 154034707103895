import React from "react";

const SvgMenuServices = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="menu_services_svg__clip-path">
        <path
          id="menu_services_svg__Rectangle_1612"
          d="M0 0h24v24H0z"
          data-name="Rectangle 1612"
          transform="translate(411 234)"
          stroke="#707070"
          fill="currentColor"
        />
      </clipPath>
      <style>{".menu_services_svg__cls-3{fill:currentColor}"}</style>
    </defs>
    <g
      id="menu_services_svg__Mask_Group_31"
      data-name="Mask Group 31"
      transform="translate(-411 -234)"
      clipPath="url(#menu_services_svg__clip-path)"
    >
      <g id="menu_services_svg__services" transform="translate(411.302 234)">
        <g id="menu_services_svg__Layer_2" data-name="Layer 2">
          <g id="menu_services_svg__tool">
            <path
              id="menu_services_svg__Path_778"
              d="M16.276 2.03a4.947 4.947 0 103.5 1.446 4.947 4.947 0 00-3.5-1.446zm0 7.136a2.192 2.192 0 112.192-2.192 2.192 2.192 0 01-2.192 2.192z"
              className="menu_services_svg__cls-3"
              data-name="Path 778"
              transform="translate(-3.563 -.638)"
            />
            <circle
              id="menu_services_svg__Ellipse_319"
              cx={1.392}
              cy={1.392}
              className="menu_services_svg__cls-3"
              data-name="Ellipse 319"
              transform="translate(11.492)"
              r={1.392}
            />
            <circle
              id="menu_services_svg__Ellipse_320"
              cx={1.392}
              cy={1.392}
              className="menu_services_svg__cls-3"
              data-name="Ellipse 320"
              transform="translate(15.592 2.838)"
              r={1.392}
            />
            <circle
              id="menu_services_svg__Ellipse_321"
              cx={1.392}
              cy={1.392}
              className="menu_services_svg__cls-3"
              data-name="Ellipse 321"
              transform="translate(15.592 7.136)"
              r={1.392}
            />
            <circle
              id="menu_services_svg__Ellipse_322"
              cx={1.392}
              cy={1.392}
              className="menu_services_svg__cls-3"
              data-name="Ellipse 322"
              transform="translate(11.329 9.894)"
              r={1.392}
            />
            <circle
              id="menu_services_svg__Ellipse_323"
              cx={1.392}
              cy={1.392}
              className="menu_services_svg__cls-3"
              data-name="Ellipse 323"
              transform="translate(7.112 7.136)"
              r={1.392}
            />
            <circle
              id="menu_services_svg__Ellipse_324"
              cx={1.392}
              cy={1.392}
              className="menu_services_svg__cls-3"
              data-name="Ellipse 324"
              transform="translate(7.112 2.391)"
              r={1.392}
            />
            <path
              id="menu_services_svg__Path_779"
              d="M0 24.733l4.482 4.712L6.911 27.1h9.58l6.472-7.068s.975-1.569 0-2.354a2.373 2.373 0 00-2.389 0l-5.5 5.714h-5.18s-.627-1 .629-1.162 3.3-.313 3.3-.313a3.106 3.106 0 001.256-3.141h-8.64z"
              className="menu_services_svg__cls-3"
              data-name="Path 779"
              transform="translate(0 -5.446)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMenuServices;
