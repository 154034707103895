import {stringify} from "query-string";
import {fetchUtils, GET_LIST, GET_MANY} from 'react-admin';
import baseSpringDataProvider from '../../dataProvider/baseSpringDataProvider';
import log from '../../utils/log';
import LocalStorage from '../../utils/services/storage';
import {ACTION_DEACTIVATE_USER, ACTION_SEND_TS_APIN, AMIN_RESET_USER_TOKEN} from './reducers';

export const RESET_FACEID = "RESET_FACEID";

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

	const convertUserSearchFilter = (resource, payload) => {
		// hack: we need a issue. We can't add 2 React Admin List component on single page.
		// We trick 2 differ resource to point to the same one 'users'

		if (resource == 'users' && payload.userServiceListFilter) {
			payload = {...payload, ...payload.userServiceListFilter}
			delete payload.userServiceListFilter
			delete payload.denyUserServiceListFilter
			return payload;
		}
		return payload;
	}

	return baseSpringDataProvider(apiUrl, httpClient, (type, resource, params) => {
		let customerId = LocalStorage.instance.getCustomerId()
		// log.debug('User dataProvider custom action type: ', type, params)

		let result = {
			url: "",
			options: {}
		}

		switch (type) {
			case GET_MANY:
				let idStr = { ids: params.ids };
				result.url = `${apiUrl}/${resource}/${customerId}/details?${stringify(idStr)}`;
				break;
			case GET_LIST: {
				const {page, perPage} = params.pagination;
				const {field, order} = params.sort;

				const convertFilter = convertUserSearchFilter(resource, params.filter);

				//// log.debug('===>USERS GET LIST:', convertFilter);

				const query = {
					page: page - 1,
					pageSize: perPage,
					...convertFilter
				};

				//// log.debug('===>USERS GET LIST:query', query);

                const customerId = LocalStorage.instance.getCustomerId();
                if (customerId && customerId.length > 0) {
                    result.url = `${apiUrl}/${resource}/${customerId}/search?${stringify(query)}`;
                } else {
                    result.url = `${apiUrl}/${resource}/search?${stringify(query)}`;
                }

				break;
			}

			case ACTION_SEND_TS_APIN:
				result.url = `${apiUrl}/send-ts-apin/${params.id}`
				result.options = {
					method: "PUT",
					body: JSON.stringify({id: params.data.id})
				}
				break;

			case AMIN_RESET_USER_TOKEN:
				result.url = `${apiUrl}/${resource}/${LocalStorage.instance.getCustomerId()}/${params.id}`
				result.options = {
					method: "PUT",
					body: JSON.stringify({id: params.data.id})
				}
				break;
			case ACTION_DEACTIVATE_USER:
				result.url = `${apiUrl}/${resource}/${params.data.id}`
				result.options = {
					method: "POST",
					body: JSON.stringify({userId: params.data.id})
				}
				break;
			case RESET_FACEID:
				result.url = `${apiUrl}/${resource}`
				console.log("RESET_FACEID: ", params)
				result.options = {
					method: "DELETE",
					body: JSON.stringify({userId: params.userId, tokenPackId: params.tokenPackId })
				}
				break;
			default:
				log.info(`Check type ${type} in base provider`);
				break;
		}

		return result
	})

}
