import React, {createRef, Ref, useImperativeHandle} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type DialogCreateConnectorProps = {
    onSubmit?: (type: string, name: string | undefined) => void
}

export type DialogCreateConnectorRef = {
    createConnector: (email: string) => void
} | null;

const DialogCreateConnector = React.forwardRef<DialogCreateConnectorRef, DialogCreateConnectorProps>((props: DialogCreateConnectorProps, ref) => {
    const [open, setOpen] = React.useState(false);
    const [connectorType, setConnectorType] = React.useState("");
    const inputRef = createRef<HTMLInputElement>()

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (props.onSubmit) {
            props.onSubmit(connectorType, inputRef?.current?.value)
        }
        handleClose()
    }

    useImperativeHandle(ref, () => ({
        createConnector: (connectorType: string) => {
            setConnectorType(connectorType)
            setOpen(true)
        }
    }))

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create {connectorType} connector</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Connector name"
                    type="text"
                    fullWidth
                    inputRef={inputRef}
                    onKeyUp={event => {
                        if (event.key === 'Enter') handleSubmit()
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="secondary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
})

export default DialogCreateConnector