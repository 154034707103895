import log from "../../utils/log";
export default (obj, path, value) => {
    console.group("deep-set")
    log.debug(obj, path, value)
    let i;
    path = path.split('.');
    for (i = 0; i < path.length - 1; i++)
    {

        log.debug("Loop ", i, obj)

        if (!obj) {
            log.debug('Wrong :: ', path[i])
        }

        if (path[i].includes('[')) {
            let splitted = path[i].split('[')
            // log.debug('Found array in path ', splitted[0], splitted[1].substring(0, splitted[1].length - 1))
            obj = obj[splitted[0]]
            obj = obj[splitted[1].substring(0, splitted[1].length - 1)]
        } else {
            obj = obj[path[i]];
        }
    }

    log.debug('assign value :: ', i, path[i], obj)
    obj[path[i]] = value;

    console.groupEnd()
}