import React from "react";

const SvgMyProfile = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="my-profile_svg__clip-path">
        <path
          id="my-profile_svg__Rectangle_1647"
          d="M0 0h24v24H0z"
          className="my-profile_svg__cls-1"
          data-name="Rectangle 1647"
        />
      </clipPath>
      <style>{".my-profile_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g
      id="my-profile_svg__Mask_Group_66"
      data-name="Mask Group 66"
      clipPath="url(#my-profile_svg__clip-path)"
    >
      <g id="my-profile_svg__outline-picture_in_picture-24px">
        <path
          id="my-profile_svg__Path_886"
          d="M0 0h24v24H0z"
          data-name="Path 886"
          fill="none"
        />
        <path
          id="my-profile_svg__Path_887"
          d="M15.917 6.659h-6.63v5.488h6.63zm-1.657 3.659h-3.316v-1.83h3.315zM17.574 3H2.657A1.755 1.755 0 001 4.829v12.806a1.738 1.738 0 001.657 1.811h14.917a1.738 1.738 0 001.657-1.811V4.829A1.755 1.755 0 0017.574 3zm0 14.644H2.657V4.811h14.917z"
          className="my-profile_svg__cls-1"
          data-name="Path 887"
          transform="translate(1.876 .767)"
        />
      </g>
    </g>
  </svg>
);

export default SvgMyProfile;
