import AutoRenewIcon from "@material-ui/icons/Autorenew";
import React, {cloneElement, createRef, useEffect, useRef} from 'react';
import {Button, sanitizeListRestProps, TopToolbar, useListContext, useMutation} from 'react-admin';
import {useParams} from 'react-router-dom';
import {ACTION_GENERATE, ACTION_RE_GENERATE} from '../../dataProvider/OTPCodeDataProvider';
import ConfirmDialog, {ConfirmDialogRef} from "./ConfirmDialog";

const   OTPListActions = (props) => {
    const {
        data,
        className,
        exporter,
        filters,
        maxResults,
        callback,
        useRefresh,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();

    const { tokenPackId } = useParams<{tokenPackId: string}>();
    const refresh = useRefresh()

    const [genOTP, { loading, loaded }] = useMutation({
        type: total === 0 ? ACTION_GENERATE : ACTION_RE_GENERATE,
        resource: 'otp',
        payload: { data: {...data, all: true} }
    });



    useEffect(() => {
        if (!loading && loaded) {
            callback(loading)
        }
    }, [loading, loaded])


    const confirmDialogRef = createRef<ConfirmDialogRef>()

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}

            <Button
                disabled={loading}
                onClick={() => {
                    if (total === 0) {
                        genOTP();
                    }
                    else {
                        confirmDialogRef?.current?.setContent(
                            "Re-generate all OTP codes?",
                            "All current OTP codes will be unavailable."
                        )
                        confirmDialogRef?.current?.show()
                    }
                }}
                label={ total > 0 ? "Re-generate all" : "Generate" }
            >
                <AutoRenewIcon />
            </Button>

            <ConfirmDialog ref={confirmDialogRef} okCallback={() => {
                genOTP()
            }} />
        </TopToolbar>
    );
};

export default OTPListActions
