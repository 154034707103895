import CloudIcon from '@material-ui/icons/Cloud';
import Apps from '@material-ui/icons/Apps';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {MenuItemLink, translate, WithPermissions} from 'react-admin';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import SubMenu from '../SubMenu';
import LocalStorage, {SCOPE_VKEY} from '../../utils/services/storage';
import SecurityChecker from '../../utils/security';
import withStyles from '@material-ui/core/styles/withStyles';
import {updateScope as updateScopeAction} from '../menuReducers'
import MenuRolesIcon from '../../Icons/MenuRoles';
import MenuCustomersIcon from '../../Icons/MenuCustomers';
import MenuAccountsIcon from '../../Icons/MenuAccounts';
import ConnectorsIcon from "@material-ui/icons/Settings";
import SAMLIcon from '@material-ui/icons/DeviceHub';
import PermissionAssetIcon from '../../Icons/PermissionAsset24Px';

const styles = {
    active: {
        backgroundColor: '#533475',
    },
    default: {
        color: 'white'
    },
    contextContent: {
        position: 'fixed',
        bottom: 15,
        color: 'white',
        fontSize: '1.2rem',
        // padding: 16,
        maxWidth: 250,
        textAlign: 'center',
        width: '100%'
    }
};

class VKeyIdmMenu extends Component {

    constructor(props) {
        super(props)
    }

    state = {
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
        menuUsers: false,
        menuSettings: false,
        menuServices: false,
        customerId: null,
        //customerServices: []
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    async UNSAFE_componentWillMount() {

        // Load saml config for this customer
        // var { data } = await dataProvider(GET_ONE, 'saml', {});
        // this.setState({ samlId: data.id });

        let customerId = LocalStorage.instance.getCustomerId();

        this.setState({
            customerId: customerId
        });

        // Load localstorage and set scope
        let scope = LocalStorage.instance.getCurrentScope()
        this.props.updateScope(scope)
    }

    render() {

        const { originalScope, currentScope, onMenuClick, open, logout, translate, permissions, classes, updateScope } = this.props;

        let scope = currentScope;
        // log.debug('Render vkey menu with originalScope', originalScope)
        // log.debug('Render vkey menu with currentScope', currentScope)

        return (
            <div id="left-menu">
                {' '}
                {/* <DashboardMenuItem onClick={onMenuClick} style={styles.default} activeStyle={styles.active} /> */}

                <WithPermissions
                    {...this.props}
                    render=
                    {({ permissions }) => (

                        SecurityChecker(permissions, 'customer', ['view', 'configure'], 'OR') &&
                        scope == SCOPE_VKEY
                            ?
                            <MenuItemLink
                                to={`/customer`}
                                primaryText={translate(`resources.customers.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<MenuCustomersIcon />}
                                onClick={onMenuClick}
                                activeStyle={styles.active}
                                style={styles.default}
                            />
                            : null
                    )}
                />

                <WithPermissions
                    {...this.props}
                    render=
                    {({ permissions }) => (

                        SecurityChecker(permissions, 'service_template', ['view', 'configure'], 'OR') && 
                        scope == SCOPE_VKEY
                            ?
                            <MenuItemLink
                                to={`/service-template`}
                                primaryText={translate(`resources.serviceTemplates.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<Apps />}
                                onClick={onMenuClick}
                                activeStyle={styles.active}
                                style={styles.default}
                            />
                            : null
                    )}
                />

                {(this.state.customerId == undefined || this.state.customerId == null) &&
                    <WithPermissions
                        {...this.props}
                        render=
                        {({ permissions }) => (

                            SecurityChecker(permissions, 'account', ['view', 'configure'], 'OR')
                                ?
                                <MenuItemLink
                                    to={`/account`}
                                    primaryText={translate(`resources.accounts.name`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<MenuAccountsIcon />}
                                    onClick={onMenuClick}
                                    activeStyle={styles.active}
                                    style={styles.default}
                                />
                                : null
                        )}
                    />
                }

                {/* {(this.state.customerId == undefined || this.state.customerId == null) &&
                    <WithPermissions
                        {...this.props}
                        render=
                        {({ permissions }) => (

                            SecurityChecker(permissions, 'account', ['view', 'configure'], 'OR')
                                ?
                                <MenuItemLink
                                    to={`/subscription`}
                                    primaryText={translate(`resources.billing.subscription`, {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<MenuAccountsIcon />}
                                    onClick={onMenuClick}
                                    activeStyle={styles.active}
                                    style={styles.default}
                                />
                                : null
                        )}
                    />
                } */}

                <WithPermissions
                    {...this.props}
                    render=
                    {({ permissions }) => (

                        SecurityChecker(permissions, 'asset', ['view', 'configure'], 'OR') &&
                        scope === SCOPE_VKEY
                            ?
                            <MenuItemLink
                                to={`/asset`}
                                primaryText={translate(`resources.assets.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<PermissionAssetIcon />}
                                onClick={onMenuClick}
                                activeStyle={styles.active}
                                style={styles.default}
                            />
                            : null
                    )}
                />

                <WithPermissions
                    {...this.props}
                    render=
                    {({ permissions }) => (

                        SecurityChecker(permissions, 'role', ['view', 'configure'], 'OR')
                        && scope == SCOPE_VKEY
                            ?
                            <MenuItemLink
                                to={`/role`}
                                primaryText={translate(`resources.roles.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<MenuRolesIcon />}
                                onClick={onMenuClick}
                                activeStyle={styles.active}
                                style={styles.default}
                            />
                            : null
                    )}
                />

                <SubMenu
                    handleToggle={() => this.handleToggle('menuSettings')}
                    isOpen={this.state.menuSettings}
                    sidebarIsOpen={open}
                    name="pos.menu.settings"
                    icon={<ConnectorsIcon />}
                    style={styles.default}
                >
                    <WithPermissions
                        {...this.props}
                        render=
                            {({ permissions }) => (

                                SecurityChecker(permissions, 'vos-cloud-configuration', ['view', 'modify'], 'OR') &&
                                scope === SCOPE_VKEY
                                    ?
                                    <MenuItemLink
                                        to={`/vos-cloud-config`}
                                        primaryText={translate(`resources.vos.cloudconfig`, {
                                            smart_count: 2,
                                        })}
                                        leftIcon={<CloudIcon />}
                                        onClick={onMenuClick}
                                        style={styles.default}
                                        activeStyle={styles.active}
                                    />: null
                            )}
                    />

                    <WithPermissions
                        {...this.props}
                        render=
                            {({ permissions }) => (
                                /*SecurityChecker(permissions, 'vos-cloud-configuration', ['view', 'modify'], 'OR') &&*/
                                scope === SCOPE_VKEY
                                    ?
                                    <MenuItemLink
                                        to={`/saml-settings`}
                                        primaryText={translate(`resources.saml.vkey_menu`, {
                                            smart_count: 2,
                                        })}
                                        leftIcon={<SAMLIcon />}
                                        onClick={onMenuClick}
                                        style={styles.default}
                                        activeStyle={styles.active}
                                    />: null
                            )}
                    />

                </SubMenu>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    serviceLaunchData: state.serviceLaunchData,
    scope: state.menu.scope
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {
            updateScope: updateScopeAction
        }
    ),
    translate,
    withStyles(styles)
);

export default enhance(VKeyIdmMenu);
