import React from "react";

const SvgMenuSubscription = props => (
  <svg width={24} height={24} {...props}>
    <path d="M0 0h24v24H0z" data-name="Path 930" fill="none" />
    <path
      d="M20 3H5a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h15a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2zm0 2v3H5V5zm-5 14h-5v-9h5zM5 10h3v9H5zm12 9v-9h3v9z"
      data-name="Path 931"
      fill="#fff"
    />
  </svg>
);

export default SvgMenuSubscription;
