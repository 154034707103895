import React from "react";

const SvgThankubackground = props => (
  <svg viewBox="0 0 1001 636" {...props}>
    <defs>
      <filter
        id="thankubackground_svg__a"
        width="100%"
        height="100%"
        x={0}
        y={0}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur result="blur" stdDeviation={3} />
        <feFlood floodOpacity={0.114} />
        <feComposite in2="blur" operator="in" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g filter="url(#thankubackground_svg__a)">
      <g data-name="Rectangle 1665" fill="#fff" stroke="#ece1f7">
        <path d="M9 6h983v618H9z" stroke="none" />
        <path d="M9.5 6.5h982v617H9.5z" fill="none" />
      </g>
    </g>
    <g fill="#ce67fc">
      <path
        d="M991.984 551.55c-148.79-.971-152.3 15.709-250.178 15.709s-155.979-24.728-257.477-24.728-131.914 3.748-241.153 3.748S11.311 517.06 11.311 517.06v106.993h980.673z"
        data-name="Path 201"
        opacity={0.05}
      />
      <path
        d="M991.984 577.563c-159.409-.8-146.054-25.488-250.919-25.488s-146.217 37.84-304.313 37.84c-86.338 0-158.287-37.84-237.249-37.84s-188.192 16.98-188.192 16.98v55h980.673z"
        data-name="Path 202"
        opacity={0.2}
      />
      <path
        d="M9 572.458c164.744-.691 162.543-40.5 270.917-40.5s139.508 60.159 302.894 60.159c116.047 0 154.754-34.277 236.359-34.277s172.814 14.619 172.814 14.619v51.6H9z"
        data-name="Path 203"
        opacity={0.065}
      />
    </g>
  </svg>
);

export default SvgThankubackground;
