export const PACKAGE_TYPE_FREE = "FREE"

export const PACKAGE_TYPE_NORMAL = "NORMAL"

export const PACKAGE_TYPE_ENTERPRISE = "ENTERPRISE"

export const PACKAGE_ACTION_TITLE = {}
PACKAGE_ACTION_TITLE[PACKAGE_TYPE_FREE] = "Try It Now"
PACKAGE_ACTION_TITLE[PACKAGE_TYPE_NORMAL] = "Subscribe Me"
PACKAGE_ACTION_TITLE[PACKAGE_TYPE_ENTERPRISE] = "Contact Us"

export const PERIOD_MONTH = "MONTH"
export const PERIOD_YEAR = "YEAR"
