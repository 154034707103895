
import React, { Fragment } from 'react';
import {
    TextField
} from 'react-admin';
import get from 'lodash/get';
import FaInfoIcon from '../Icons/InfoCircleSolid'
import ReactTooltip from 'react-tooltip'

const VkeyTextField = ({ source, format, record = {}, tooltip, classes, ...rest }) => {
    let value = get(record, source);
    const tempstatus = 'tempstatus';

    if (format && typeof format === 'function') {
        value = format(value);
        record[tempstatus] = value;
    }
    return (
        <Fragment>
            <TextField style={{ display: 'inline' }} sortable={false} {...rest} source={tempstatus} record={record} />
            { tooltip &&
                <Fragment>
                    <span data-tip={tooltip} ><FaInfoIcon style={{ color: 'gray', width: 12, height: 14, opacity: .4 , marginLeft: '5px' }} /></span>
                    <div><ReactTooltip place="right" delayShow={100} className={classes.tooltip} html={true} /></div>
                </Fragment>
            }
        </Fragment>
    );
};

export default VkeyTextField;