import React from "react";

const SvgMenuOrdersMgmt = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="menu_orders_mgmt_svg__clip-path">
        <path
          id="menu_orders_mgmt_svg__Rectangle_1678"
          d="M0 0h24v24H0z"
          className="menu_orders_mgmt_svg__cls-1"
          data-name="Rectangle 1678"
        />
      </clipPath>
      <style>{".menu_orders_mgmt_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g
      id="menu_orders_mgmt_svg__Mask_Group_67"
      data-name="Mask Group 67"
      clipPath="url(#menu_orders_mgmt_svg__clip-path)"
    >
      <g id="menu_orders_mgmt_svg__outline-view_list-24px">
        <path
          id="menu_orders_mgmt_svg__Path_918"
          d="M0 0h24v24H0z"
          data-name="Path 918"
          fill="none"
          opacity={0.87}
        />
        <path
          id="menu_orders_mgmt_svg__Path_919"
          d="M3 5v16.607h17V5zm4 2.372v2.373H5V7.372zM5 14.49v-2.373h2v2.373zm0 2.372h2v2.372H5zm13 2.372H9v-2.372h9zm0-4.745H9v-2.372h9zm0-4.745H9V7.372h9z"
          className="menu_orders_mgmt_svg__cls-1"
          data-name="Path 919"
          transform="translate(0 -.611)"
        />
      </g>
    </g>
  </svg>
);

export default SvgMenuOrdersMgmt;
