import React, { Children, Fragment} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import MuiToolbar from '@material-ui/core/Toolbar';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Cancel from '@material-ui/icons/Cancel';
import { SaveButton, DeleteButton, Button } from 'react-admin';
import log from "../utils/log";

const CancelButton = ({
                          basePath = '',
                          label = 'ra.action.cancel',
                          record = {},
                          icon = <Cancel />,
                          onClick = () => { },
                          redirect = 'list',
                          variant = "outlined",
                          ...rest
                      }) => (
    <Button
        //component={Link}
        label={label}
        onClick={() => {
            log.debug('...on cancel click - redirect to ', basePath, redirect);
            // default redirect to list of resources
            if (typeof redirect !== "function")
            {
                window.globalHistory.push(`${basePath}`);
                return
            }

            if (typeof redirect === "function") {
                window.globalHistory.push(redirect(basePath, record.id, record))
            }
        }}
        variant={variant}
        {...rest}
        style={{ marginRight: 10 }}
    >
        {icon}
    </Button>
);

const styles = theme => ({
    toolbar: {
        backgroundColor: '#f5f5f5'
    },
    desktopToolbar: {
        marginTop: 16
    },
    mobileToolbar: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '16px',
        width: '100%',
        boxSizing: 'border-box',
        flexShrink: 0,
        //backgroundColor: 'white',
        zIndex: 2,
    },
    defaultToolbar: {
        flex: 1,
        display: 'flex',
        //justifyContent: 'space-between',
        justifyContent: 'flex-start',
    },
    spacer: {
        [theme.breakpoints.down('xs')]: {
            height: '5em',
        },
    },
    label: {
        fontSize: 0
    },
    test: {

    }
});

const valueOrDefault = (value, defaultValue) =>
    typeof value === 'undefined' ? defaultValue : value;

const Toolbar = ({
                     basePath,
                     children,
                     classes,
                     className,
                     handleSubmit,
                     handleSubmitWithRedirect,
                     invalid,
                     pristine,
                     record,
                     redirect,
                     resource,
                     saving,
                     submitOnEnter,
                     width,
                     enableDelete,
                     disableSaveButton = false,
                     ...rest
                 }) => (
    <Fragment>
        <MuiToolbar
            className={classnames(
                { [classes.mobileToolbar]: width === 'xs' },
                { [classes.desktopToolbar]: width !== 'xs' },
                classes.toolbar,
                className
            )}
            //disableGutters
            {...rest}
        >
            {Children.count(children) === 0 ? (
                <div className={classes.defaultToolbar}>
                    <CancelButton
                        basePath={basePath}
                        redirect={redirect}
                    />
                    {disableSaveButton === false &&
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        redirect={redirect}
                        saving={saving}
                        submitOnEnter={submitOnEnter}
                    />
                    }

                    {enableDelete && record &&
                    typeof record.id !== 'undefined' && (
                        <DeleteButton
                            basePath={basePath}
                            record={record}
                            resource={resource}
                        />
                    )}
                </div>
            ) : (
                <div>
                    <CancelButton
                        basePath={basePath}
                        redirect={redirect}
                    />
                    {
                        Children.map(
                            children,
                            button =>
                                button
                                    ? React.cloneElement(button, {
                                        basePath,
                                        handleSubmit: valueOrDefault(
                                            button.props.handleSubmit,
                                            handleSubmit
                                        ),
                                        handleSubmitWithRedirect: valueOrDefault(
                                            button.props.handleSubmitWithRedirect,
                                            handleSubmitWithRedirect
                                        ),
                                        invalid,
                                        pristine,
                                        saving,
                                        submitOnEnter: valueOrDefault(
                                            button.props.submitOnEnter,
                                            submitOnEnter
                                        ),
                                    })
                                    : null
                        )}
                </div>
            )}
        </MuiToolbar>
        <div className={classes.spacer} />
    </Fragment>
);

Toolbar.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    handleSubmit: PropTypes.func,
    handleSubmitWithRedirect: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    submitOnEnter: PropTypes.bool,
    width: PropTypes.string,
};

Toolbar.defaultProps = {
    submitOnEnter: true,
    enableDelete: false
};

const enhance = compose(
    withWidth(),
    withStyles(styles)
);
export default enhance(Toolbar);