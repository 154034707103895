import {createTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {Button, CreateButton, translate, useNotify, useRedirect} from 'react-admin';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {lightTheme} from '../layout/themes';
import LocalStorage, { SCOPE_VKEY } from '../utils/services/storage'
import FinishByService from './components/FinishByService'
import qs from 'query-string'
import ReviewPlanContainer from './home/container/ReviewPlanContainer'
import {BASE_URL} from '../dataProvider'
import httpClient from '../dataProvider/httpClient'
import withWidth from "@material-ui/core/withWidth"
import StripePayment from './home/container/StripePaymentContainer'
import styles from './home/styles'

import {
    DELETE_CARD,
    EDIT_PACKAGE,
    EXTEND_PACKAGE,
    LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION,
    LOAD_ORDER_DETAIL,
    LOAD_PACKAGES_AND_FEATURES,
    LOAD_PERIODS_BY_PACKAGE,
    LOAD_SUBSCRIPTION_DETAIL,
    SET_PRIMARY_CARD,
    SUBSCRIBE_PACKAGE,
    UPGRADE_PACKAGE
} from "./home/homeSaga";
import {
    clearHomeData,
    setLoading,
    updateCurrentStep,
    updateHoveredPackage,
    updateMessage,
    updateOrderDetail,
    updateSelectedPackage
} from './home/homeReducers'
import {useLocation} from "react-router";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// Styles for modal
const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 4,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
};

const modalContentStyle = {
    background: "white",
    padding: "20px",
    borderRadius: "5px",
}

const Home = (props) => {
    const [currentStep, setCurrentStep] = useState("0")
    const [loadingSalePackage, setLoadingSalePackage] = useState(true)
    const [hadFreeSub, setHadFreeSub] = useState(false)
    const location = useLocation()

    useEffect(() => {

        // Check OIDC login parameter
        if (qs.parse(window.location.search).client_id) {
            window.location.replace(`/login${window.location.search}`)
        }

        const { step } = location.search
        if (step)
            props.updateCurrentStep(parseInt(step))

        const { loadListPackagesAndFeatures, loadOrderDetail, loadSubscriptionDetail, loadCurrentActiveUsersSubscription } = props

        loadListPackagesAndFeatures();

        const query = qs.parse(location.search)
        if (!!query.orderId ) { // After order was created => Go to payment if pro package
            loadOrderDetail(query.orderId)
        }

        // Check free existed
        if (LocalStorage.instance.getUser()) {
            _checkFreePackageExisting().then(r => console.count("_checkFreePackageExisting"));
        }

        setLoadingSalePackage(false)

        return () => {
            props.clearHomeData()
            window.removeEventListener('vos-ready', loadListPackagesAndFeatures)
        }
    }, [])

    const _checkFreePackageExisting = async () => {
        try {
            const { json: { contents } } = await httpClient(`${BASE_URL}/billing/subscription/${LocalStorage.instance.getCustomerId()}/search`, {
                method: 'POST',
                body: JSON.stringify({
                    limit: 100,
                    page: 0
                })
            })

            let existedFree = contents.find(sub => sub.tierPlanType === 'FREE')
            if (existedFree) {
                setHadFreeSub(true)
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        setCurrentStep(qs.parse(location.search).step)
    }, [location.search])

    const closeDialog = () => {
        props.updateMessage(false)
    }

    const {
        classes, translate, history, width, salePackages,
        loading, features, hoveredPackage, selectedPackage, message,
    } = props

    if (!salePackages || salePackages.length === 0) {
        return null
    }

    const isMobile = width === 'xs'

    const { action, upgradeFromTierId, upgradeFromTierPlanType, tierId,
        periodId, tierPlanType, orderId, subscriptionId, billing, done, step } = qs.parse(location.search)

    const newSubscription = () => {
        console.log("running my fun1..");
        let pTierId = "";
        const professional = "PROFESSIONAL";
        for (const p in salePackages) {
            if (salePackages[p].name === professional) {
                pTierId = salePackages[p].id
                break;
            }
        }
        history.push(`/subscription?tierId=${pTierId}&step=1`);
    }

    const closeNewSubscription = () => {
        history.push(`/subscription`);
        // history.replace(`/my-account/subscription`);
        // history.go(0);
    }

        return (
            <>
                {(LocalStorage.instance.getOriginalScope() === SCOPE_VKEY) && <div style={{
                    background: '#ffffff',
                    display: 'flex',
                    justifyContent: "end",
                    zIndex: 4,
                    paddingTop: 0,
                    paddingBottom: 10,
                    paddingRight: 10
                }}>
                    <Button style={{fontSize: 14, paddingLeft: 5}} label="+ Create" onClick={newSubscription} />
                </div>}

                {! (!tierId && !orderId) &&

                <div style={modalStyle}>
                    <div style={modalContentStyle}>
                        <div style={{position: "relative"}}>
                            <IconButton style={{position: "absolute", right: "10px", zIndex: 6}}
                                        onClick={closeNewSubscription}>
                                <CloseIcon/>
                            </IconButton>
                        </div>

                        {(currentStep === "1" && !done) &&
                            <ReviewPlanContainer location={props.location} history={props.history} />
                        }

                        {currentStep === "2" &&
                            <StripePayment location={props.location} history={props.history} {...props}/>}

                        {(done) &&
                            <FinishByService {...props} />
                        }
                    </div>
                </div>
                }
            </>
        );

}

Home.propTypes = {

};

const mapStateToProps = state => ({
    loading: state.home.loading,
    cSvTemplate: state.home.cSvTemplate,
    mode: state.home.mode,
    salePackages: state.home.salePackages,
    features: state.home.features,
    hoveredPackage: state.home.hoveredPackage,
    message: state.home.message,
    selectedPackage: state.home.selectedPackage,
    currentStep: state.home.currentStep,
    orderDetail: state.home.orderDetail,
    periods: state.home.periods,
    subscriptionDetail: state.home.subscriptionDetail,
    currentActiveUsersSubscription: state.home.currentActiveUsersSubscription
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
            loadListPackagesAndFeatures: () => ({
                type: LOAD_PACKAGES_AND_FEATURES
            }),
            subscribePackage: (pack, additionalData) => ({
                type: SUBSCRIBE_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            upgradePackage: (pack, additionalData) => ({
                type: UPGRADE_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            extendPackage: (pack, additionalData) => ({
                type: EXTEND_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            editPackage: (pack, additionalData) => ({
                type: EDIT_PACKAGE, payload: {
                    selectedPackage: pack,
                    additionalData: additionalData
                }
            }),
            loadOrderDetail: (orderId) => ({
                type: LOAD_ORDER_DETAIL,
                payload: orderId
            }),
            loadSubscriptionDetail: (subscriptionId) => ({
                type: LOAD_SUBSCRIPTION_DETAIL,
                payload: subscriptionId
            }),
            loadCurrentActiveUsersSubscription: (subscriptionId) => ({
                type: LOAD_CURRENT_ACTIVE_USERS_SUBSCRIPTION,
                payload: subscriptionId
            }),
            loadPeriods: (tierPlanId) => ({
                type: LOAD_PERIODS_BY_PACKAGE,
                payload: tierPlanId
            }),
            deleteCard: (cardId) => ({
                type: DELETE_CARD,
                payload: cardId
            }),
            setPrimaryCard: (cardId) => ({
                type: SET_PRIMARY_CARD,
                payload: cardId
            }),
            setLoading,
            updateHoveredPackage,
            updateSelectedPackage,
            updateMessage,
            updateCurrentStep,
            updateOrderDetail,
            clearHomeData
        }
    ),
    withStyles(styles),
    withWidth()
);

const EnhancedHome = enhance(Home);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const HomeWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedHome {...props} />
    </MuiThemeProvider>
);

export default HomeWithTheme;
