import React from "react";

const SvgMyAccount = props => (
    <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="my-account_svg__clip-path">
        <path
          id="my-account_svg__Rectangle_1647"
          d="M0 0h24v24H0z"
          className="my-account_svg__cls-1"
          data-name="Rectangle 1647"
        />
      </clipPath>
      <style>{".my-account_svg__cls-1{fill:#fff}"}</style>
    </defs>
    <g
      id="my-account_svg__Mask_Group_63"
      data-name="Mask Group 63"
      clipPath="url(#my-account_svg__clip-path)"
    >
      <g id="my-account_svg__outline-account_box-24px">
        <path
          id="my-account_svg__Path_880"
          d="M0 0h24v24H0z"
          data-name="Path 880"
          fill="none"
        />
        <path
          id="my-account_svg__Path_881"
          d="M19 5v14H5V5h14m0-2H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2.006 2.006 0 002-2V5a2.006 2.006 0 00-2-2zm-7 9a3 3 0 113-3 3.009 3.009 0 01-3 3zm-6 4.47c1.24-2.148 3.97-2.87 6-2.87s4.881.931 6 2.869z"
          className="my-account_svg__cls-1"
          data-name="Path 881"
        />
      </g>
    </g>
  </svg>
);

export default SvgMyAccount;
