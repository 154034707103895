import * as log from "loglevel";

// log.setDefaultLevel(process.env.REACT_APP_LOG_LEVEL as log.LogLevelDesc);
log.setDefaultLevel("debug")
const apiPath = `${process.env.REACT_APP_API_URL}/log`;
// remote.apply(log, {
//     url: apiPath,
//     json: true,
//     format: (logRec) => {
//         logRec.level = logRec.level.label;
//         logRec.customerId = LocalStorage.instance.getCustomerId()
//         return logRec;
//     },
//     stacktrace: {
//         depth: 3,
//     },
// });

// let tokenInteval = setInterval(() => {
//     if (LocalStorage.instance.getToken() && LocalStorage.instance.getToken() !== '')
//     {
//         remote.setToken();
//         clearInterval(tokenInteval);
//     }
// }, 10000)

export default log;
