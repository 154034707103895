

var securityChecker = (permissions, resource, actionIds, operator) => {


    if (permissions == undefined || resource == undefined || actionIds == undefined) {
        return false;
    }

    if (operator == null)
        operator = 'OR';

    let secRs = false;
    let flag;
    let checkPermissionId;
    let isInitSecRs = false;
    for (let i = 0; i < actionIds.length; i++) {
        checkPermissionId = resource + ':' + actionIds[i];
        flag = permissions[checkPermissionId];
        if (flag == undefined) {
            flag = false;
        }

        if (!isInitSecRs) {
            secRs = flag;
            isInitSecRs = true;
        } else if (operator === 'OR') {
            secRs |= flag;
        } else if (operator === 'AND') {
            secRs &= flag;
        }

    }

    return secRs;

}

export default securityChecker;