import React, { Component, Fragment } from 'react';
import {withStyles} from "@material-ui/core/styles";
import classNames from 'classnames'

const styles = {
    hoverableRoot: {
        opacity: 1,
        transition: 'opacity .3s ease-out'
},
    hoverableHovered: {
        opacity: .5
    }
}

class Hoverable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isHover: false
        }
    }

    render() {

        const { classes } = this.props

        return (<span {...this.props} className={this.state.isHover ? classNames(classes.hoverableRoot, classes.hoverableHovered) : classes.hoverableRoot}
            onMouseEnter={e => this.setState({ isHover: true })}
            onMouseLeave={e => this.setState({ isHover: false})}
            >
            { this.props.children }
        </span>);
    }

}

export default withStyles(styles)(Hoverable)