import React, {Component} from 'react'
import HomeBaseComponent from './HomeBaseComponent'
import qs from 'query-string'
import {updateHoveredPackage} from "./homeReducers";

export default class StepBaseComponent extends HomeBaseComponent {

    parseQuery = () => {
        return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    }

    constructor(props) {
        super(props)
        // Parse query
        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        const { tierId, step } = query
        const { salePackages, updateHoveredPackage, updateCurrentStep } = props

        const selectedPackage = salePackages.filter(pack => pack.id === tierId)[0]
        // log.debug('StepBaseComponent constructor ', tierId, selectedPackage)

        this.props.updateSelectedPackage(selectedPackage)

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.selectedPackage && !!this.props.selectedPackage) {
            const { loadPeriods, selectedPackage } = this.props
            loadPeriods(selectedPackage.id)
        }
    }

    componentDidMount() {

    }
}