import AutoRenewIcon from "@material-ui/icons/Autorenew";
import EmailIcon from "@material-ui/icons/Email";
import React, {createRef, useEffect, useRef, useState} from "react";
import {
    Datagrid,
    DateField,
    Filter,
    List,
    Pagination,
    SearchInput,
    TextField,
    useDataProvider,
    useMutation,
    useNotify,
    useRefresh
} from "react-admin";
import DashboardPageWrapperWithTheme, {DashboardPageWrapperRef} from "../../components/DashboardPageWrapper";
import VKeyListButton from "../../components/VkeyListButton";
import log from "../../utils/log";
import LocalStorage from "../../utils/services/storage";
import OTPListActions from "./OTPListActions";
import ConfirmDialog, {ConfirmDialogRef} from "./ConfirmDialog";
import EmailFormDialog, {EmailFormDialogRef} from "./EmailFormDialog";
import {GET_BY_SERVICE_ID} from "../../dataProvider/customProvider";
import {Input, Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ACTION_RE_GENERATE, ACTION_SEND_MAIL} from "../../dataProvider/OTPCodeDataProvider";
import OTPBulkActions from "./OTPBulkActions";


const Filters = (props) => {
    const {tokenPackId, customerId, serviceId, clientId} = props;

    useEffect(() => {
        log.info("Filter initial.");
    }, []);

    return (
        <Filter {...props}>
            <SearchInput source="username" alwaysOn/>
        </Filter>
    );
};

const RowActionsButtonField = ({source = "", record = {}, clickCallback}) => (
    <span style={{display: "flex"}}>
        <span
            onClick={() => {
                clickCallback(record, ACTION_SEND_MAIL);
            }}
        >
            <VKeyListButton title={"Send code via email."}>
                <EmailIcon/>
            </VKeyListButton>
        </span>
        <span
            onClick={() => {
                clickCallback(record, ACTION_RE_GENERATE);
            }}
        >
            <VKeyListButton title={"Generate code again."}>
                <AutoRenewIcon/>
            </VKeyListButton>
        </span>
    </span>
);

const PasswordInput = (props) => {
    const [values, setValues] = React.useState({
        password: props ? props.record["seedNumber"] : "",
        showPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };
    const style = makeStyles({
        input: {
            border: "none",
            "&hover": {
                cursor: "grab",
            }
        }
    })
    const classes = style();


    return (
        <Tooltip key={Math.random()} title={"Double click to show/hide"}>
            <Input
                inputComponent={"input"}
                className={classes.input}
                onDoubleClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                disabled={!values.showPassword}
            />
        </Tooltip>

    );
}

const OTPList = (props) => {
    const [connectorId, setServiceId] = useState(props.connectorId)
    const dataProvider = useDataProvider();
    const [tokenPack, setTokenPack] = useState<any>();
    const [httpsConfig, setHttpsConfig] = useState<any>()
    const [record, setRecord] = useState<any>();
    const [action, setAction] = useState("");
    const [email, setEmail] = useState("");
    const refresh = useRefresh();
    const notify = useNotify();
    const [reload, setReload] = useState(false);
    const wrapper = createRef<DashboardPageWrapperRef>();
    const emailDialog = createRef<EmailFormDialogRef>();
    const confirmDialog = createRef<ConfirmDialogRef>();
    const reset = () => {
        setEmail("");
        setRecord(null);
        setAction("");
    };
    const [getTokenPack, {data, total, error, loading, loaded}] = useMutation({
        type: GET_BY_SERVICE_ID,
        resource: 'token-pack',
        payload: {serviceId: props.record.id}
    });

    const [reGenerate, reGenerateState] = useMutation(
        {
            type: ACTION_RE_GENERATE,
            resource: "otp",
            payload: {
                data: {
                    ids: [record?.id],
                    customerId: LocalStorage.instance.getCustomerId(),
                    serviceId: props.record.id,
                    clientId: httpsConfig?.clientId,
                    tokenPackId: tokenPack?.id
                }
            },
        },
        {
            onSuccess: (response) => {
                notify("Re-generate OTP successfully.");
                reset();
                refresh();
            },
        }
    );
    useEffect(() => {
        if (reGenerateState.data && reGenerateState.loaded && !reGenerateState.loading) {
            setReload(true)
        }
    }, [reGenerateState]);

    useEffect(() => {
        if (props.record.id) {
            getTokenPack();
        }
    }, [props.record])

    useEffect(() => {
        if (data) {
            setTokenPack(data.find(Boolean))
        }
    }, [data])

    useEffect(() => {

        if (connectorId) {
            dataProvider
                .getOne("https", {id: connectorId})
                .then(({data}) => {
                    setHttpsConfig(data);
                })
        }
    }, []);
    useEffect(() => {
        switch (action) {
            case ACTION_SEND_MAIL:
                log.info("Send mail", record);
                emailDialog?.current?.show(record?.email || "");
                setAction("");
                break;

            case ACTION_RE_GENERATE:
                if (record) {
                    confirmDialog?.current?.setContent(
                        "Re-generate select OTP code.",
                        `Re-generate OTP code for user (${record.username})`
                    );
                    confirmDialog?.current?.show();
                }
                setAction("");
                break;
        }
    }, [record, action]);
    return (
        <DashboardPageWrapperWithTheme
            // title={"OTP Code"}
            ref={wrapper}
        >
            <List
                basePath={"/otp"}
                resource={"otp"}
                actions={
                    <OTPListActions
                        useRefresh={useRefresh}
                        data={{
                            customerId: LocalStorage.instance.getCustomerId(),
                            serviceId: props.record.id,
                            clientId: httpsConfig?.clientId,
                            tokenPackId: tokenPack?.id
                        }}
                        callback={(bLoad) => {
                            wrapper?.current?.loading(bLoad);
                            refresh();
                        }}
                    />
                }
                filters={<Filters/>}
                filter={{
                    serviceId: props.record.id,
                    clientId: httpsConfig?.clientId,
                    tokenpackId: httpsConfig?.tokenpackId
                }}
                empty={false}
                bulkActionButtons={
                    <OTPBulkActions
                        data={{
                            customerId: LocalStorage.instance.getCustomerId(),
                            serviceId: props.record.id,
                            clientId: httpsConfig?.clientId,
                            tokenPackId: tokenPack?.id
                        }}
                        callback={(bLoad) => {
                            wrapper?.current?.loading(bLoad);
                            refresh();
                        }}
                    />
                }
                pagination={
                    <Pagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                    />
                }
            >
                <Datagrid>
                    <TextField source="username"/>
                    <TextField label={"OTP Secret"} source={"seedNumber"}/>
                    <DateField
                        source="generatedDate"
                        locales="vi-VN"
                        showTime={true}
                        label={"Generated date"}
                    />
                    <RowActionsButtonField
                        clickCallback={(record, action) => {
                            setRecord(record);
                            setAction(action);
                        }}
                    />
                </Datagrid>
            </List>
            <EmailFormDialog
                okCallback={(email) => {
                    log.warn("Sending email to ", email);
                    setEmail(email);
                }}
                ref={emailDialog}
            />
            <ConfirmDialog okCallback={() => reGenerate()} ref={confirmDialog}/>
        </DashboardPageWrapperWithTheme>
    );
};

export default OTPList;
