/*const DEFAULT = {
    currentStep: 0,
    loading: true,
    initial: false,
    serviceInstanceId: '',
    steps: [
        {
            title: '',
            shortDescription: '',
            resource: '',
            id: ''
        }
    ]
}*/
import log from '../../utils/log';

const SERVICE_WIZARD_INITIAL = 'service/wizard/INITIAL'

const SERVICE_WIZARD_LOAD = 'service/wizard/LOAD'

const SERVICE_WIZARD_NEXT = 'service/wizard/NEXT'

const SERVICE_WIZARD_PREV = 'service/wizard/PREV'

const SERVICE_WIZARD_JUMP = 'service/wizard/JUMP'

const SERVICE_WIZARD_DESTROY = 'service/wizard/DESTROY'

const SERVICE_WIZARD_WELCOME_INIT = 'service/wizard/WELCOMEINIT'

const SERVICE_WIZARD_START_TOKENPACK = 'service/wizard/START_TOKENPACK'

export const SERVICE_WIZARD_SET_TOKENPACK_DETAIL = 'service/wizard/SET_TOKENPACK_DETAIL'

export const SERVICE_WIZARD_SET_SERVICES_DETAIL = 'service/wizard/SET_SERVICES_DETAIL'

const reducer = (state = {
    currentStep: 0,
    loading: false,
    initial: false,
    serviceInstanceId: false,
    tokenPackId: false,
    steps: [],
    serviceDetails: [],
    tokenPackDetail: {}
}, action) => {
    switch (action.type) {

        case SERVICE_WIZARD_LOAD:
            return {
                ...state,
                serviceInstanceId: action.data.serviceInstanceId,
                loading: true,
                initial: false
            }

        case SERVICE_WIZARD_INITIAL:
            return {
                ...state,
                ...action.data,
                initial: true,
                loading: false
            }

        case SERVICE_WIZARD_NEXT:
            return {
                ...state,
                currentStep: state.currentStep < state.steps.length - 1 ? state.currentStep + 1 : state.currentStep
            }

        case SERVICE_WIZARD_PREV:
            return {
                ...state,
                currentStep: state.currentStep > 0 ? state.currentStep - 1 : state.currentStep
            }

        case SERVICE_WIZARD_JUMP:
            return {
                ...state,
                currentStep: action.data
            }

        case SERVICE_WIZARD_DESTROY:
            return { // Set initial state
                currentStep: 0,
                loading: false,
                initial: false,
                steps: [],
                serviceDetails: []
            }

        case SERVICE_WIZARD_WELCOME_INIT:
            return {
                currentStep: 0,
                loading: false,
                initial: true,
                serviceInstanceId: 'blank',
                steps: [
                    {
                        resource: 'welcome',
                        title: 'resources.wizard.welcome.title'
                    }
                ]
            }

        case SERVICE_WIZARD_START_TOKENPACK:
            return {
                ...state,
                currentStep: 0,
                loading: false,
                initial: true,
                serviceInstanceId: 'blank',
                tokenPackId: action.data.tokenPackId,
                steps: [
                    {
                        resource: 'welcome',
                        title: 'resources.wizard.welcome.title'
                    }
                ],
                serviceDetails: [],
            }

        case SERVICE_WIZARD_SET_TOKENPACK_DETAIL:
            return {
                ...state,
                tokenPackDetail: action.data
            }

        case SERVICE_WIZARD_SET_SERVICES_DETAIL:
            return {
                ...state,
                serviceDetails: action.data
            }

        default:
            return state
    }
}

export const wizardInitial = (data) => {
    return { type: SERVICE_WIZARD_INITIAL, data: data }
}

export const loadInitialData = (serviceInstanceId) => {
    log.info('loadInitialData id = ' + serviceInstanceId)
    return { type: SERVICE_WIZARD_LOAD, data: { serviceInstanceId } }
}

export const nextStep = () => {
    return { type: SERVICE_WIZARD_NEXT }
}

export const prevStep = () => {
    return { type: SERVICE_WIZARD_PREV }
}

export const jumpStep = (step) => {
    return { type: SERVICE_WIZARD_JUMP, data: step }
}

export const destroy = () => {
    return { type: SERVICE_WIZARD_DESTROY }
}

export const welcome = () => {
    return { type: SERVICE_WIZARD_WELCOME_INIT }
}

export const tokenPackStart = (tokenPackId) => {
    if (tokenPackId)
        return { type: SERVICE_WIZARD_START_TOKENPACK, data: {tokenPackId} }
    else
        return {}
}

export default reducer