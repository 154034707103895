import React from "react";

const SvgMenuConnectors = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="menu_connectors_svg__clip-path">
        <path
          id="menu_connectors_svg__Rectangle_1612"
          d="M0 0h24v24H0z"
          data-name="Rectangle 1612"
          transform="translate(411 666)"
          stroke="#707070"
          fill="currentColor"
        />
      </clipPath>
      <style>{".menu_connectors_svg__cls-3{fill:currentColor}"}</style>
    </defs>
    <g
      id="menu_connectors_svg__Mask_Group_37"
      data-name="Mask Group 37"
      transform="translate(-411 -666)"
      clipPath="url(#menu_connectors_svg__clip-path)"
    >
      <g id="menu_connectors_svg__connector" transform="translate(411.07 666)">
        <g id="menu_connectors_svg__Layer_2" data-name="Layer 2">
          <g id="menu_connectors_svg__connector-2" data-name="connector">
            <circle
              id="menu_connectors_svg__Ellipse_332"
              cx={4.121}
              cy={4.121}
              className="menu_connectors_svg__cls-3"
              data-name="Ellipse 332"
              transform="translate(15.619 15.759)"
              r={4.121}
            />
            <path
              id="menu_connectors_svg__Rectangle_1623"
              d="M0 0h6.538v2.133H0z"
              className="menu_connectors_svg__cls-3"
              data-name="Rectangle 1623"
              transform="rotate(90 5.047 15.689)"
            />
            <circle
              id="menu_connectors_svg__Ellipse_333"
              cx={4.121}
              cy={4.121}
              className="menu_connectors_svg__cls-3"
              data-name="Ellipse 333"
              transform="translate(15.121 .02)"
              r={4.121}
            />
            <path
              id="menu_connectors_svg__Rectangle_1624"
              d="M0 0h6.538v2.133H0z"
              className="menu_connectors_svg__cls-3"
              data-name="Rectangle 1624"
              transform="translate(10.006 3.144)"
            />
            <circle
              id="menu_connectors_svg__Ellipse_334"
              cx={4.121}
              cy={4.121}
              className="menu_connectors_svg__cls-3"
              data-name="Ellipse 334"
              r={4.121}
            />
            <path
              id="menu_connectors_svg__Rectangle_1625"
              d="M0 0h6.538v2.133H0z"
              className="menu_connectors_svg__cls-3"
              data-name="Rectangle 1625"
              transform="rotate(-90 8.243 5.115)"
            />
            <rect
              id="menu_connectors_svg__Rectangle_1626"
              width={7.694}
              height={7.694}
              className="menu_connectors_svg__cls-3"
              data-name="Rectangle 1626"
              rx={0.512}
              transform="translate(.344 15.756)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMenuConnectors;
