import httpClient from '../../../dataProvider/httpClient'
import { BASE_URL } from '../../../dataProvider'

export const updateServiceWizardStatus = (payload) => {
    return httpClient(`${BASE_URL}/service-mgmt/service-launch/update-wizard-status`, {
        method: 'PUT',
        body: JSON.stringify({
            id: payload.serviceInstanceId,
            status: payload.status
        })
    })
}

export const getManyServices = ({ ids }) => {
    return httpClient(`${BASE_URL}/service-mgmt/service-launch/details`, {
        method: 'POST',
        body: JSON.stringify(ids)
    })
}