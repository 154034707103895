import baseSpringDataProvider from '../../dataProvider/baseSpringDataProvider'
import {
    fetchUtils,
    GET_ONE,
    UPDATE
} from 'react-admin'
import { stringify } from "query-string";
import LocalStorage from '../../utils/services/storage';

export const GENERATE_FEDERATE_SCRIPT = "GENERATE_FEDERATE_SCRIPT"
export const GENERATE_UNFEDERATE_SCRIPT = "GENERATE_UNFEDERATE_SCRIPT"

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

    return baseSpringDataProvider(apiUrl, httpClient, (type, resource, params) => {
        let customerId = LocalStorage.instance.getCustomerId()
        // log.debug('User dataProvider custom action type: ', type, params)

        let result = {
            url: "",
            options: {

            }
        }

        switch (type) {
            case GET_ONE:
                result.url = `${apiUrl}/${resource}`;
                break;

            case UPDATE:
                result.url = `${apiUrl}/${resource}`;
                result.options.method = "POST";
                result.options.body = JSON.stringify(params.data);
                break;

            case GENERATE_FEDERATE_SCRIPT:
                result.url = `${apiUrl}/${resource}/script/federate-script?domain=${params.domain}&unFederate=0`;
                result.options.method = "GET";
                break;

            case GENERATE_UNFEDERATE_SCRIPT:
                result.url = `${apiUrl}/${resource}/script/federate-script?domain=${params.domain}&unFederate=1`;
                result.options.method = "GET";
                break;

            default:

                break;
        }

        return result
    },
    
    (response, type, resource, params) => {

        // log.debug('custom parse response: ', response, type, resource, params)

        const { headers, json, body } = response;

        let result = undefined;

        switch (type) {

            case GENERATE_FEDERATE_SCRIPT:
                return { data: body };

            case GENERATE_UNFEDERATE_SCRIPT:
                return { data: body };

            default:

                break;
        }

        return result
    })

}