import {ajax} from "rxjs/ajax";
import {API_URL} from "../../dataProvider/httpClient";
import LocalStorage from "../../utils/services/storage";
import {stringify} from "query-string";

const defaultHeader = () => ({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + LocalStorage.instance.getToken()
})

export const getAllSubscriptionApi = () =>
    ajax.post(`${API_URL}/billing/subscription/${LocalStorage.instance.getCustomerId()}/search`, {}, defaultHeader());

export const getServiceTemplateDetailApi = (id: string) =>
    ajax.post(`${API_URL}/service-mgmt/template/detail`, { id }, defaultHeader());

export const getTokenPackBySubscriptionIdApi = (id: string) =>
    ajax.get(`${API_URL}/idm-config/token-pack-v2/get-by-subscription/${id}`, defaultHeader());

export const getConnectorsByTypeApi = (type: string) =>
    ajax.get(`${API_URL}/idm-config/connectors/${type}?${stringify({ 
        filter: JSON.stringify({ customerId: LocalStorage.instance.getCustomerId() }) })}`, defaultHeader());

export const postCreateConnectorApi = (values: Object) =>
    ajax.post(`${API_URL}/service-mgmt/service-launch/create-service`, values , defaultHeader());
