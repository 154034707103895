import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import serviceLaunch, {
    fetchConnectorsByTypeEpics,
    fetchSubscriptionEpic,
    fetchTokenPackEpic,
    getTemplateDetailEpic, postCreateServiceEpics
} from './rootEpic';
import { createEpicMiddleware } from 'redux-observable';
import { createStore, applyMiddleware } from 'redux';
import LocalStorage from "../../utils/services/storage";

export const rootEpic = combineEpics(
    fetchSubscriptionEpic,
    fetchTokenPackEpic,
    getTemplateDetailEpic,
    fetchConnectorsByTypeEpics,
    postCreateServiceEpics
);

export const rootReducer = combineReducers({
    serviceLaunch
});

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        defaultHeader: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + LocalStorage.instance.getToken()
        }
    }
});

function configureStore() {
    const store = createStore(
        rootReducer,
        applyMiddleware(epicMiddleware)
    );

    epicMiddleware.run(rootEpic);

    return store;
}

const store = configureStore()
export default store