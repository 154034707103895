import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY,
} from "react-admin";
import LocalStorage from "../../utils/services/storage";
import log from "../../utils/log";

export const INACTIVE_MANY = "INACTIVE_MANY";
export const ACTIVE_MANY = "ACTIVE_MANY";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const UPDATE_SECURITY_2FA_CFG = "UPDATE_SECURITY_2FA_CFG";
export const GET_SECURITY_2FA_CFG = "GET_SECURITY_2FA_CFG";
export const LOAD_ACC_INFO_BY_TOKEN = "LOAD_ACC_INFO_BY_TOKEN";

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    const convertResource = (resource) => {
        // hack: we need a issue. We can't add 2 React Admin List component on single page.
        // We trick 2 differ resource to point to the same one 'account'
        if (resource == "account_role") {
            return "account";
        }
        return resource;
    };

    const convertAccountSearchFilter = (resource, payload) => {
        // hack: we need a issue. We can't add 2 React Admin List component on single page.
        // We trick 2 differ resource to point to the same one 'account'

        //// log.debug('in convertAccountSearchFilter')
        //// log.debug(resource)
        if (resource == "account_role") {
            payload = { ...payload, ...payload.accountRoleFilter };
            return payload;
        } else if (resource == "account" && payload.accountFilter) {
            payload = { ...payload, ...payload.accountFilter };
            return payload;
        }
        return payload;
    };

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        log.debug("hello");
        const oldResource = resource;
        resource = convertResource(resource);
        // // log.debug('oldResource');
        // // log.debug(oldResource);
        // // log.debug('resource');
        // // log.debug(resource);

        let url = "";
        const options = {};
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;

                let payload = {
                    page: page - 1,
                    limit: perPage,
                    ...params.filter,
                };

                payload = convertAccountSearchFilter(oldResource, payload);
                //// log.debug('payload');
                //// log.debug(payload);

                // check customer scope
                const customerId = LocalStorage.instance.getCustomerId();
                if (customerId && customerId.length > 0) {
                    url = `${apiUrl}/${resource}/${customerId}/search`;
                } else {
                    url = `${apiUrl}/${resource}/search`;
                }
                options.method = "POST";
                options.body = JSON.stringify(payload);

                break;
            }
            case GET_ONE:
                const payload = {
                    id: params.id,
                };
                url = `${apiUrl}/${resource}/detail`;
                options.method = "POST";
                options.body = JSON.stringify(payload);
                break;
            case GET_MANY: {
                const payload = {
                    ids: params.ids
                };
                options.method = "POST";
                options.body = JSON.stringify(payload);
                url = `${apiUrl}/${resource}/details`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                //// log.debug(params)
                const payload = {
                    page: page - 1,
                    limit: perPage,
                    [params.target]: params.id,
                };
                url = `${apiUrl}/${resource}/search`;
                options.method = "POST";
                options.body = JSON.stringify(payload);
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/update`;
                options.method = "PUT";
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}/create_account_organization`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;
            case CHANGE_PASSWORD:
                url = `${apiUrl}/${resource}/change-password`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;
            case UPDATE_SECURITY_2FA_CFG:
                url = `${apiUrl}/${resource}/${params.data.accountId}/security/configure/2fa`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;
            case GET_SECURITY_2FA_CFG:
                url = `${apiUrl}/${resource}/security/configuration/2fa`;
                options.method = "GET";
                break;
            case LOAD_ACC_INFO_BY_TOKEN:
                url = `${apiUrl}/${resource}/load_account_by_token`;
                options.method = "POST";
                break;

            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        //// log.debug('response ---->', response)
        const { headers, json } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!json.hasOwnProperty("totalElements")) {
                    throw new Error(
                        "The numberOfElements property must be must be present in the Json response"
                    );
                }
                return {
                    data: json.contents,
                    total: parseInt(json.totalElements, 10),
                };
            case GET_MANY:
                return {data: json};
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        //resource = convertResource(resource);
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === INACTIVE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/deactivate`, {
                        method: "POST",
                        body: JSON.stringify({ id: id }),
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }

        if (type === ACTIVE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/activate`, {
                        method: "POST",
                        body: JSON.stringify({ id: id }),
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }

        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data),
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "DELETE",
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options)
            .then((response) =>
                convertHTTPResponse(response, type, resource, params)
            )
            .catch((e) => {
                log.error("rest-api", resource, type, e);
                const errorJson = e.body;
                if (errorJson && errorJson.messageKey)
                    throw new Error(errorJson.messageKey);
                else if (errorJson && errorJson.message) {
                    throw new Error(errorJson.message);
                } else throw new Error("error.default.internal.server.error");
            });
    };
};
