import baseSpringDataProvider from '../../dataProvider/baseSpringDataProvider'
import {fetchUtils, GET_LIST} from 'react-admin'
import {stringify} from "query-string";
import log from '../../utils/log';

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

    return baseSpringDataProvider(apiUrl, httpClient, (type, resource, params) => {

        let result = {
            url: "",
            options: {

            }
        }

        switch (type) {
            case GET_LIST:
                const query = {};
                result.url = `${apiUrl}/${resource}${stringify(query)}`
                // url = `${apiUrl}/${resource}/${customerId}/search?page=${page}&pageSize=${perPage}`;
                break;

            default:
                log.info(`Check type ${type} in base provider`);
                break;
        }

        return result
    })

}