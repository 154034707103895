import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { unregister } from './registerServiceWorker';
import log from './utils/log';

// console.error = function(){}
// console.warn = function(){}

String.prototype.width = function (pFontSize, pStyle) {

    var lDiv = document.createElement('div');

    document.body.appendChild(lDiv);

    if (pStyle != null) {
        lDiv.style.fontWeight = pStyle.fontWeight;
    }
    lDiv.style.fontSize = "" + pFontSize + "px";
    lDiv.style.fontFamily = 'sans-serif'
    lDiv.style.position = "absolute";
    lDiv.style.left = -1000;
    lDiv.style.top = -1000;

    lDiv.innerHTML = this;

    //// log.debug(this);
    //// log.debug('font-size', pFontSize);
    //// log.debug('font-weight', lDiv.style.fontWeight);

    // var lResult = {
    //     width: lDiv.clientWidth,
    //     height: lDiv.clientHeight
    // };

    let width = lDiv.clientWidth * 0.75

    // // log.debug('Calculator text width ', this, width)

    document.body.removeChild(lDiv);
    lDiv = null;

    return width;
}

function collectionHas(a, b) { //helper function (see below)
    for(var i = 0, len = a.length; i < len; i ++) {
        if(a[i] == b) return true;
    }
    return false;
}
window.findParentBySelector = function (elm, selector) {
    var all = document.querySelectorAll(selector);
    var cur = elm.parentNode;
    while(cur && !collectionHas(all, cur)) { //keep going up until you find a match
        cur = cur.parentNode; //go up
    }
    return cur; //will return null if not found
}

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
log.info("Unregister all service workers");
unregister();