export default (obj, path) => {
    let paths = path.split('.')
      , current = obj
      , i;
  
    for (i = 0; i < paths.length; ++i) {
        if (!current) return current

        if (paths[i].includes('[')) {
            let splitted = paths[i].split('[')
            current = current[splitted[0]]
            // log.debug("DeepGet ", current)
            current = current[splitted[1].substring(0, splitted[1].length - 1)]
        } else {
            current = current[paths[i]];
        }
    }

    return current;
}