import React from "react";

const SvgMenuCustomers = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="menu_customers_svg__clip-path">
        <path
          id="menu_customers_svg__Rectangle_1612"
          d="M0 0h24v24H0z"
          data-name="Rectangle 1612"
          transform="translate(411 162)"
          stroke="#707070"
          fill="#fff"
        />
      </clipPath>
      <style>{".menu_customers_svg__cls-3{fill:#fff}"}</style>
    </defs>
    <g
      id="menu_customers_svg__Mask_Group_30"
      data-name="Mask Group 30"
      transform="translate(-411 -162)"
      clipPath="url(#menu_customers_svg__clip-path)"
    >
      <g id="menu_customers_svg__customers" transform="translate(411 164.734)">
        <g id="menu_customers_svg__Layer_2" data-name="Layer 2">
          <g id="menu_customers_svg__customers-2" data-name="customers">
            <circle
              id="menu_customers_svg__Ellipse_302"
              cx={2.617}
              cy={2.617}
              r={2.617}
              className="menu_customers_svg__cls-3"
              data-name="Ellipse 302"
              transform="translate(9.391 7.598)"
            />
            <path
              id="menu_customers_svg__Path_768"
              d="M21.215 25.014a7.514 7.514 0 00-13.669 0z"
              className="menu_customers_svg__cls-3"
              data-name="Path 768"
              transform="translate(-2.372 -6.481)"
            />
            <circle
              id="menu_customers_svg__Ellipse_303"
              cx={1.818}
              cy={1.818}
              className="menu_customers_svg__cls-3"
              data-name="Ellipse 303"
              transform="translate(2.931)"
              r={1.818}
            />
            <path
              id="menu_customers_svg__Path_769"
              d="M9.5 9.682a5.221 5.221 0 00-9.5 0z"
              className="menu_customers_svg__cls-3"
              data-name="Path 769"
              transform="translate(0 -2.083)"
            />
            <path
              id="menu_customers_svg__Path_770"
              d="M15.335 8.755a3.349 3.349 0 012.342.927h2.408a5.221 5.221 0 00-9.5 0h2.408a3.353 3.353 0 012.342-.927z"
              className="menu_customers_svg__cls-3"
              data-name="Path 770"
              transform="translate(-3.327 -2.083)"
            />
            <circle
              id="menu_customers_svg__Ellipse_304"
              cx={1.818}
              cy={1.818}
              className="menu_customers_svg__cls-3"
              data-name="Ellipse 304"
              transform="translate(10.19)"
              r={1.818}
            />
            <circle
              id="menu_customers_svg__Ellipse_305"
              cx={1.818}
              cy={1.818}
              className="menu_customers_svg__cls-3"
              data-name="Ellipse 305"
              transform="translate(17.432)"
              r={1.818}
            />
            <path
              id="menu_customers_svg__Path_771"
              d="M30.646 9.682a5.221 5.221 0 00-9.5 0z"
              className="menu_customers_svg__cls-3"
              data-name="Path 771"
              transform="translate(-6.646 -2.083)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMenuCustomers;
