import { stringify } from 'query-string';
import {
    CREATE, DELETE,
    DELETE_MANY, fetchUtils,
    GET_LIST, GET_MANY,
    GET_MANY_REFERENCE, GET_ONE, UPDATE,
    UPDATE_MANY
} from 'react-admin';
import log from "../../utils/log";
import LocalStorage from '../../utils/services/storage';

export const GET_ALL_CONNECTORS = 'GET_ALL_CONNECTORS';
export const MANAGE_SERVICE_USERS = 'MANAGE_SERVICE_USERS';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

    const convertDenyServiceUserSearchFilter = (resource, payload) => {
        // hack: we need a issue. We can't add 2 React Admin List component on single page.
        // We trick 2 differ resource to point to the same one 'users'

        //// log.debug('in convertDenyServiceUserSearchFilter')
        //// log.debug(resource)
        //// log.debug(payload)
        if (resource == 'deny_service_users' && payload.denyUserServiceListFilter) {
            payload = { ...payload, ...payload.denyUserServiceListFilter }
            delete payload.userServiceListFilter
            delete payload.denyUserServiceListFilter
            return payload;
        }

        return payload;
    }


    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {

        let url = '';
        const options = {};
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                // const query = {
                //     sort: JSON.stringify([field, order]),
                //     range: JSON.stringify([
                //         (page - 1) * perPage,
                //         page * perPage - 1,
                //     ]),
                //     filter: JSON.stringify(convertDenyServiceUserSearchFilter(resource, params.filter)),
                // };


                const convertFilter = convertDenyServiceUserSearchFilter(resource, params.filter);

                //// log.debug('===>DENY GET LIST:', convertFilter);

                const query = {
                    page: page - 1,
                    pageSize: perPage,
                    ...convertFilter
                };

                //// log.debug('===>DENY GET LIST:query', query);

                const customerId = LocalStorage.instance.getCustomerId();
                if (customerId && customerId.length > 0) {
                    url = `${apiUrl}/users/${customerId}/search?${stringify(query)}`;
                } else {
                    url = `${apiUrl}/users/search?${stringify(query)}`;
                }

                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY: {
                const query = {
                    filter: JSON.stringify({ id: params.ids }),
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([
                        (page - 1) * perPage,
                        page * perPage - 1,
                    ]),
                    filter: JSON.stringify({
                        ...params.filter,
                        [params.target]: params.id,
                    }),
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            case GET_ALL_CONNECTORS:
                url = `${apiUrl}/connectors/list-type`;
                options.method = 'GET';
                break;
            case MANAGE_SERVICE_USERS:
                url = `${apiUrl}/users/${LocalStorage.instance.getCustomerId()}/manage_service_users`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { headers, json } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!json.data.hasOwnProperty("totalElements")) {
                    throw new Error(
                        "The numberOfElements property must be must be present in the Json response"
                    );
                }

                const result = {
                    data: json.data.content,
                    total: parseInt(json.data.totalElements, 10)
                };

                log.warn("Deny list response ", result);
                return result;
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        ).catch(e => {
            //// log.debug('server resp error')
            //// log.debug(e.status)
            //// log.debug(e.body)
            const errorJson = e.body;
            if (errorJson && errorJson.messageKey)
                throw new Error(errorJson.messageKey);

            else
                throw new Error("error.default.internal.server.error");

        });
    };
};