import baseSpringDataProvider from '../../../dataProvider/baseSpringDataProvider'
import {CREATE, DELETE, DELETE_MANY, fetchUtils, GET_LIST, GET_MANY_REFERENCE, GET_ONE, UPDATE} from 'react-admin'
import LocalStorage from '../../../utils/services/storage';
import {post} from "axios";

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

    const convertHTTPResponse = (response, type, resource, params) => {
        // log.debug('Custom provider convert response ', response)
        const { headers, json } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!headers.has('content-range')) {
                    throw new Error(
                        'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
                    );
                }

                return {
                    data: json.data,
                    total: parseInt(
                        headers
                            .get('content-range')
                            .split('/')
                            .pop(),
                        10
                    )
                };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            default:
                return { data: json.data };
        }
    };
    const addUploadFeature = (type, resource, params) => {
        if (type === 'CREATE' || type === 'UPDATE') {

            let url = '';
            if (type === 'UPDATE') {
                url = `${apiUrl}/${resource}/` + params.data.id + `/update`;
            } else {
                url = `${apiUrl}/${resource}/create`;
            }

            const formData = new FormData();
            let entity = {...params.data};

            formData.append('iconFile', params.data.iconUrl)
            if (params.data.iconUrl && params.data.iconUrl.rawFile) {
                formData.append('iconFile', params.data.iconUrl.rawFile)
            }

            if (type === 'UPDATE' && params.data.iconUrlTemp && params.data.iconUrlTemp.rawFile) {
                formData.append('iconFile', params.data.iconUrlTemp.rawFile)
            }
            delete entity.iconUrl;

            formData.append('json', JSON.stringify(entity));


            let token = LocalStorage.instance.getToken();

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Transfer-Encoding': 'chunked',
                    'Authorization': 'Bearer ' + token
                }
            }

            return post(url, formData, config)
                .then(rsp => {
                    return {data: rsp.data, id: rsp.data.id}
                }).catch(error => {

                    let response = error.response;
                    let errorMessage = {};
                    if (response) {
                        errorMessage = response.data;
                    }
                    throw new Error(
                        errorMessage.message
                    );

                });

        } else {
            // for other request types and resources, fall back to the default request handler
            return requestHandler(type, resource, params);
        }

    };


    const requestHandler  = baseSpringDataProvider(apiUrl, httpClient, (type, resource, params) => {
        let customerId = LocalStorage.instance.getCustomerId()
        let result = {
            url: "",
            options: {}
        }

        switch (type) {
            case GET_LIST:
                // log.debug("get list here")
                result.url = `${apiUrl}/${resource}/${customerId}/search`;
                break;

            case UPDATE:

                const formData = new FormData();
                let entity = {...params.data};
                if (params.data.iconUrl && params.data.iconUrl.rawFile) {
                    formData.append('iconFile', params.data.iconUrl.rawFile)
                }

                if (params.data.iconUrlTemp && params.data.iconUrlTemp.rawFile) {
                    formData.append('iconFile', params.data.iconUrlTemp.rawFile)
                }

                delete entity.iconUrl;

                formData.append('json', JSON.stringify(params.data));
                result.url = `${apiUrl}/${resource}/${params.id}/update`;

                result.options.method = "POST";
                result.options.headers = new Headers({'Content-type': 'multipart/form-data'});

                result.options.body = formData;
                break;

            case CREATE:
                result.url = `${apiUrl}/${resource}/${customerId}`;
                result.options.method = "POST";
                result.options.body = JSON.stringify(params.data);
                break;

            case GET_ONE:
                // log.debug("heer")
                result.url = `${apiUrl}/${resource}/${params.id}`;
                // result.options.method = "GET";
                break;

            case DELETE:
                result.url = `${apiUrl}/${resource}/${customerId}/${LocalStorage.instance.getTokenPackId()}/delete-many`;
                result.options.method = "DELETE";
                result.options.body = JSON.stringify({ids: [params.id]})
                break;

            case DELETE_MANY:
                result.url = `${apiUrl}/${resource}/${customerId}/${LocalStorage.instance.getTokenPackId()}/delete-many`
                result.options.method = "DELETE"
                result.options.body = JSON.stringify({ids: params.ids})
                break;
            default:
                // log.debug('Use baseSpringDataProvider ')
        }

        return result
    })

    return addUploadFeature;
};