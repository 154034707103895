import {
  CREATE, DELETE,
  DELETE_MANY, fetchUtils,
  GET_LIST, GET_MANY,
  GET_MANY_REFERENCE, GET_ONE, UPDATE,
  UPDATE_MANY
} from "react-admin";
import log from '../../utils/log';
import LocalStorage from '../../utils/services/storage';

export const GET_CUSTOMER_SUBSCRIPTION = 'GET_CUSTOMER_SUBSCRIPTION'
export const GET_SUBSCRIPTION_DETAIL = 'GET_SUBSCRIPTION_DETAIL'
export const GET_PLAN_INFOS = 'GET_PLAN_INFOS'
export const INACTIVE_MANY = 'INACTIVE_MANY'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const APPROVE_PAYMENT = "APPROVE_PAYMENT"
export const GET_SUPPORT_SERVICE_FEATURES = "GET_SUPPORT_SERVICE_FEATURES"
export const COMPLETE_PAYMENT = "COMPLETE_PAYMENT"
export const GET_HIGHER_PLAN = "GET_HIGHER_PLAN"

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {

    // if (resource === 'orders') {
    //   resource = 'subscription';
    // }

    let url = "";
    const options = {};
    const customerId = LocalStorage.instance.getCustomerId();
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const payload = {
          page: page - 1,
          limit: perPage,
          ...params.filter
        }

        if (customerId && customerId.length > 0) { 
          url = `${apiUrl}/${resource}/${customerId}/search`;
        } else {
          url = `${apiUrl}/${resource}/search`;
        }

        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      }
      case GET_ONE:
        const payload = {
          id: params.id
        }
        url = `${apiUrl}/${resource}/${params.id}/detail`;
        options.method = "GET";
        //options.body = JSON.stringify(payload);
        break;
      case GET_SUBSCRIPTION_DETAIL:
        // const payload = {
        //   id: params.id
        // }
        url = `${apiUrl}/${resource}/` + params.id +  `/detail`;
        options.method = "GET";
        //options.body = JSON.stringify(payload);
        break;
      case GET_MANY: {
        // const query = {
        //   filter: JSON.stringify({ id: params.ids })
        // };
        // let idStr = "";
        // const queryString = params.ids.map(id => idStr + `id=${id}`);
        // url = `${apiUrl}/${resource}?${idStr}}`;
        url = `${apiUrl}/${resource}/details`;
        options.body = JSON.stringify(params.ids);
        options.method = "POST";
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const payload = {
          page: page - 1,
          limit: perPage,
          [params.target]: params.id
        }
        url = `${apiUrl}/${resource}/search`;
        options.method = "POST";
        options.body = JSON.stringify(payload);
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/update`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/register`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      case GET_CUSTOMER_SUBSCRIPTION:
        url = `${apiUrl}/${resource}/${params.customerId}/list`;
        options.method = "GET";
        break;
      case GET_PLAN_INFOS:
        url = `${apiUrl}/payment_plan/details`;
        options.method = "POST";
        options.body = JSON.stringify(params.ids);
        break;
      case CANCEL_SUBSCRIPTION:
        url = `${apiUrl}/${resource}/${params.id}/cancel`;
        options.method = "POST";
        break;
      case CANCEL_ORDER:
        url = `${apiUrl}/${resource}/${customerId}/cancel`;
        options.method = "POST";
        options.body = JSON.stringify({
          orderId: params.orderId,
          customerId,
          notes: "Cancel by user"
        })
        break;
      case APPROVE_PAYMENT:
        url = `${apiUrl}/approve_payment`;
        options.method = "POST";
        options.body = JSON.stringify(params)
        break;

      case COMPLETE_PAYMENT:
        url = `${apiUrl}/stripe/${LocalStorage.instance.getCustomerId()}/pay-for-order/${params.orderId}`;
        options.method = "POST";
        options.body = JSON.stringify({})
        break;

      case GET_SUPPORT_SERVICE_FEATURES:
        url = `${apiUrl}/${customerId}/check/support_features`;
        options.method = "GET";
        break;

      case GET_HIGHER_PLAN:
        url = `${apiUrl}/payment_plan/higher_plan?tierPlanId=` + params.tierPlanId;
        options.method = "GET";
        break;

      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json, status } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!json.hasOwnProperty("totalElements")) {
          throw new Error(
            "The numberOfElements property must be must be present in the Json response"
          );
        }
        return {
          data: json.contents,
          total: parseInt(json.totalElements, 10)
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };

      case COMPLETE_PAYMENT:
        return { data: { stripeSessionId: response.body }}

      default:
        return { data: json };
    }
  };

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === INACTIVE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/deactivate`, {
            method: "POST",
            body: JSON.stringify({ id: id })
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);

    return httpClient(url, options).then(response => {
      log.info('Debugging billing data-provider THEN ')
      return convertHTTPResponse(response, type, resource, params)
    }).catch(e => {
      log.info('typeof e ', typeof e)
      let errorJson = e.body;
      if (errorJson && errorJson.messageKey)
        throw new Error(errorJson.messageKey);
      else
        throw new Error("error.default.internal.server.error");
    });
  };
};