import { stringify } from 'query-string';
import {
	fetchUtils,
	GET_LIST,
	GET_ONE,
	GET_MANY,
	GET_MANY_REFERENCE,
	CREATE,
	UPDATE,
	UPDATE_MANY,
	DELETE,
	DELETE_MANY,
	showNotification
} from 'react-admin';
import LocalStorage from '../utils/services/storage';
import axios, { AxiosRequestConfig, post, put } from "axios";
import constants from "../constants";
export const GET_PANEL_DETAIL = 'GET_PANEL_DETAIL'

export const SEARCH_VARIABLE_QUERY = 'SEARCH_VARIABLE_QUERY'

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
	const GRAFANA_BASE_URL = `${constants.API_URL.replace("/api", "")}`

	/**
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} { url, options } The HTTP request parameters
	 */
	const convertDataRequestToHTTP = (type, resource, params) => {
		let customerId = LocalStorage.instance.getCustomerId()
		let url = '';
		const options = {};
		//// log.debug('params', params);
		let token = LocalStorage.instance.getToken();
		options.headers = new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Sec-Fetch-Site': 'none',
			'Access-Control-Expose-Headers': 'Content-Range',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS',
			'Authorization': 'Bearer ' + token
		});
		options.mode = 'no-cors';
		switch (type) {

			case GET_PANEL_DETAIL:
				// alert(GET_DASHBOARD_DETAIL)
				url = `${GRAFANA_BASE_URL}/api/datasources/proxy/${params.datasourceId}/api/dashboards/uid/${params.dashboardUID}/panels/${params.panelUID}?token=${token}`;
				break;

			default:
				throw new Error(`Unsupported fetch action type ${type}`);
		}
		return { url, options };
	};

	/**
	 * @param {Object} response HTTP response from fetch()
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} Data response
	 */
	const convertHTTPResponse = (response, type, resource, params) => {
		const { headers, json } = response;

		console.log("grafana PANEL DETAIL response", {json})
		switch (type) {
			default:
				return { data: json };
		}
	};

	/**
	 * @param {string} type Request type, e.g GET_LIST
	 * @param {string} resource Resource name, e.g. "posts"
	 * @param params params.datasoureId, params.dashboardUID, params.panelUID
	 * @returns {Promise} the Promise for a data response
	 */
	const requestHandler = (type, resource, params) => {

		// simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
		if (type === UPDATE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'PUT',
						body: JSON.stringify(params.data),
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}
		// simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
		if (type === DELETE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'DELETE',
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}

		const { url, options } = convertDataRequestToHTTP(
			type,
			resource,
			params
		);

		return httpClient(url, options).then(response =>
			convertHTTPResponse(response, type, resource, params)
		);
	};

	return requestHandler;
};