// epic
import { ofType } from "redux-observable";
import { concat, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import log from "../../utils/log";
import {
    getAllSubscriptionApi, getConnectorsByTypeApi,
    getServiceTemplateDetailApi,
    getTokenPackBySubscriptionIdApi, postCreateConnectorApi
} from "../rest-api/serviceLaunchApi";
import { ConnectorTypeKey, ServiceLaunch } from "./styled";

const prefix = "serviceLaunch";
const GET_TEMPLATE_DETAIL = `${prefix}/GET_TEMPLATE_DETAIL`
const FETCH_SUBSCRIPTION = `${prefix}/FETCH_SUBSCRIPTION`
const FETCH_TOKENPACK = `${prefix}/FETCH_TOKENPACK`
const FETCH_CONNECTORS_BY_TYPE = `${prefix}/FETCH_CONNECTORS_BY_TYPE`

// all post actions
const POST_CREATE_SERVICE = `${prefix}/POST_CREATE_SERVICE`
const POST_CREATE_SERVICE_REJECTED = `${prefix}/POST_CREATE_SERVICE_REJECTED`
const CLEAN_ERROR = `${prefix}/CLEAN_ERROR`

const SET_LOADING = `${prefix}/SET_LOADING`
const SET_SUBSCRIPTION = `${prefix}/SET_SUBSCRIPTION`
const SET_TOKENPACK = `${prefix}/SET_TOKENPACK`

// Reducers here
const FILL_SUBSCRIPTION = `${prefix}/FILL_SUBSCRIPTION`
const FILL_TOKENPACK = `${prefix}/FILL_TOKENPACK`
const FILL_SERVICE_TEMPLATE = `${prefix}/FILL_SERVICE_TEMPLATE`
const FILL_CONNECTORS = `${prefix}/FILL_CONNECTORS`
const FILL_SERVICE_INSTANCE = `${prefix}/FILL_SERVICE_INSTANCE`
const UNSHIFT_CONNECTOR = `${prefix}/UNSHIFT_CONNECTOR`

export const CLEAN_CONNECTORS = `${prefix}/CLEAN_CONNECTORS`

const SERVICE_LAUNCH_CANCELATION = `${prefix}/SERVICE_LAUNCH_CANCELATION`

// action creators
export const setLoading = ({loading, loadingElem}: ServiceLaunch) => ({ type: SET_LOADING, payload: {loading, loadingElem}})
export const setSubscription = (currentSubscriptionId: string) => ({ type: SET_SUBSCRIPTION, payload: {currentSubscriptionId} })
export const setTokenPack = (currentTokenPackId: string) => ({ type: SET_TOKENPACK, payload: {currentTokenPackId} })

// All get action
export const getTemplateDetail = (templateId) => ({ type: GET_TEMPLATE_DETAIL, payload: {templateId} })

// All fetch action
export const fetchSubscription = () => ({ type: FETCH_SUBSCRIPTION })
export const fetchTokenPack = (subscriptionId) => ({ type: FETCH_TOKENPACK, payload: {subscriptionId}})
export const fetchConnectorsByType = (type: ConnectorTypeKey) => ({ type: FETCH_CONNECTORS_BY_TYPE, payload: type })

export const postCreateConnector = (values: any) => ({ type: POST_CREATE_SERVICE, payload: values })

// All fill action
export const fillSubscriptions = ({subscriptions}: ServiceLaunch) => ({ type: FILL_SUBSCRIPTION, payload: {subscriptions} })
export const fillTokenPacks = ({tokenPacks}: ServiceLaunch) => ({ type: FILL_TOKENPACK, payload: {tokenPacks} })
export const fillTemplate = ({serviceTemplate}: ServiceLaunch) => ({ type: FILL_SERVICE_TEMPLATE, payload: {serviceTemplate} })
export const fillConnectors = (type: ConnectorTypeKey, list: Array<any>) => ({ type: FILL_CONNECTORS, payload: {list, type} })
export const fillServiceInstance = (serviceInstance: Object) => ({ type: FILL_SERVICE_INSTANCE, payload: {serviceInstance} })

export const serviceLaunchCancel = () => ({ type: SERVICE_LAUNCH_CANCELATION})
export const unshiftConnector = (connectorType: string, connector: { name: string, id: string }) => ({ type: UNSHIFT_CONNECTOR, payload: {connectorType, connector} })
export const cleanError = () => ({ type: CLEAN_ERROR })

export interface ReduxAction {
    payload?: any,
    type: string
}

export const getTemplateDetailEpic = (action$, $state, { defaultHeader }) => action$.pipe(
    ofType(GET_TEMPLATE_DETAIL),
    switchMap((action:ReduxAction) =>
        {
            return concat(
                // of(setLoading({ loading: true, loadingElem: "connector_" })),
                getServiceTemplateDetailApi(action.payload.templateId)
                    .pipe(
                        map(({response}) => {
                            return fillTemplate({ serviceTemplate: response })
                        }),
                        takeUntil(action$.pipe(
                            ofType(SERVICE_LAUNCH_CANCELATION)
                        ))
                    ),
            )
        }
    )
);

export const fetchSubscriptionEpic = (action$, $state) => action$.pipe(
    ofType(FETCH_SUBSCRIPTION),
    switchMap((action:ReduxAction) =>
        {
            return concat(
                of(setLoading({ loading: true, loadingElem: "subscription" })),
                getAllSubscriptionApi()
                    .pipe(
                        map(({response}) => {
                            return fillSubscriptions({ subscriptions: response.contents })
                        }),
                        takeUntil(action$.pipe(
                            ofType(SERVICE_LAUNCH_CANCELATION)
                        ))
                    ),
                of(setLoading({ loading: false })),

            )
        }
    )
);

export const fetchTokenPackEpic = (action$, $state, { defaultHeader }) => action$.pipe(
    ofType(FETCH_TOKENPACK),
    switchMap((action:ReduxAction) =>
    {
        return concat(
            of(setLoading({ loading: true, loadingElem: "tokenPack" })),
            getTokenPackBySubscriptionIdApi(action.payload.subscriptionId)
                .pipe(
                    map(({response}) => {
                        return fillTokenPacks({ tokenPacks: response })
                    }),
                    takeUntil(action$.pipe(
                        ofType(SERVICE_LAUNCH_CANCELATION)
                    ))
                ),
            of(setLoading({ loading: false })),
        )
    })
);

export const fetchConnectorsByTypeEpics = (action$, $state) => action$.pipe(
    ofType(FETCH_CONNECTORS_BY_TYPE),
    mergeMap((action:ReduxAction) =>
    {
        return concat(
            of(setLoading({ loading: true, loadingElem: ( action.payload ) })),
            getConnectorsByTypeApi(action.payload)
                .pipe(
                    map(({response}) => {
                        return fillConnectors(action.payload, response)
                    }),
                    takeUntil(action$.pipe(
                        ofType(SERVICE_LAUNCH_CANCELATION)
                    ))
                ),
            of(setLoading({ loading: false })),
        )
    })
);

export const postCreateServiceEpics = (action$, $state) => action$.pipe(
    ofType(POST_CREATE_SERVICE),
    mergeMap((action:ReduxAction) =>
    {
        return concat(
            of(setLoading({ loading: true, loadingElem: "root" })),
            postCreateConnectorApi(action.payload)
                .pipe(
                    map(({response}) => {
                        return fillServiceInstance(response)
                    }),
                    takeUntil(action$.pipe(
                        ofType(SERVICE_LAUNCH_CANCELATION)
                    )),
                    catchError(error => of({
                        type: POST_CREATE_SERVICE_REJECTED,
                        payload: error.xhr.response,
                        error: true
                    }))
                ),
            of(setLoading({ loading: false })),
        )
    })
);

let defaultState = {
    loading: false,
    subscriptions: [],
    tokenPacks: [],
    currentSubscription: {},
    currentTokenPack: {},
    loadingElem: "subscription",
    serviceTemplate: {},
    connectors: {},
    serviceInstance: {},
    error: ""
} as ServiceLaunch

const rootEpic = (state = defaultState, action) => {
    let newConnectors
    switch (action.type) {
        case FILL_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: action.payload.subscriptions
            }

        case FILL_CONNECTORS:
            newConnectors = state.connectors
            newConnectors[action.payload.type] = action.payload.list
            return {
                ...state,
                connectors: newConnectors
            }

        case CLEAN_CONNECTORS:
            return defaultState

        case UNSHIFT_CONNECTOR:
            const { connectorType, connector } = action.payload
            let connectors = state.connectors?.[connectorType] || [];
            let canAdd = connectors.filter(conn => conn.id === connector.id).length === 0 // Connector is not existed => canAdd
            if (!canAdd) {
                log.warn(UNSHIFT_CONNECTOR + " connector existed", connector)
                return state
            }

            newConnectors = state.connectors
            newConnectors[connectorType].unshift(connector)

            return {
                ...state,
                connectors: newConnectors
            }

        case POST_CREATE_SERVICE_REJECTED:
            return {
                ...state,
                error: action.payload.messageKey
            }

        case CLEAN_ERROR:
            return {
                ...state,
                error: ""
            }

        default:
            return typeof action.payload === 'object' ? {
                ...state,
                ...action.payload
            } : state
    }
};

export default rootEpic