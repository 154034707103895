import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {MenuItemLink, translate, usePermissions} from 'react-admin';
import {connect, useDispatch} from 'react-redux';
import {withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import SubMenu from '../SubMenu';
import LocalStorage, {IDM_DASHBOARD, MY_ACC_DASHBOARD} from '../../utils/services/storage';
import withStyles from '@material-ui/core/styles/withStyles';
import {updateDashboardView as updateDashboardViewAction, updateScope as updateScopeAction} from '../menuReducers'
import {getTheme} from '../Layout';
import MyAccountIcon from '../../Icons/MyAccount';
import MyProfileIcon from '../../Icons/MyProfile';
import ChangePasswordIcon from '../../Icons/ChangePassword';
import OrdersIcon from '../../Icons/Orders';
import OrderMgmtIcon from '../../Icons/MenuOrdersMgmt'
import SubscriptionMgmtIcon from '../../Icons/MenuSubscription'
import PermissionAssetIcon from '../../Icons/PermissionAsset24Px';
import useTranslate from "ra-core/esm/i18n/useTranslate";
import {makeStyles} from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles((theme) => ( {
    active: {
        backgroundColor: '#533475',
    },
    default: {
        color: 'white'
    },
    root: {
        color: 'white',
        padding: '12px 16px',
        fontSize: '1rem'
    },
    contextContent: {
        position: 'fixed',
        bottom: 15,
        color: 'white',
        fontSize: '1.2rem',
        // padding: 16,
        maxWidth: 250,
        textAlign: 'center',
        width: '100%'
    }
}));

function CustomerMyAccountMenu(props) {

    // constructor(props) {
    //     super(props)
    // }
    // const theme = useTheme();
    const classes = useStyles();
    const [customerId, setCustomerId] = useState(LocalStorage.instance.getCustomerId())
    const [menuMyAccounts, setMenuMyAccounts] = useState(true)
    const [orderManagement, setOrderManagement] = useState(true)
    const [billingManagement, setBillingManagement] = useState(true)
    const translate = useTranslate();
    const permissions = usePermissions();
    // state = {
    //     customerId: null,
    //     menuMyAccounts: true,
    //     orderManagement: true,
    //     billingManagement: true
    // };

    // static propTypes = {
    //     onMenuClick: PropTypes.func,
    //     logout: PropTypes.object,
    // };

    const handleToggle = menu => {
        switch (menu) {
            case "orderManagement":
                setOrderManagement(pre => !pre)
                break;
            case "billingManagement":
                setBillingManagement(pre => !pre)
                break;
            case "menuMyAccounts":
                setMenuMyAccounts(pre => !pre)
                break;
        }
        // this.setState(state => ({[menu]: !state[menu]}));
    };

    // useEffect(() => {
    //     setCustomerId(LocalStorage.instance.getCustomerId());
    // })
    // async UNSAFE_componentWillMount() {
    //
    //     let customerId = LocalStorage.instance.getCustomerId();
    //
    //     this.setState({
    //         customerId: customerId
    //     });
    //
    //     // Load localstorage and set scope
    //     //let scope = LocalStorage.instance.getCurrentScope()
    //     //this.props.updateScope(scope)
    // }


    const { originalScope, currentScope, onMenuClick, open, logout, updateScope} = props;

    let scope = currentScope;

    // log.debug('Render customer my account menu with currentScope - permissions', permissions)

    return (
        <div id="left-menu">

            <SubMenu
                handleToggle={() => handleToggle('orderManagement')}
                isOpen={orderManagement}
                sidebarIsOpen={open}
                name="resources.orders.parentMenu"
                icon={<OrderMgmtIcon/>}
                // classes={classes}
                hasDivider={false}
            >
                <MenuItemLink
                    to={`/my-account/orders`}
                    primaryText={translate(`resources.orders.name`, {smart_count: 2})}
                    leftIcon={<OrdersIcon/>}
                    onClick={onMenuClick}
                    classes={classes}
                />
            </SubMenu>

            {/* <SubMenu
                handleToggle={() => handleToggle('billingManagement')}
                isOpen={billingManagement}
                sidebarIsOpen={open}
                name={translate("resources.billing.management")}
                icon={<SubscriptionMgmtIcon/>}
                // classes={classes}
            >
                <MenuItemLink
                    to={`/my-account/subscription`}
                    primaryText={translate(`resources.billing.subscription`, {smart_count: 2})}
                    leftIcon={<SubscriptionMgmtIcon/>}
                    onClick={onMenuClick}
                    // activeStyle={theme.menu ? theme.menu.active : {}}
                    // style={theme.menu ? theme.menu.default : {}}
                    classes={classes}
                />
            </SubMenu> */}

            <SubMenu
                handleToggle={() => handleToggle('menuMyAccounts')}
                isOpen={menuMyAccounts}
                sidebarIsOpen={open}
                name="resources.myAccounts.name"
                icon={<MyAccountIcon/>}
                // style={classes.default}
                // hasDivider={false}
                // classes={classes}
            >
                <MenuItemLink
                    to={`/my-account/profile`}
                    primaryText={translate(`resources.myAccounts.tabs.profileTab`)}
                    leftIcon={<MyProfileIcon/>}
                    onClick={onMenuClick}
                    // activeStyle={theme.menu ? theme.menu.active : {}}
                    // style={theme.menu ? theme.menu.default : {}}
                    classes={classes}
                />
                <MenuItemLink
                    to={`/my-account/change-password`}
                    primaryText={translate(`resources.myAccounts.tabs.changePasswordTab`)}
                    leftIcon={<ChangePasswordIcon/>}
                    onClick={onMenuClick}
                    // activeStyle={theme.menu ? theme.menu.active : {}}
                    // style={theme.menu ? theme.menu.default : {}}
                    classes={classes}
                />
                <MenuItemLink
                    to={`/my-account/security`}
                    primaryText={translate(`resources.myAccounts.tabs.securityTab`)}
                    leftIcon={<PermissionAssetIcon/>}
                    onClick={onMenuClick}
                    // activeStyle={theme.menu ? theme.menu.active : {}}
                    // style={theme.menu ? theme.menu.default : {}}
                    classes={classes}
                />

            </SubMenu>
        </div>
    );

}

CustomerMyAccountMenu.propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
};

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: getTheme(state.theme),
    scope: state.menu.scope
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {
            updateScope: updateScopeAction,
            updateDashboardView: updateDashboardViewAction
        }
    )
);

export default enhance(CustomerMyAccountMenu);
