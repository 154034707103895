import Cookies from "universal-cookie";

const cookies = new Cookies();

class AppCookies {
  static instance: AppCookies = new AppCookies();
  setItem(key: string, item?: any, expireInSec?: number) {
    if (item == undefined) {
      cookies.remove(key);
    } else if (expireInSec) {
      cookies.set(key, item, {path: '/', expires: new Date(Date.now() + (expireInSec*1000)) });
    } else {
      cookies.set(key, item);
    }
  }

  getItem(key: string) {
    return cookies.get(key);
  }

  removeItem(key: string) {
    cookies.remove(key, { path: '/' });
  }
  
}

const appCookies = new AppCookies();

export default appCookies;
