import React from "react";

const SvgUpArrow = props => (
  <svg viewBox="0 0 34.461 19.436" {...props}>
    <path
      d="M0 17.3l2.023 2.136L17.161 4.272l15.164 15.164 2.136-2.136L17.161 0z"
      fill="#5f2685"
    />
  </svg>
);

export default SvgUpArrow;
