export default (theme) => ({
    titleBackground: {
        top: "4px",
        width: '80vw',
        height: 'calc(80vw / 10)',
        // opacity: 0.44,
        // backgroundImage: 'linear-gradient(to left, rgba(203, 42, 42, 0), rgba(62, 21, 21, 0.58) 33%, #11021a 50%, rgba(62, 21, 21, 0.67) 68%, rgba(186, 45, 45, 0))'
        backgroundImage: 'url("/assets/images/home/vos_background.png")',
        backgroundSize: '100% auto',
        position: 'relative',
        left: '-50%',
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            height: 'calc(100vw / 9)',
        }
    },
    titleFirst: {
        fontSize: '2rem',
        fontWeight: 'bold',
        textShadow: '0 3px 30px #5f2685',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.2,
        letterSpacing: 'normal',
        color: '#f8f5fc',
        marginBottom: 30
    },
    titleSecond: {
        top: "40px",
        fontSize: '1.37rem',
        textShadow: '0 3px 30px #5f2685',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.22,
        fontWeight: 'bold',
        letterSpacing: 'normal',
        color: '#f8f5fc',
        paddingRight: 15,
        verticalAlign: 'middle',
        display: 'table-cell',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.6rem',
        }
    },
    vosHighlight: {
        top: "40px",
        fontSize: '2.29rem',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.21,
        letterSpacing: 'normal',
        color: '#1ebec3',
        verticalAlign: 'middle',
        display: 'table-cell',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
        }
    },
    companyDesc: {
        // height: 350,
        // height: '60vh',
        color: 'white',
        textAlign: 'center',
        padding: '60px 60px 30px 60px',
        zIndex: 3,
        position: 'relative',
        backgroundImage: 'url("/assets/images/home_background.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: '50px 60px 10px 60px'
        }
    },
    descText: {
        fontSize: '1.1rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.74,
        letterSpacing: 'normal',
        color: '#dac0eb',
        width: '32.65vw',
        textAlign: 'left',
        margin: '0 auto'
    },
    productContent: {
        background: '#f2f4f4',
        minHeight: 340,
        padding: '60px 0',
        display: 'inline-flex',
        width: '100%',
        zIndex: 3,
        position: 'relative'
    },
    joinUsNow: {
        width: 400,
        height: 60,
        opacity: 0.24,
        borderRadius: 200,
        border: 'solid 1px #fff',
        backgroundColor: '#f7e0b9',
        margin: '0 auto',
        position: 'relative'
    },
    joinUsNowButton: {
        width: 260,
        height: 75,
        opacity: 1,
        boxShadow: '0 0px 80px 0 #ffffff',
        border: 'solid 2px #a277bf',
        backgroundColor: '#5f2685',
        position: 'absolute',
        top: 'calc((62px - 79px) / 2)',
        left: 'calc(50% - 138px)',
        borderRadius: 50,

        // Styling text
        fontSize: '1rem',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 1.68,
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ffffff',
        cursor: 'pointer'
    },
    // Product css
    product: {
        maxWidth: '33.3%',
        flex: 1,
        margin: '0 30px',
    },
    productImage: {
        height: 200,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    productTitle: {
        fontWeight: 600,
        fontSize: '1.2rem',
        marginTop: 20,
        marginBottom: 10
    },
    productDescription: {
        fontWeight: 200,
        marginBottom: 20
    },
    productLauchLink: {
        textDecoration: 'none',
        fontSize: '1.2rem'
    },

    // Footer css
    footer: {
        width: '80%',
        margin: '0 auto'
    },
    socialFooter: {
        color: 'white',
        width: 26,
        height: 26,
        margin: '0 1.55rem'
    },

    // Content under service templates
    svTemplateDetail: {
        width: '80%',
        // backgroundColor: 'pink',
        marginTop: 20,
        zIndex: 3,
        minHeight: 300,
        margin: "0 auto",
        position: "relative"
    },
    img1: {
        position: "absolute",
        zIndex: 2,
        opacity: .2,
        top: -400,
        width: 800,
        left: "calc(50% - 400px)"
    },
    arrowIcon: {
        width: 25.88,
        margin: '0 auto'
    }
})