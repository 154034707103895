import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import VkeyTextField from '../components/VkeyTextField';
import { compose } from 'recompose';
import {
    translate
} from 'react-admin';

const coloredStyles = {
    pending: { color: '#c77922' },
    cancel: { color: '#606569' },
    active: { color: '#3cad07' },
    expired: { color: '#fc4c49' },
    pastDue: { color: '#aea3af' },
    unPaid: { color: '#aea3af' }
};

class OrderStatusField extends Component {

    state = {

    }

    constructor(props) {
        super(props)

    }

    async UNSAFE_componentWillMount() {


    }

    componentWillUnmount() {
    }

    formatStatus = (status) => {

        const { translate } = this.props;

        let st = status;
        //// log.debug('formatStatus----', st);

        if (st == "PENDING") {
            return translate('resources.orders.fields.status.values.pending');
        } else if (st == "CANCEL") {
            return translate('resources.orders.fields.status.values.cancel');
        } else if (st == "ACTIVE") {
            return translate('resources.orders.fields.status.values.active');
        } else if (st == "EXPIRED") {
            return translate('resources.orders.fields.status.values.expired');
        } else if (st == "PAST_DUE") {
            //return translate('resources.orders.fields.status.values.pending');
            return "PAST_DUE";
        } else if (st == "UNPAID") {
            //return translate('resources.orders.fields.status.values.pending');
            return "UNPAID";
        }
        return st;
    }

    render() {

        const { source, classes, className, record, translate } = this.props;

        //// log.debug('record----', record);

        return (
            <VkeyTextField
                {...this.props}
                className={classnames(
                    {
                        [classes.pending]: record[source] === 'PENDING' || record[source] === translate('resources.orders.fields.status.values.pending'),
                        [classes.cancel]: record[source] === 'CANCEL' || record[source] === translate('resources.orders.fields.status.values.cancel'),
                        [classes.active]: record[source] === 'ACTIVE' || record[source] === translate('resources.orders.fields.status.values.active'),
                        [classes.expired]: record[source] === 'EXPIRED' || record[source] === translate('resources.orders.fields.status.values.expired'),
                    })}
                format={st => this.formatStatus(st)}
            />
        );
    }
}

const enhance = compose(
    withStyles(coloredStyles),
    translate
);

export default enhance(OrderStatusField);