import './App.css';

import React, {Component} from 'react';
import {Admin, Resource} from 'react-admin';

import authProvider from './authProvider';
import englishMessages from './i18n/en';
import {Layout, Login, Menu} from './layout';
import sagas from './sagas';
import themeReducer from './themeReducer';
import loadThemeReducer from './wla_v2/reducers/themeDetailReducer';
import myAccountReducer from './external/myaccount/reducers/myAccountReducer';
import serviceLaunchReducer from './reducers/serviceLaunchReducer';
import wizardReducers from './internal/wizard_v2/wizardReducers';
import multipleUserSelectionReducer from './external/home/component/MultipleUserSelection/multipleUserSelectionReducer'

import dataProvider from './dataProvider';
import customRoutes from './routes';
import {lightTheme} from './layout/themes';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab, faFacebookF, faLinkedinIn, faTwitter} from '@fortawesome/free-brands-svg-icons'
import SecurityChecker from './utils/security';
import LocalStorage from './utils/services/storage'
import menuReducers from './layout/menuReducers'
import homeReducers from './external/home/homeReducers'
import reportReducers from './internal/report/ReportReducers'
import VkeySAMLSetting from './internal/vkey-saml-settings'
import {createBrowserHistory, createHashHistory as createHistory} from 'history';
import Loadable from 'react-loadable';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import OrderList from "./subscription/OrderList";
import log from "./utils/log";
import ListAudit from "./internal/audit/ListAudit";

const DynamicImport = (LoaderComponent) => Loadable({
    loader: LoaderComponent,
    loading: () => null
});

library.add(fab, faFacebookF, faLinkedinIn, faTwitter)

window.sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

/*if (!constant.DEBUG_LOG) {
    window.// log.debug = () => {}
}*/

/*const i18nProvider = locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
};*/
const i18nProvider = polyglotI18nProvider(locale => {
    /*if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }*/

    // Always fallback on english
    return englishMessages;
}, 'en');

/*const history = createHistory({
    hashType: "noslash"
});*/

const history = createBrowserHistory()

window.globalHistory = history;

class App extends Component {
    state = { dataProvider: null };

    async UNSAFE_componentWillMount() {
        this.setState({ dataProvider });
    }

    componentWillUnmount() {

    }

    getNonLoggedinResourceClass() {
        // if has token - return null, thene return div - for redirect to login page
        const loggedToken = LocalStorage.instance.getToken();

        if (loggedToken) {
            return null;
        } else {
            return 'div';
        }

    }

    render() {
        const { dataProvider } = this.state;

        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }

        return (
            <Admin
                title=""
                theme={lightTheme}
                dataProvider={dataProvider}
                customReducers={
                    {
                        theme: themeReducer,
                        serviceLaunchData: serviceLaunchReducer,
                        wlaThemeDetail: loadThemeReducer,
                        menu: menuReducers,
                        myAccount: myAccountReducer,
                        home: homeReducers,
                        // buyService: buyServiceReducers,
                        wizard: wizardReducers,
                        multipleUsersSelect: multipleUserSelectionReducer,
                        report: reportReducers
                    }
                }
                customSagas={sagas}
                customRoutes={customRoutes}
                authProvider={authProvider}
                // dashboard={Dashboard}
                loginPage={Login}
                appLayout={Layout}
                menu={Menu}
                locale="en"
                history={history}
                i18nProvider={i18nProvider}
            >
                {/* <Resource name="customers" {...visitors} />
                <Resource
                    name="commands"
                    {...orders}
                    options={{ label: 'Orders' }}
                />
                <Resource name="reviews" {...reviews} /> */}
                {permissions => {
                    log.warn("App.js debug permissions", permissions)
                    return [
                        <Resource name="token-pack"
                                  list={SecurityChecker(permissions, 'token-pack', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/tokenPacks/list')) : this.getNonLoggedinResourceClass()}
                                  // create={SecurityChecker(permissions, 'token-pack', ['create','configure'], 'OR') ? DynamicImport(() => import('./internal/tokenPacks/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'token-pack', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/tokenPacks/edit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="token-pack-v2"
                                  list={SecurityChecker(permissions, 'token-pack', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/tokenPacks/list')) : this.getNonLoggedinResourceClass()}
                            // create={SecurityChecker(permissions, 'token-pack', ['create','configure'], 'OR') ? DynamicImport(() => import('./internal/tokenPacks/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'token-pack', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/tokenPacks/edit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="otp-config"
                                  list={SecurityChecker(permissions, 'token-pack', ['view', 'modify', 'configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/otp-config/list')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'token-pack', ['view', 'modify', 'configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/otp-config/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'token-pack', ['view', 'modify', 'configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/otp-config/edit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="account"
                                  list={SecurityChecker(permissions, 'account', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./account/list')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'account', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./account/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'account', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./account/edit')) : this.getNonLoggedinResourceClass()}
                                  show={SecurityChecker(permissions, 'account', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./account/show')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="report"
                                  list={SecurityChecker(permissions, 'account', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/report/ReportDashboard')) : this.getNonLoggedinResourceClass()}

                        />,

                        <Resource name="ldap"
                                  list={SecurityChecker(permissions, 'directories', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/ldaps/LdapsList')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'directories', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/ldaps/LdapsEdit')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'directories', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/ldaps/LdapsEdit'))  : this.getNonLoggedinResourceClass()}
                        />,
                        <Resource name="ldap_2fa"
                                  list={SecurityChecker(permissions, 'directories', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/ldap2fa/Ldap2FAList')) : this.getNonLoggedinResourceClass()}
                                  /* create={SecurityChecker(permissions, 'directories', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/ldap2fa/Ldap2FAEdit')) : this.getNonLoggedinResourceClass()} */
                                  edit={SecurityChecker(permissions, 'directories', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/ldap2fa/Ldap2FAEdit'))  : this.getNonLoggedinResourceClass()}
                        />,
                        <Resource name={"users/keycloak"}
                                  list={SecurityChecker(permissions, 'directories', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/users/vkeyldap/list')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'directories', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/users/vkeyldap/edit')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'directories', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/users/vkeyldap/create')) : this.getNonLoggedinResourceClass()}

                        />,
                        <Resource name="radius"
                                  list={SecurityChecker(permissions, 'radius', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/radius/RadiusList')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'radius', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/radius/RadiusEdit')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'radius', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/radius/RadiusEdit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="saml"
                                  list={SecurityChecker(permissions, 'saml', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/saml/list')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'saml', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/saml/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'saml', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/saml/edit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="saml2"
                                  list={SecurityChecker(permissions, 'saml', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/saml2/list')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'saml', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/saml2/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'saml', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/saml2/edit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="oidc"
                                  list={SecurityChecker(permissions, 'saml', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/oidc/list')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'saml', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/oidc/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'saml', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/oidc/edit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="soft-token"
                                  list={SecurityChecker(permissions, 'soft-token', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./softToken/softTokenList')) : this.getNonLoggedinResourceClass()}
                                  show={SecurityChecker(permissions, 'soft-token', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./softToken/SoftTokenShow')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="customer"
                                  list={SecurityChecker(permissions, 'customer', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./customer/CustomerList')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'customer', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./customer/CustomerEdit')) : this.getNonLoggedinResourceClass()}
                                  show={SecurityChecker(permissions, 'customer', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./customer/CustomerShow')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="asset"
                                  list={SecurityChecker(permissions, 'asset', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./asset/AssetList')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'asset', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./asset/AssetCreate')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'asset', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./asset/AssetEdit')) : this.getNonLoggedinResourceClass()}
                                  show={SecurityChecker(permissions, 'asset', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./asset/AssetShow')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="account_role"
                                  list={SecurityChecker(permissions, 'account', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./account/list')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'account', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./account/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'account', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./account/edit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="role"
                                  create={SecurityChecker(permissions, 'role', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./role/RoleCreate')) : this.getNonLoggedinResourceClass()}
                                  list={SecurityChecker(permissions, 'role', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./role/RoleList')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'role', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./role/RoleEdit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="service-template"
                                  list={SecurityChecker(permissions, 'service_template', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-template/ServiceTemplateList')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'service_template', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-template/ServiceTemplateCreate')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'service_template', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-template/ServiceTemplateEdit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="service-launch"
                                  list={SecurityChecker(permissions, 'service_instance', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-launch/ServiceLaunchList')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'service_instance', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-launch/ServiceInstanceCreate')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'service_instance', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-launch/ServiceLaunchEdit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="service-instance"
                                  list={SecurityChecker(permissions, 'service_instance', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-launch/ServiceLaunchList')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'service_instance', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-launch/ServiceInstanceCreate')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'service_instance', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./service-launch/ServiceLaunchEdit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="users"
                                  list={SecurityChecker(permissions, 'user-management', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/users/list.js')) : this.getNonLoggedinResourceClass()}

                        />,
                        <Resource name="user"
                                  list={SecurityChecker(permissions, 'user-management', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/users/list.js')) : this.getNonLoggedinResourceClass()}

                        />,
                        <Resource name="user-details"
                                  show={SecurityChecker(permissions, 'user-management', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/user-details/detail')) : this.getNonLoggedinResourceClass()}

                        />,
                        <Resource name="deny_service_users"
                                  show={SecurityChecker(permissions, 'user-management', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/users/list.js')) : this.getNonLoggedinResourceClass()}
                        />,
                        <Resource name="seamless_service_users"
                                  show={SecurityChecker(permissions, 'user-management', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/users/list.js')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="theme"
                                  list={SecurityChecker(permissions, 'theme', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./wla_v2/list')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'theme', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./wla_v2/create')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'theme', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./wla_v2/edit')) : this.getNonLoggedinResourceClass()}
                                  show={SecurityChecker(permissions, 'theme', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./wla_v2/show')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="subscription"
                                  list={SecurityChecker(permissions, 'subscription', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./subscription/SubscriptionList')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="white-list-ips"
                                  edit={SecurityChecker(permissions, 'token-pack', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/trusted-ip/edit')) : this.getNonLoggedinResourceClass()}
                                  list={SecurityChecker(permissions, 'token-pack', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/trusted-ip/list')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'token-pack', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/trusted-ip/create')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="vos-cloud-config"
                                  list={SecurityChecker(permissions, 'vos-cloud-configuration', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./vosCloudConfig/VosCloudList')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'vos-cloud-configuration', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./vosCloudConfig/VosCloudCreate')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'vos-cloud-configuration', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./vosCloudConfig/VosCloudEdit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="saml-settings" {...VkeySAMLSetting}
                                  list={SecurityChecker(permissions, 'saml-configuration', ['view','modify','configure'], 'OR') ? VkeySAMLSetting.list : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="https"
                                  list={SecurityChecker(permissions, 'saml', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/https/HttpsList')) : this.getNonLoggedinResourceClass()}
                                  create={SecurityChecker(permissions, 'saml', ['create','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/https/HttpsCreate')) : this.getNonLoggedinResourceClass()}
                                  edit={SecurityChecker(permissions, 'saml', ['modify','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/https/HttpsEdit')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="order"
                                  list={OrderList}
                        />,

                        <Resource name="activation-code"
                                  list={SecurityChecker(permissions, 'token-pack', ['view','configure'], 'OR') ?
                                      DynamicImport(() => import('./internal/activation-code/ListActivationCode')) : this.getNonLoggedinResourceClass()}
                        />,
                        <Resource name="otp"
                                  list={SecurityChecker(permissions, 'token-pack', ['view', 'configure', 'modify'], 'OR') ?
                                      DynamicImport(() => import('./internal/otp/ListOTP')) : this.getNonLoggedinResourceClass()}
                        />,

                        <Resource name="audit"
                                  list={ListAudit}
                        />,

                    ]
                }}

            </Admin>
        );
    }

}

export default App;
