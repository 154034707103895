import React, {useEffect, useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import LocalStorage from '../utils/services/storage';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import useTranslate from "ra-core/esm/i18n/useTranslate";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuList from "@material-ui/core/MenuList";
import ButtonMenuPopper from "../components/ButtonMenuPopper";
import MailIcon from '@material-ui/icons/Mail';
import {useTheme} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import clsx from "clsx";
import ListSubheader from "@material-ui/core/ListSubheader";
import {useRedirect} from 'react-admin'

const styleColorTransition = {
	background: "#5c2d78",
	transition: 'background .5s ease',
	boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',

}

const useStyles = makeStyles((theme) => ({
	body: {
		fontFamily: "Roboto !important",
		fontWeight: 400,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	root: {
		flexGrow: 1,
		position: 'fixed',
		width: '100%',
		top: 0,
		zIndex: 1000
	},
	homeHeader: {
		background: 'transparent',
		boxShadow: 'none',
		"&:hover": {
			background: "rgba(47,47,47,0.3)",
			transition: 'background .5s ease',
			boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
		},

	},
	header: {
		background: 'transparent',
		boxShadow: 'none',
	},
	flex: {
		flexGrow: 1,
		marginLeft: 10
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	hover: styleColorTransition,
	offsetTop: styleColorTransition,
	textWhite: {
		color: 'white',
		textTransform: 'unset'
	},
	homeLink: {
		[theme.breakpoints.down('sm')]: {
			flex: 1
		}
	},
	menuBtn: {
		color: 'white',
		fontSize: "16px",
		textTransform: "capitalize",
		"&:hover,&:focus,&:after": {
			color: 'rgb(255, 157, 0)'
		}

	},
	hiddenMenu: {
		marginRight: "10px"
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	listHeader: {
		color: "white"
	}
}));

function CommonMenu(props) {
	const [hover, setHover] = useState(false);
	const [offsetTop, setOffsetTop] = useState(false);
	const [solOpen, setSolOpen] = useState(false);
	const [guiOpen, setGuiOpen] = useState(false);
	const [faqOpen, setFaqOpen] = useState(false);
	const translate = useTranslate();
	const theme = useTheme();
	const classes = useStyles(theme);
	const redirectTo = useRedirect()
	window.addEventListener('scroll', e => handleScroll())

	useEffect(() => {
		if (guiOpen === true) {
			setSolOpen(false)
		}
	}, [guiOpen, setSolOpen])

	useEffect(() => {
		if (solOpen === true) {
			setGuiOpen(false)
		}
	}, [solOpen])

	const handleScroll = () => {
		setOffsetTop(window.pageYOffset > 1);
		setHover(false);

	}

	const handleClose = (href) => {
		if(href.includes("http")) {
			window.open(href, "_self")
			return;
		}
		if (href && href !== '')
			redirectTo(href)
	};
	const hoverUrlList = [
		"", "/home", "/", "/login", "/signup"
	]

	const {forceHover, history} = props;

	let user = LocalStorage.instance.getUser()

	let rootClasses = [classes.root]
	if (hover && hoverUrlList.indexOf(history.location.pathname) !== -1 && window.pageYOffset === 0)
		rootClasses.push(classes.homeHeader)


	if (offsetTop)
		rootClasses.push(classes.hover)

	if (forceHover)
		rootClasses.push(classes.hover)


	const solMenu = [
		{text: "RADIUS", href: "/docs/intro/solutions/2fa-vpn-radius"},
		{text: "SAML2.0", href: "/docs/intro/solutions/2fa-o365-saml"},
		{text: "OIDC", href: "/docs/intro/solutions/saleforce-oidc"},
	]
	const guiMenu = [
		{text: "V-Key App", href: "/docs/help/vkey-apps"},
		{text: "VPN/RADIUS", href: "/docs/help/2fa-for-vpn"},
		{text: "V2 Cloud/SAML2.0", href: "/docs/help/vos-cloud-saml2"},
		{text: "Office 365/SAML2.0", href: "/docs/help/2fa-for-o365"},
		{text: "Ingram Micro/SAML2.0", href: "/docs/help/ingram-micro-saml2"},
		{text: "Gitlab/SAML2.0", href: "/docs/help/gitlab-saml2"},
		{text: "Zoom/SAML2.0", href: "/docs/help/zoom-saml2"},
		{text: "AWS/SAML2.0", href: "/docs/help/aws-saml2"},
		{text: "Wordpress/SAML2.0", href: "/docs/help/wordpress-saml2"},
		{text: "Assembla/SAML2.0", href: "/docs/help/assembla-saml2"},
		{text: "Saleforce/OIDC", href: "/docs/help/saleforce-oidc"},
		{text: "Wordpress/OIDC", href: "/docs/help/wordpress-oidc"},
	]

	const overview = [
		{text: translate('resources.home.overview'), href: "/docs/intro/overview"},
	]
	const faq = [
		{text: "FAQ", href: "/docs/intro/faq"},
		{text: "Ticket", href: "https://v-key.atlassian.net/servicedesk"},
		// {text: "Contact Us", href: "#"},
	]

	const SolutionMenuList = (props) => {
		// log.debug("SolutionMenuList:", props)
		return (
			<MenuList className={props.className}>
				{solMenu.map((item, index) => (
					<MenuItem
						onClick={() => handleClose(item.href)}
					>
						{item.text}
					</MenuItem>
				))}
			</MenuList>
		)
	}
	const SetupGuideMenuList = (props) => {
		return (
			<MenuList className={props.className}>
				{guiMenu.map((item, index) => (
					<MenuItem
						onClick={() => handleClose(item.href)}
					>
						{item.text}
					</MenuItem>
				))}
			</MenuList>
		)
	}

	const FAQMenuList = (props) => {
		return (
			<MenuList className={props.className}>
				{faq.map((item, index) => (
					<MenuItem
						onClick={() => handleClose(item.href)}
					>
						{item.text}
					</MenuItem>
				))}
			</MenuList>
		)
	}


	const list = (anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === 'top' || anchor === 'bottom',
			})}
			style={{backgroundColor: "#533475", height: "100%"}}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>

			<List>
				{overview.map((item, index) => (
					<ListItem button key={item.href}>
						<ListItemIcon>{index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
						<ListItemText primary={item.text} onClick={() => handleClose(item.href)}/>
					</ListItem>
				))}

			</List>
			<Divider/>
			<List aria-labelledby="sol-list-subheader"
				  component="nav"
				  subheader={
					  <ListSubheader component="div" id="sol-list-subheader" style={{color: "white"}}>
						  {translate('resources.home.solutions')}
					  </ListSubheader>
				  }
				  className={classes.listHeader}

			>
				{solMenu.map((item, index) => (
					<ListItem button key={item.href}>
						<ListItemIcon>{index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
						<ListItemText primary={item.text} onClick={() => handleClose(item.href)}/>
					</ListItem>
				))}

			</List>
			<Divider/>
			<List aria-labelledby="gui-list-subheader"
				  component="nav"
				  subheader={
					  <ListSubheader component="div" id="gui-list-subheader" style={{color: "white"}}>
						  {translate('resources.home.guidelines')}
					  </ListSubheader>
				  }
				  className={classes.listHeader}
			>
				{guiMenu.map((item, index) => (
					<ListItem button key={item.href}>
						<ListItemIcon>{index % 2 !== 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
						<ListItemText primary={item.text} onClick={() => handleClose(item.href)}/>
					</ListItem>
				))}
			</List>
			<Divider/>
			<List>
				{faq.map((item, index) => (
					<ListItem button key={item.href}>
						<ListItemIcon>{index % 2 !== 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>
						<ListItemText primary={item.text} onClick={() => handleClose(item.href)}/>
					</ListItem>
				))}
			</List>
		</div>
	);
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	const mobileMenuAnchor = "left";
	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({...state, [anchor]: open});
	};
	const [open, setOpen] = React.useState(false);

	const handleCloses = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	return (

		<Toolbar style={{minHeight: "20px", height: "18px", paddingLeft: "50px", marginBottom: "5px", ...props.style}}>
			<Hidden smDown>
				<Button className={classes.menuBtn}
						component={props => <Link to="/docs/intro/overview" {...props} />}
				>
					{translate('resources.home.overview')}
				</Button>
			</Hidden>
			{/*<DropDownMenu/>*/}
			{/*<MultiLevelMenu />*/}

			<Hidden smDown>
				<ButtonMenuPopper
					open={solOpen}
					setOpen={setSolOpen}
					// buttonIcon={<DashboardIcon/>}
					component={SolutionMenuList}
					id={"sol-popper-menu"}
					arrow={true}
					buttonText={translate('resources.home.solutions')}
				/>
			</Hidden>

			<Hidden smDown>
				<ButtonMenuPopper
					open={guiOpen}
					setOpen={setGuiOpen}
					// buttonIcon={<Settings />}
					component={SetupGuideMenuList}
					id={"gui-popper-menu"}
					arrow={true}
					buttonText={translate('resources.home.guidelines')}

				/>
			</Hidden>
			<Hidden smDown>
				<ButtonMenuPopper
					open={faqOpen}
					setOpen={setFaqOpen}
					// buttonIcon={<Settings />}
					component={FAQMenuList}
					id={"faq-popper-menu"}
					arrow={true}
					buttonText={translate('resources.home.faq')}

				/>
			</Hidden>
			<Drawer anchor={mobileMenuAnchor} open={state[mobileMenuAnchor]}
					onClose={toggleDrawer(mobileMenuAnchor, false)}>
				{list(mobileMenuAnchor)}
			</Drawer>
		</Toolbar>
	);

}

export default CommonMenu
