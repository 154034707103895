
import { LOAD_ACC_PROFILE_DATA, LOAD_SUBSCRIPTION_DATA, LOAD_COMP_INFO_DATA, LOAD_SEC_2FA_CFG_DATA } from '../actions';


export default (previousState = { } , { type, payload }) => {
    if (type === LOAD_ACC_PROFILE_DATA) {
        return {
            ...previousState,
            accountInfo: payload
        }
    }
    if (type === LOAD_SUBSCRIPTION_DATA) {
        // log.debug('--------------------- LOAD_SUBSCRIPTION_DATA -------------')
        return {
            ...previousState,
            subscriptionDetail: payload
        }
    }

    if (type === LOAD_COMP_INFO_DATA) {
        return {
            ...previousState,
            companyInfo: payload
        }
    }

    if (type === LOAD_SEC_2FA_CFG_DATA) {
        return {
            ...previousState,
            security2FAConfigInfo: payload
        }
    }

    return previousState;
};
