import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";

const styles = {}

export default class ConfirmationDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            areConfirming: false
        }

        this.confirm = this.confirm.bind(this)
    }

    confirm() {
        this.setState({ areConfirming: true })
    }

    render() {
        const {options, translate, onOk, onCancel, width} = this.props
        return (
            <Dialog
                open={this.state.areConfirming}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{ options ? options.title : "Confirmation" }</DialogTitle>
                <DialogContent style={{ minWidth: width !== 'xs' ? 400 : 'unset' }}>
                    <DialogContentText>
                        { options ? options.body : "Are you sure?" }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => { this.setState({ areConfirming: false }); onCancel() }}>
                        { translate('ra.boolean.false') }
                    </Button>
                    <Button color="primary" onClick={() => { this.setState({ areConfirming: false }); onOk() }}>
                        { translate('ra.boolean.true') }
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialog.propTypes = {
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    width: PropTypes.string.isRequired
};