import React, {Component} from 'react'
import {Tooltip} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import {Edit} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
    button: {
        margin: 2,
    },
    loading: {
        marginRight: 5
    },
    default: {
        width: 35,
        height: 35,
        color: theme.palette.primary.main,
        float: "right",
        // right: "30%"
    }
})

class ButtonGoto extends Component {

    render() {
        const {classes, title, reference, resourceId, history} = this.props
        return (
            <Tooltip title={"Edit"}>
                <IconButton {...this.props} className={classes.default}
                            style={{visibility: resourceId ? 'visible' : 'hidden'}}
                            onClick={e => {
                                history.push(`/${reference}/${resourceId}`)
                            }}
                label >
                    <Edit/>
                </IconButton>
            </Tooltip>
        )
    }
}

ButtonGoto.propTypes = {
    reference: PropTypes.string,
    resourceId: PropTypes.string
}

export default withStyles(styles)(ButtonGoto)