
import React from 'react';
import {
    TextField
} from 'react-admin';
import get from 'lodash/get';

const VkeyAmountField = ({ source, currencySource, sortable, record = {}, style, ...rest }) => {
    
    let value = get(record, source);
    let currency = get(record, currencySource);
    if (value && currency) {
        // record[source] = value + ' ' + currency;
    }
    {/*<TextField sortable={sortable} {...rest} source={source} record={record} />*/}

    return (
        <span style={style}>{ (record[source] != null && record[source] > 0 ) ? record[source] + " "  + record[currencySource] : "N/A" }</span>
    );
};

export default VkeyAmountField;