import { Grid } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import SearchBar from "material-ui-search-bar";
import React, { useEffect, useState } from "react";
import { useNotify } from 'react-admin';
import TextLinesEllipsis from "../../components/TextLinesEllipsis";
import httpClient, { API_URL } from "../../dataProvider/httpClient";
import log from "../../utils/log";
import { ServiceTemplate } from "../epics/styled";
import WaitingView from "./WaitingView";

const svTemplateListStyle = makeStyles((theme) => ({
    itemRoot: {
        display: "flex",
        flexDirection: "column",
        width: 180,
        height: 220,
        padding: theme.spacing(1.5),
        border: '1px solid',
        borderColor: 'rgba(204, 204, 204, 0.5)',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
        margin: theme.spacing(0, 1.5, 1.5, 0),
        borderRadius: 3,
        color: grey[800],
        backgroundColor: 'white',
        cursor: "pointer",
        userSelect: "none",
        msUserSelect: "none",
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 4px 8px',
        }
    },
    itemImage: {
        height: 60
    },
    itemTitle: {
        fontSize: '0.875em'
    },
    itemDescription: {
        fontSize: '0.688em',
        letterSpacing: '.2px'
    },
    mt2: {
        marginTop: theme.spacing(2)
    },
    mt1: {
        marginTop: theme.spacing(1)
    },
    mb1: {
        marginBottom: theme.spacing(1)
    }
}))

export interface ServiceTemplateListProps {
    onSelect?: (templateId: string) => void
}

const ServiceTemplateList = (props: ServiceTemplateListProps) => {

    const classes = svTemplateListStyle()
    const [serviceTemplates, setServiceTemplates] = useState(new Array<ServiceTemplate>())
    const [filteredTemplates, setFilteredTemplates] = useState(new Array<ServiceTemplate>())
    const [query, setQuery] = useState("")
    const [loading, setLoading] = useState(true)
    const [groupProtocol, setGroupProtocol] = useState(new Set<string>())
    const notify = useNotify()

    useEffect(() => {
        httpClient( `${API_URL}/service-mgmt/template`)
            .then(rsp => rsp.json)
            .then(arrSvTemplates => {
                setServiceTemplates(arrSvTemplates)
                setFilteredTemplates(arrSvTemplates)
                setLoading(false)
                setQuery(" ") // Cheat

            }).catch(e => {
            log.error(e)
            setLoading(false)
            notify(JSON.stringify(e), "warning")
        })
    }, [])

    useEffect(() => {
        const tempGroups = new Set<string>()
        setFilteredTemplates(serviceTemplates.filter(template => {
            let result = query === "" || template.templateName.toLowerCase().includes(query.toLowerCase())
            if (result)
                tempGroups.add(template.protocol || "")

            return result
        }))

        setGroupProtocol(tempGroups)
    }, [query])

    return (
        <WaitingView loading={loading}>
            <div style={{ display: "flex", flexDirection: "column", height: '100%' }}>
                <Grid container spacing={4} style={{ paddingLeft: 16, position: "relative" }}>
                    <Grid item xs={12}>
                        <SearchBar
                            onChange={(q) => setQuery(q)}
                            onRequestSearch={() => log.debug('onRequestSearch')}
                            style={{
                                margin: '16px auto',
                                borderRadius: 50,
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px'
                            }}
                        />
                    </Grid>
                </Grid>

                {
                    Array.from(groupProtocol.values()).map(protocol => <React.Fragment>
                        <div className={clsx(classes.mt2, classes.mb1)}>{protocol}</div>
                        <div style={{ display: "flex", flexWrap: "wrap", position: "relative" }}>
                            {
                                filteredTemplates.filter(svTemplate => svTemplate.protocol === protocol)
                                    .map(svTemplate =>
                                        <div className={classes.itemRoot} onClick={() => props.onSelect ? props.onSelect(svTemplate.id) : {}}>
                                            <div className={classes.itemImage}>
                                                <img height={'100%'}
                                                     src={svTemplate.iconUrl}
                                                />
                                            </div>

                                            <TextLinesEllipsis text={svTemplate.templateName} lines={2} className={clsx(classes.mt2, classes.itemTitle)} />

                                            <TextLinesEllipsis text={svTemplate.shortDescription || ""} lines={3}
                                                               className={clsx(classes.mt1, classes.itemDescription)}/>

                                        </div>
                                )
                            }
                        </div>
                    </React.Fragment>)
                }
            </div>
        </WaitingView>
    )
}

export default ServiceTemplateList