import React from "react";

const SvgMenuDirectories = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="menu_directories_svg__clip-path">
        <path
          id="menu_directories_svg__Rectangle_1612"
          d="M0 0h24v24H0z"
          data-name="Rectangle 1612"
          transform="translate(411 594)"
          stroke="#707070"
          fill="currentColor"
        />
      </clipPath>
      <style>{".menu_directories_svg__cls-3{fill:currentColor}"}</style>
    </defs>
    <g
      id="menu_directories_svg__Mask_Group_36"
      data-name="Mask Group 36"
      transform="translate(-411 -594)"
      clipPath="url(#menu_directories_svg__clip-path)"
    >
      <g
        id="menu_directories_svg__directory"
        transform="translate(411.104 594)"
      >
        <g id="menu_directories_svg__Layer_2" data-name="Layer 2">
          <g id="menu_directories_svg__directory-2" data-name="directory">
            <path
              id="menu_directories_svg__Rectangle_1616"
              d="M0 0h6.901v3.922H0z"
              className="menu_directories_svg__cls-3"
              data-name="Rectangle 1616"
              transform="translate(8.396)"
            />
            <path
              id="menu_directories_svg__Rectangle_1617"
              d="M0 0h6.901v3.922H0z"
              className="menu_directories_svg__cls-3"
              data-name="Rectangle 1617"
              transform="translate(2.198 10.645)"
            />
            <path
              id="menu_directories_svg__Rectangle_1618"
              d="M0 0h6.901v3.922H0z"
              className="menu_directories_svg__cls-3"
              data-name="Rectangle 1618"
              transform="translate(14.651 10.645)"
            />
            <path
              id="menu_directories_svg__Path_788"
              d="M13.919 5.719L6.606 9.843v2.6h2.376v-1.2L13.9 8.418l4.922 2.917v1.107h2.468v-2.416"
              className="menu_directories_svg__cls-3"
              data-name="Path 788"
              transform="translate(-2.076 -1.797)"
            />
            <path
              id="menu_directories_svg__Rectangle_1619"
              d="M0 0h4.982v3.294H0z"
              className="menu_directories_svg__cls-3"
              data-name="Rectangle 1619"
              transform="translate(0 20.706)"
            />
            <path
              id="menu_directories_svg__Rectangle_1620"
              d="M0 0h4.982v3.294H0z"
              className="menu_directories_svg__cls-3"
              data-name="Rectangle 1620"
              transform="translate(6.256 20.706)"
            />
            <path
              id="menu_directories_svg__Rectangle_1621"
              d="M0 0h4.982v3.294H0z"
              className="menu_directories_svg__cls-3"
              data-name="Rectangle 1621"
              transform="translate(12.591 20.706)"
            />
            <path
              id="menu_directories_svg__Rectangle_1622"
              d="M0 0h4.982v3.294H0z"
              className="menu_directories_svg__cls-3"
              data-name="Rectangle 1622"
              transform="translate(18.811 20.706)"
            />
            <path
              id="menu_directories_svg__Path_789"
              d="M6.316 21.334l-3.858 3.857v2.285h2.386v-1.443l1.55-1.55 1.525 1.525v1.468h2.465v-2.39z"
              className="menu_directories_svg__cls-3"
              data-name="Path 789"
              transform="translate(-.773 -6.705)"
            />
            <path
              id="menu_directories_svg__Path_790"
              d="M24.67 21.334l-3.859 3.857v2.285H23.2v-1.443l1.55-1.55 1.528 1.525v1.468h2.463v-2.39z"
              className="menu_directories_svg__cls-3"
              data-name="Path 790"
              transform="translate(-6.541 -6.705)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMenuDirectories;
