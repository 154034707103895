import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, {FC, useEffect, useRef} from 'react';
import {required, SimpleForm, TextInput, useTranslate} from 'react-admin';
import {Field, useForm} from "react-final-form";
import {connect} from "react-redux";
import VkeyFormToolbar from "../components/VkeyFormToolbar";
import VkeyTooltipWrapper from "../components/VkeyTooltipWrapper";
import {DEFAULT_MAX_LENGTH} from "../constants";
import httpClient, {API_URL} from "../dataProvider/httpClient";
import log from "../utils/log";
import LocalStorage from "../utils/services/storage";
import ServiceConnectorConfig from "./ServiceConnectorConfig";

const styles = {}

/*export const validate = (values) => {
    log.info("Inside validate ", values)
    const errors = {};
    /!*['instanceName'].forEach(field => {
        if (!values[field]) {
            errors[field] = ['Required field'];
        }
    });*!/
    let finalErrors = {};
    if (Object.keys(errors).length) {
        finalErrors = errors;

    }
    else {
        delete values.iconUrl;
        connectorValidate(values).then(rsp => {
            log.info("Validate connector done ", rsp)
        });
    }

    return finalErrors
}*/

const connectorValidate = async values => {
    log.debug("values: ", values)
    const { json } = await httpClient(`${API_URL}/service-mgmt/service-launch/validate-update`, {
        method: "POST",
        body: JSON.stringify(values)
    })

    if (values.force == 'false' && !json.success)
    {
        return { "force":  `The connector ${json.usedConnectors[0].connectorName} is using for service ${json.usedConnectors[0].serviceInstanceName} now. 
        At the same time, one connector is only assigned for one service only. 
        If you assign it to new service, the last assignment will be removed automatically. 
        The old service will no longer work correctly. Do you really want to use it for this service?`}
    }
}

const ServiceLaunchForm = (props) => {
    const translate = useTranslate();
    console.log("server form : ", {props})

    // @ts-ignore
    return (
        <SimpleForm
            basePath={"/" + `${props.resource}`}
            record={props.record}
            toolbar={<VkeyFormToolbar style={{ display: props.wizard ? 'none' : 'flex' }}/>}
            validate={connectorValidate}
            // form={'serviceEdit'}
            { ...props }
        >
            <VkeyTooltipWrapper>
                <TextInput source="instanceName" inputProps={{ maxLength: DEFAULT_MAX_LENGTH }}
                           validate={required()}
                           label={translate('resources.serviceLaunchs.fields.instanceName.label')}
                           title={translate('resources.serviceLaunchs.fields.instanceName.tooltip')} />
                <TextInput source="description" inputProps={{ maxLength: DEFAULT_MAX_LENGTH + 1000 }} multiline={true}
                               label={translate('resources.serviceLaunchs.fields.description.label')}
                               title={translate('resources.serviceLaunchs.fields.description.tooltip')} />
                <TextInput source="status" disabled={true}
                               label={translate('resources.serviceLaunchs.fields.status.label')}
                               title={translate('resources.serviceLaunchs.fields.status.tooltip')}/>

                <ServiceConnectorConfig history={props.history} />

                <TextInput source="customerId" style={{ display: 'none' }} defaultValue={LocalStorage.instance.getCustomerId()} />

                <Field name="force" allowNull={true} defaultValue={"false"} >
                    {({ input, meta }) => (
                        <>
                            <div style={{ display: 'none' }}>
                                <label>Force</label>
                                <input {...input} type="text" placeholder="Force" />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                <WarningConnectorDialog open={!!meta.error} error={meta.error} record={props.record} />
                            </div>
                            <div>
                                {meta.error && <span style={{ fontSize: "0.875em", color: "red" }}>{"* " + meta.error}</span>}
                            </div>
                        </>
                    )}
                </Field>
            </VkeyTooltipWrapper>

        </SimpleForm>
    );

}
export const ConfirmDialog: FC<{open?: boolean, error?: any}> = ({ open = false, ...props}) => {
    const [stateOpen, setStateOpen] = React.useState(open);
    const [error, setError] = React.useState(props.error);
    const handleClose = () => {
        setStateOpen(false);
    };
    useEffect(() => {
        setError(props.error)
        setStateOpen(open)
    }, [open])
    return (
        <div>
            <Dialog
                open={stateOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick={true}
            >
                <DialogTitle id="alert-dialog-title">{"No subscription found!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>{ error }</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        window.history.back()
                    }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        setStateOpen(!stateOpen)
                        window.open("/", "_blank")
                    }} color="primary" autoFocus>
                        Subscribe
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export const WarningConnectorDialog:FC<{ record?: any, open: boolean, error?: any }> = (props) => {

    const [open, setOpen] = React.useState(props.open);
    const [error, setError] = React.useState(props.error);
    const currentForm = useForm()

    useEffect(() => {
        setError(props.error)
        setOpen(props.open)
    }, [props.open])

    const handleClose = () => {
        setOpen(false);
    };

    return <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Warning connector setting!"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div>{ error }</div>
                    <div>Do you want to use it for this service?</div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpen(!open)
                }} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => {
                    currentForm.batch(() => currentForm.change(`force`, "true"));
                    setOpen(!open)
                }} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}

const mapStateToProps = state => {
    return {
        loading: state.wizard.loading,
        initial: state.wizard.initial,
        steps: state.wizard.steps,
        serviceInstanceId: state.wizard.serviceInstanceId,
        currentStep: state.wizard.currentStep,
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch
});

export default connect(
        mapStateToProps,
        mapDispatchToProps
    )(ServiceLaunchForm)


