import React from 'react';
import {connect} from 'react-redux';
import {Layout, Sidebar} from 'react-admin';
import CustomAppBar from "./CustomAppBar";
import {darkTheme, lightTheme} from './themes';
import {portalTheme} from './themes/PortalTheme';

const CustomSidebar = props => <Sidebar size={250} {...props} id="side-bar"/>;

const CustomLayout = props => (
    <Layout appBar={CustomAppBar} sidebar={CustomSidebar} {...props} />
);

export const getTheme = (themeName) => {

    let theme = lightTheme;

    switch (themeName) {
        case 'dark':
            theme = darkTheme;
            break;

        case 'portal':
            theme = portalTheme;
            break;

    }

    return theme;
}

export default connect(
    state => ({
        theme: getTheme(state.theme),
    }),
    {}
)(CustomLayout);
