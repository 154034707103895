const UPDATE_SCOPE = 'menu/SCOPE';
const UPDATE_DASHBOARD = 'menu/DASHBOARD';
const UPDATE_SUPPORT_SERVICE_FEATURE = 'menu/SUPPORT_SERVICE_FEATURE';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_SCOPE:
            // log.debug('Current state ', state)
            // log.debug('New state ', {
            //     ...state,
            //     scope: action.data
            // })
            return {
                ...state,
                scope: action.data
            }
        case UPDATE_DASHBOARD:
            // // log.debug('Current state ', state)
            // // log.debug('New state ', {
            //     ...state,
            //     scope: action.data
            // })
            return {
                ...state,
                dashboard: action.data
            }

        case UPDATE_SUPPORT_SERVICE_FEATURE:
            // // log.debug('Current state ', state)
            // // log.debug('New state ', {
            //     ...state,
            //     scope: action.data
            // })
            return {
                ...state,
                supportServiceFeatures: action.data
            }

        default:
            //// log.debug('In default case, ', action.type)
            return state
    }
}

/**
 * Simulates data loaded into this reducer from somewhere
 */
export const updateScope = data => {
    // log.debug('Inside update scope ', data)
    return { type: UPDATE_SCOPE, data }
}

export const updateDashboardView = data => {
    // log.debug('Inside update dashboard view ', data)
    return { type: UPDATE_DASHBOARD, data }
}

export const updateSupportServiceFeatures = data => {
    // log.debug('Inside updateSupportServiceFeatures', data)
    return { type: UPDATE_SUPPORT_SERVICE_FEATURE, data }
}

export default reducer