import {
	CREATE,
	DELETE,
	DELETE_MANY,
	fetchUtils,
	GET_LIST,
	GET_MANY,
	GET_MANY_REFERENCE,
	GET_ONE,
	UPDATE,
	UPDATE_MANY
} from "react-admin";
import LocalStorage from "../../utils/services/storage";

export const ACTIVE_MANY = 'ACTIVE_MANY'
export const INACTIVE_MANY = 'INACTIVE_MANY'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&pageSize=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
	/**
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} { url, options } The HTTP request parameters
	 */
	const convertDataRequestToHTTP = (type, resource, params) => {
		let url = "";
		const options = {};
		let customerId = LocalStorage.instance.getCustomerId()
		switch (type) {
			case GET_LIST: {
				const {page, perPage} = params.pagination;
				const payload = {
					page: page - 1,
					limit: perPage,
					...params.filter
				}
				url = `${apiUrl}/${resource}/search`;
				options.method = "POST";
				options.body = JSON.stringify(payload);
				break;
			}
			case GET_ONE:
				const payload = {
					id: params.id
				}
				url = `${apiUrl}/${resource}/detail`;
				options.method = "POST";
				options.body = JSON.stringify(payload);
				break;
			case GET_MANY: {
				// const query = {
				//   filter: JSON.stringify({ id: params.ids })
				// };
				// let idStr = "";
				// const queryString = params.ids.map(id => idStr + `id=${id}`);
				// url = `${apiUrl}/${resource}?${idStr}`;
				// dinh.tran update
				// log.debug('Debugging GET_MANY ', params)
				url = `${apiUrl}/${resource}/get-customers-detail`;
				options.body = JSON.stringify(params.ids);
				options.method = "POST";
				break;
			}
			case GET_MANY_REFERENCE: {
				const {page, perPage} = params.pagination;
				url = `${apiUrl}/${resource}?page=${page}&pageSize=${perPage}`;
				break;
			}
			case UPDATE:
				url = `${apiUrl}/${resource}/update`;
				options.method = "PUT";
				options.body = JSON.stringify(params.data);
				break;
			case CREATE:
				url = `${apiUrl}/${resource}/create`;
				options.method = "POST";
				options.body = JSON.stringify(params.data);
				break;
			case DELETE:
				url = `${apiUrl}/${resource}/${params.id}`;
				options.method = "DELETE";
				break;
			case UPDATE_PROFILE:
				// log.debug('[UPDATE_PROFILE] with params  ', params)
				url = `${apiUrl}/${resource}/${customerId}/update_profile`;
				options.method = 'PUT'
				options.body = JSON.stringify(params.data);
				// JSON.stringify({
				//   customerId,
				//   customerName: params.data.customerName,
				//   contactPhone: params.data.contactPhone,
				//   address: params.data.address,
				//   logoUrl: params.data.logoUrl
				// })
				break;
			default:
				throw new Error(`Unsupported fetch action type ${type}`);
		}
		return {url, options};
	};

	/**
	 * @param {Object} response HTTP response from fetch()
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} Data response
	 */
	const convertHTTPResponse = (response, type, resource, params) => {
		const {headers, json} = response;
		switch (type) {
			case GET_LIST:
			case GET_MANY_REFERENCE:
				if (!json.hasOwnProperty("totalElements")) {
					throw new Error(
						"The numberOfElements property must be must be present in the Json response"
					);
				}
				return {
					data: json.contents,
					total: parseInt(json.totalElements, 10)
				};

			case GET_MANY:
				// log.debug("GET_MANY response ", json)
				return {data: json.data}
			case CREATE:
				return {data: {...params.data, id: json.id}};

			default:
				return {data: json};
		}
	};

	/**
	 * @param {string} type Request type, e.g GET_LIST
	 * @param {string} resource Resource name, e.g. "posts"
	 * @param {Object} payload Request parameters. Depends on the request type
	 * @returns {Promise} the Promise for a data response
	 */
	return (type, resource, params) => {
		// simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
		if (type === INACTIVE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/deactivate`, {
						method: "POST",
						body: JSON.stringify({id: id})
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json)
			}));
		}

		if (type === ACTIVE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/activate`, {
						method: "POST",
						body: JSON.stringify({id: id})
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json)
			}));
		}

		if (type === UPDATE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: "PUT",
						body: JSON.stringify(params.data)
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json)
			}));
		}
		// simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
		if (type === DELETE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: "DELETE"
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json)
			}));
		}

		const {url, options} = convertDataRequestToHTTP(type, resource, params);
		return httpClient(url, options)
			.then(response =>
				convertHTTPResponse(response, type, resource, params)
			).catch(e => {
				const errorJson = e.body;
				if (errorJson && errorJson.messageKey) {
					throw new Error(errorJson.messageKey);
				} else {
					throw new Error("error.default.internal.server.error");
				}

			});
	};
};