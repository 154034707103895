import React, {Component} from 'react'
import log from "../../utils/log";

export default class HomeBaseComponent extends Component {

    constructor(props) {
        super(props)
    }

    componentDidCatch(error, errorInfo) {
        log.error('[HomeBaseComponent] componentDidCatch', error, errorInfo)
        // alert('componentDidCatch please check error log')
    }
}