import {stringify} from 'query-string';
import {
	CREATE,
	DELETE,
	DELETE_MANY,
	fetchUtils,
	GET_LIST,
	GET_MANY,
	GET_MANY_REFERENCE,
	GET_ONE,
	UPDATE,
	UPDATE_MANY,
} from 'react-admin';
import LocalStorage from "../../../utils/services/storage";
import log from "../../../utils/log";

export const RESET_PASSWORD_USER_KEYCLOAK = "RESET_PASSWORD_KEYCLOAK";
export const UPLOAD_CSV_USER_KEYCLOAK = "UPLOAD_CSV_USER_KEYCLOAK";
export const LOAD_USERS_FROM_AZURE = "LOAD_USERS_FROM_AZURE";
export const IMPORT_AZURE_USERS_KEYCLOAK = "IMPORT_AZURE_USERS_KEYCLOAK";

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
	/**
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} { url, options } The HTTP request parameters
	 */
	const convertDataRequestToHTTP = (type, resource, params) => {
		const customerId = LocalStorage.instance.getCustomerId();
		let url = '';
		const options = {};
		switch (type) {
			case GET_LIST: {
				const {page, perPage} = params.pagination;
				const {field, order} = params.sort;
				const id = params.filter.id;
				const query = {
					page: page,
					pageSize: perPage,
				};
				url = `${apiUrl}/${resource}/r-${customerId}/users/connectors/${id}?${stringify(query)}`;
				break;
			}
			case GET_ONE:
				url = `${apiUrl}/${resource}/r-${customerId}/users/${params.id}`;
				break;
			case GET_MANY: {
				const query = {
					filter: JSON.stringify({id: params.ids}),
				};
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				break;
			}
			case GET_MANY_REFERENCE: {
				const {page, perPage} = params.pagination;
				const {field, order} = params.sort;
				const query = {
					sort: JSON.stringify([field, order]),
					range: JSON.stringify([
						(page - 1) * perPage,
						page * perPage - 1,
					]),
					filter: JSON.stringify({
						...params.filter,
						[params.target]: params.id,
					}),
				};
				url = `${apiUrl}/${resource}?${stringify(query)}`;
				break;
			}
			case IMPORT_AZURE_USERS_KEYCLOAK:
				url = `${apiUrl}/users/azure/migrate-all-users`
				options.method = 'POST';
				options.body = JSON.stringify(params);
				break;
			case LOAD_USERS_FROM_AZURE:
				let perPage = params.pagination.perPage;
				let startFrom = (params.pagination.page - 1) * perPage
				url = `https://graph.microsoft.com/v1.0/users?$select=${params.select}&$top=${perPage}`;
				options.method = 'GET';
				options.headers = new Headers({
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Sec-Fetch-Site': 'none',
					'Access-Control-Expose-Headers': 'Content-Range',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS',
					'Authorization': 'Bearer ' + params.accessToken,
				});
				break;
			case UPDATE:
				url = `${apiUrl}/${resource}/r-${customerId}/users/${params.id}`;
				options.method = 'PUT';
				options.body = JSON.stringify(params.data);
				break;
			case CREATE:
				url = `${apiUrl}/${resource}/r-${customerId}/users/connectors/${params.data.connectorId}`;
				options.method = 'POST';
				options.body = JSON.stringify(params.data);
				break;
			case DELETE:
				log.debug("delete here: ", params)
				url = `${apiUrl}/${resource}/r-${customerId}/users/${params.id}`;
				options.method = 'DELETE';
				break;
			case RESET_PASSWORD_USER_KEYCLOAK:
				url = `${apiUrl}/${resource}/r-${customerId}/users/${params.id}/reset-password`;
				options.method = 'PUT';
				options.body = JSON.stringify(params.data);
				break;

			case UPLOAD_CSV_USER_KEYCLOAK:
				url = `${apiUrl}/${resource}/r-${customerId}/users/connectors/${params.data.id}/upload-csv-file`;

				let formData = new FormData();
				formData.append('file', params.data.file);
				let token = LocalStorage.instance.getToken()
				// let headers = {
				// 	'content-type': 'multipart/form-data',
				// 		'Transfer-Encoding': 'chunked',
				// 		'Authorization': 'Bearer ' + token,
				// 		'Boundary': 123123123
				// }
				// options.headers = new Headers(headers);
				options.body = formData
				options.method = 'POST';
				break;
			default:
				throw new Error(`Unsupported fetch action type ${type}`);
		}
		return {url, options};
	};

	/**
	 * @param {Object} response HTTP response from fetch()
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} Data response
	 */
	const convertHTTPResponse = (response, type, resource, params) => {
		const {headers, json} = response;
		switch (type) {
			case GET_LIST:
				return {
					data: json.data.content,
					total: parseInt(json.data.totalElements, 10)
				};

			case GET_MANY_REFERENCE:
				if (!headers.has('content-range')) {
					throw new Error(
						'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
					);
				}
				return {
					data: json.data.content,
					total: parseInt(json.data.totalElements, 10)
				};
			// case CREATE:
			// 	return { data: { ...params.data, id: json.id } };
			case UPDATE:
			case DELETE:
				return {data: {...json.data, id: params.id}};
			case LOAD_USERS_FROM_AZURE:
				log.debug("LOAD_AZURE_LIST_USER: ", json["@odata.nextLink"][0] )
				log.debug("LOAD_AZURE_LIST_USER: ", json["@odata.nextLink"] )
				return {
					data: {
						value: json.value,
						nextLink: json["@odata.nextLink"]
					},
					total: parseInt(json.value.length, 10),
				}
				break;
			default:
				return {data: json};
		}
	};

	/**
	 * @param {string} type Request type, e.g GET_LIST
	 * @param {string} resource Resource name, e.g. "posts"
	 * @param {Object} payload Request parameters. Depends on the request type
	 * @returns {Promise} the Promise for a data response
	 */
	return (type, resource, params) => {
		const customerId = LocalStorage.instance.getCustomerId();
		// simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
		if (type === UPDATE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'PUT',
						body: JSON.stringify(params.data),
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}
		// simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
		if (type === DELETE_MANY) {
			let url = `${apiUrl}/${resource}/r-${customerId}/users/`;
			return Promise.all(
				params.ids.map(id =>
					httpClient(url + `${id}`, {
						method: 'DELETE',
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}

		const {url, options} = convertDataRequestToHTTP(
			type,
			resource,
			params
		);
		return httpClient(url, options).then(response =>
			convertHTTPResponse(response, type, resource, params)
		).catch(e => {

			let errorJson = e.body;
			log.debug("Exception: ", errorJson)
			throw new Error(errorJson.message);
			if (errorJson.message && errorJson.status) {
				switch (errorJson.status ) {
					case 500:
						let rs = {
							json: {
								code: 0,
								data: {
									totalPages: 0,
									totalElements: 0,
									content: []
								},
								httpStatus: 200,
								success: true,
							},
							headers: new Headers(),
							status: 200
						}
						return convertHTTPResponse(rs, type, resource, params)
					case 504:
						throw new Error(errorJson);
					default:
						throw new Error(errorJson.message);
						break
				}

			} else if (errorJson && errorJson.messageKey) {
				log.debug(errorJson.messageKey)
				throw new Error(errorJson.messageKey);
			} else
				throw new Error("error.default.internal.server.error");
		});
	};
};