import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";
import clsx from 'clsx'

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical"
        },
        line1: {
            WebkitLineClamp: 1
        },
        line2: {
            WebkitLineClamp: 2
        },
        line3: {
            WebkitLineClamp: 3
        },
        line4: {
            WebkitLineClamp: 4
        },
    })
);

interface TextLinesEllipsisProps extends React.HTMLProps<HTMLDivElement> {
    text: string,
    lines: number
}

const TextLinesEllipsis = React.forwardRef<HTMLDivElement, TextLinesEllipsisProps>((props:TextLinesEllipsisProps) => {
    const classes = styles()
    const clazz = [classes.root, props.className]

    if (props.lines)
        { // @ts-ignore
            clazz.push(classes["line" + props.lines])
        }

    return (
        <div className={clsx(clazz)} title={props.text}>{
            props.text
        }</div>
    )
})

export default TextLinesEllipsis