import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {GET_ONE, ReferenceInput, SelectInput, useDataProvider, useTranslate} from 'react-admin';
import {Grid} from '@material-ui/core';
import {GET_ALL_CONNECTORS} from '../dataProvider/customProvider';
import LdapReferenceInput from './LdapReferenceInput';
import RadiusReferenceInput from './RadiusReferenceInput';
import SamlReferenceInput from './SamlReferenceInput';
import OIDCReferenceInput from './OIDCReferenceInput';
import ButtonGoto from "../components/ButtonGoto";
import deepGet from '../wla_v2/utils/deepGet'
import OTPList from "../internal/otp/ListOTP";

const styles = makeStyles( (theme) => ({
    gotoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'flex-end',
        paddingBottom: 5,
        paddingLeft: 10,
        position: "relative",
        top: "30px"
    }
}));

const ServiceConnectorConfig = (props) => {
    const theme = useTheme();
    const classes = styles(theme)

    const [ serviceTemplateId, setServiceTemplateId ] = useState(props.record ? props.record.serviceTemplateId : null)
    const [ serviceTemplate, setServiceTemplate ] = useState()
    const [ connectors, setConnectors ] = useState()
    // const [ connectorConfig, setConnectorConfig ] = useState()

    const dataProvider = useDataProvider();
    const translate = useTranslate();

    const getServiceTemplate = (id) => {
        dataProvider(GET_ONE, 'service-template', { id: id },
            {
                onSuccess: {

                },
                onFailure: {
                    notification: { body: 'Error: Get Service Template Failed', level: 'warning' }
                }
            }).then(rsp => {

                setServiceTemplate(rsp.data);
            });
    }

    const getAllConnectors = () => {
        dataProvider(GET_ALL_CONNECTORS, 'token-pack', {},
            {
                onSuccess: {

                },
                onFailure: {
                    notification: { body: 'Error: Get All Connectors Failed', level: 'warning' }
                }
            }).then(rsp => {
                setConnectors(rsp.data);
            });
    }

    const getFieldLabel = (connectorId) => {
        //// log.debug(connectorId)
        if (connectors) {
            for (let i = 0; i < connectors.length; i++) {
                if (connectors[i].id === connectorId) {
                    return connectors[i].name + ( connectorId !== '1' ? ' Connector' : '');
                }
            }
        }
        return "";  
    }

    const getReference = (connectorId) => {
        if (connectors) {
            for (let i = 0; i < connectors.length; i++) {
                if (connectors[i].id === connectorId) {
                    return connectors[i].resource;
                }
            }
        }
        return "";
    }

    useEffect(() => {
        getAllConnectors();
        getServiceTemplate(serviceTemplateId)
    }, [serviceTemplateId])

    const { source, record, history, width } = props;
    return (
        <Grid container>
            {serviceTemplate && serviceTemplate.connectorIds
                && serviceTemplate.connectorIds.length > 0
                && serviceTemplate.connectorIds.map(connectorId => {

                    if (getReference(connectorId) === 'ldap') {

                        return (
                            <Grid container md={12}>
                                <div style={{ width: width === 'xs' ? '60%' : '80%' }}>
                                    <LdapReferenceInput key={connectorId}
                                        label={getFieldLabel(connectorId)}
                                        source={'connectorConfig.connector_' + connectorId}
                                        reference={getReference(connectorId)} />
                                </div>
                                <div className={classes.gotoContainer}>
                                    <ButtonGoto width={10} reference={getReference(connectorId)}
                                                resourceId={deepGet(record, `connectorConfig.connector_${connectorId}`)} history={history} />
                                </div>
                            </Grid>
                        )

                    } else if (getReference(connectorId) === 'radius') {

                        return (
                            <Grid container md={12}>
                                <div style={{ width: width === 'xs' ? '60%' : '80%' }}>
                                    <RadiusReferenceInput key={connectorId}
                                        label={getFieldLabel(connectorId)}
                                        source={'connectorConfig.connector_' + connectorId}
                                        reference={getReference(connectorId)} />
                                </div>
                                <div className={classes.gotoContainer}>
                                    <ButtonGoto width={10} reference={getReference(connectorId)}
                                                resourceId={deepGet(record, `connectorConfig.connector_${connectorId}`)} history={history} />
                                </div>
                            </Grid>

                        )
                    } else if (getReference(connectorId) === 'saml') {

                        return (
                            <Grid md={12} container>
                                <div style={{ width: width === 'xs' ? '60%' : '80%' }}>
                                    <SamlReferenceInput key={connectorId}
                                        label={getFieldLabel(connectorId)}
                                        source={'connectorConfig.connector_' + connectorId}
                                        reference={getReference(connectorId)} />
                                </div>
                                <div className={classes.gotoContainer}>
                                    <ButtonGoto width={10} reference={getReference(connectorId)}
                                                resourceId={deepGet(record, `connectorConfig.connector_${connectorId}`)} history={history} />
                                </div>
                            </Grid>

                        )
                    } else if (getReference(connectorId) === 'oidc') {

                        return (
                            <Grid md={12} container>
                                <div style={{ width: width === 'xs' ? '60%' : '80%' }}>
                                    <OIDCReferenceInput key={connectorId}
                                        label={getFieldLabel(connectorId)}
                                        source={'connectorConfig.connector_' + connectorId}
                                        reference={getReference(connectorId)} />
                                </div>
                                <div className={classes.gotoContainer}>
                                    <ButtonGoto width={10} reference={getReference(connectorId)}
                                                resourceId={deepGet(record, `connectorConfig.connector_${connectorId}`)} history={history} />
                                </div>
                            </Grid>

                        )
                    } else if (getReference(connectorId) === 'saml2') {

                        return (
                            <Grid md={12} container>
                                <div style={{ width: width === 'xs' ? '60%' : '80%' }}>
                                    <ReferenceInput reference={getReference(connectorId)} style={{ width: '100%'}}
                                                    label={getFieldLabel(connectorId)}
                                                    source={'connectorConfig.connector_' + connectorId}>
                                        <SelectInput optionText={"name"} />
                                    </ReferenceInput>
                                </div>
                                <div className={classes.gotoContainer}>
                                    <ButtonGoto width={10} reference={getReference(connectorId)}
                                                resourceId={deepGet(record, `connectorConfig.connector_${connectorId}`)} history={history} />
                                </div>
                            </Grid>

                        )
                    } else if (getReference(connectorId) === 'ldap_2fa') {

                        return (
                            <Grid md={12} container>
                                <div style={{ width: width === 'xs' ? '60%' : '80%' }}>
                                    <ReferenceInput reference={getReference(connectorId)} style={{ width: '100%'}}
                                                    label={getFieldLabel(connectorId)}
                                                    source={'connectorConfig.connector_' + connectorId}
                                                    disabled={true}
                                    >
                                        <SelectInput optionText={"name"} />
                                    </ReferenceInput>
                                </div>
                                <div className={classes.gotoContainer}>
                                    <ButtonGoto width={10} reference={getReference(connectorId)}
                                                resourceId={deepGet(record, `connectorConfig.connector_${connectorId}`)} history={history} />
                                </div>
                            </Grid>

                        )
                    } else  if  (getReference(connectorId) === 'https') {
                         return (
                                 <Grid md={12} xs={12} sm={12} lg={12} item>
                                 <OTPList {...props} connectorId={deepGet(record, `connectorConfig.connector_${connectorId}`)} />
                             </Grid>
                         )

                    }

                })
            }
        </Grid>
    );
}

export default ServiceConnectorConfig;
