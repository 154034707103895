import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {AccountCircle, PowerSettingsNew} from '@material-ui/icons';
import LocalStorage from '../utils/services/storage';
import {userLogout, useRedirect} from 'react-admin'
import {connect} from 'react-redux';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {Link} from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import ButtonMenuPopper from "../components/ButtonMenuPopper";
import useTheme from "@material-ui/core/styles/useTheme";
import useTranslate from "ra-core/esm/i18n/useTranslate";
import {Hidden} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const useStyle = makeStyles((theme) => ({

    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    textWhite: {
        color: 'white',
        textTransform: 'unset'
    },
    menuBtn: {
        color: 'white',
        // fontSize: "16px",
        textTransform: "capitalize",
        "&:hover,&:focus,&:after": {
            color: 'rgb(255, 157, 0)'
        }

    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const MenuListComposition = (props) => {
    const [open, setOpen] = useState(null);
    const theme = useTheme();
    const classes = useStyle(theme);
    const translate = useTranslate();
    const {user, userLogout} = props;
    const redirectTo = useRedirect();

    const handleClick = event => {
        switch (event.currentTarget.name) {
            case "userMenu":
                this.setState({anchorEl: event.currentTarget});
                break;
            default:
                break;
        }
    }
    const handleToggle = () => {
        this.setState(state => ({open: !state.open}));
    };


    const handleClose = (href) => {
        if (href && href !== '')
            redirectTo(href)
    };

    const handleLogout = () => {
        LocalStorage.instance.logout();
        LocalStorage.instance.clearAccountPermission();
        this.handleToggle()
        this.props.history.push('/home')
    }

    const UserMenuCustom = (props) => {
        return (
            <MenuList className={props.className}>
                <MenuItem
                    onClick={() => handleClose('/my-account/profile')}
                >
                    <AccountCircleIcon
                        color={"action"}
                        style={{paddingRight: '1.2rem'}}
                    /> {translate('resources.myAccounts.menuName')}
                </MenuItem>

                <MenuItem onClick={e => {
                    userLogout()
                }}>
                    <PowerSettingsNew
                        color={"action"}
                        style={{paddingRight: '1.2rem'}}
                    /> {translate('ra.auth.logout')}
                </MenuItem>
            </MenuList>
        )
    }


    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const mobileMenuAnchor = "right";
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            style={{backgroundColor: "#533475", height: "100%"}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem button key={"token-pack"}>
                    <ListItemIcon>{<DashboardIcon/>}</ListItemIcon>
                    <ListItemText primary={translate('pos.dashboard.name')}
                                  onClick={() => handleClose("/token-pack")}/>
                </ListItem>
                <ListItem button key={"my-account/profile"}>
                    <ListItemIcon>{<AccountCircleIcon/>}</ListItemIcon>
                    <ListItemText primary={translate('resources.myAccounts.menuName')}
                                  onClick={() => handleClose("/my-account/profile")}/>
                </ListItem>
                <ListItem button key={"logout"}>
                    <ListItemIcon>{<PowerSettingsNew/>}</ListItemIcon>
                    <ListItemText primary={translate('ra.auth.logout')}
                                  onClick={() => userLogout()}/>
                </ListItem>


            </List>
            <Divider/>

        </div>
    );
    return (
        <div className={classes.root}>
            <Hidden smDown>
                <Button className={classes.menuBtn}
                        style={{fontSize: '1em'}}
                        component={props => <Link to="/report" {...props} />}
                >
                    <DashboardIcon style={{marginRight: 5}}/>
                    {translate('pos.dashboard.name')}
                </Button>
                <ButtonMenuPopper
                    open={open}
                    setOpen={setOpen}
                    buttonIcon={<AccountCircle/>}
                    component={UserMenuCustom}
                    id={"user-popper-menu"}
                    arrow={true}
                    buttonText={user.username}
                />
            </Hidden>
            <div className={classes.sectionMobile}>
                <IconButton
                    onClick={toggleDrawer(mobileMenuAnchor, true)}
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>

            </div>
            <Drawer anchor={mobileMenuAnchor} open={state[mobileMenuAnchor]}
                    onClose={toggleDrawer(mobileMenuAnchor, false)}>
                {list(mobileMenuAnchor)}
            </Drawer>


        </div>
    );

}

MenuListComposition.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(
    null,
    {userLogout}
)(MenuListComposition);
// export default connect(null, {userLogout})(withStyles(styles)(translate(MenuListComposition)));