import deepGet from './deepGet';
import deepSet from './deepSet';
const SCREEN_DEFS = `themeDefinition.screenDefs`;

export default (obj, type, value) => {
    const current = obj;
    // // log.debug('obj ne ', obj);
    const screenDefs = deepGet(obj, SCREEN_DEFS);
    for (let i = 0; i < screenDefs.length; i++) {
        (screenDefs[i].fields).forEach((element, index) => {
            if (element.id === type) {
                const path = `${SCREEN_DEFS}[${i}].fields[${index}].value`;
                //// // log.debug('path image deep Set ', path);
                deepSet(current, path, value);
            }
        });
    }
    return current;
};
