import React from "react";

const SvgCheckIcon = props => (
  <svg viewBox="0 0 23.4 19" {...props}>
    <path
      fill="currentColor"
      d="M8.965 19L0 8.809l2.127-2.418 6.838 7.6L21.273 0 23.4 2.418z"
      className="check_icon_svg__cls-1"
    />
  </svg>
);

export default SvgCheckIcon;
