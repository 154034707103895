import {CREATE, DELETE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE} from "react-admin";
import qs from "query-string";
import {GET_OIDC_SECRET} from "./simpleRestProvider";
import log from '../utils/log';

export const convertDataRequestToHTTP = (apiUrl: string, type: string, resource: string, params: any) => {
    let url = "";
    const options = {} as any;
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            url = `${apiUrl}/${resource}?pageNumber=${page - 1}&pageSize=${perPage}&${qs.stringify(params.filter)}`;
            break;
        }
        case GET_ONE:
            url = `${apiUrl}/${resource}/${params.id}`;
            break;
        case GET_MANY: {
            let idStr = { ids: params.ids };
            // alert("get many " + qs.stringify(params.ids));
            url = `${apiUrl}/${resource}?${qs.stringify(idStr)}`;
            break;
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            url = `${apiUrl}/${resource}?pageNumber=${page - 1}&pageSize=${perPage}`;
            break;
        }
        case UPDATE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = "PUT";
            options.body = JSON.stringify(params.data);
            break;
        case CREATE:
            url = `${apiUrl}/${resource}`;
            options.method = "POST";
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = "DELETE";
            break;
        case GET_OIDC_SECRET:
            url = `${apiUrl}/${resource}/get-client-secret/${params.id}`;
            options.method = 'GET';
            break;
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The data request params, depending on the type
 * @returns {Object} Data response
 */
export const convertHTTPResponse = (response: any, type: string, resource: string, params: any) => {
    const { headers, json } = response;
    log.debug(resource, type, headers);
    switch (type) {
        case GET_LIST:
        case GET_MANY_REFERENCE:
        case GET_MANY:
            if (!json.hasOwnProperty("totalElements")) {
                throw new Error(
                    "The numberOfElements property must be must be present in the Json response"
                );
            }
            return {
                data: json.content,
                total: parseInt(json.totalElements, 10)
            };
        case GET_ONE:
            return {
                data: json[0]
            }
        case CREATE:
            return { data: { ...params.data, id: json.id } };
        case UPDATE:
            return {data: { ...params.data, id: json[0].id }}
        default:
            return { data: json };
    }
};
