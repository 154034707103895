import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { updateServiceWizardStatus, getManyServices } from './api/serviceInstanceApi'
import {
    loadTokenPackInitialData as loadTokenPackInitialDataApi,
    getLatestTPNew
} from './api/tokenPackApi'
import {SERVICE_WIZARD_SET_TOKENPACK_DETAIL, SERVICE_WIZARD_SET_SERVICES_DETAIL} from "./wizardReducers";

export const UPDATE_SERVICE_WIZARD_STATUS = 'UPDATE_SERVICE_WIZARD_STATUS'

export const LOAD_TOKEN_PACK_INITIAL_DATA = 'LOAD_TOKEN_PACK_INITIAL_DATA'

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* updateServiceStatus(action) {
    try {
        // log.debug('updateServiceStatus saga ', action)
        yield call(updateServiceWizardStatus, {
            ...action.payload
        });
        // yield put({type: "USER_FETCH_SUCCEEDED", user: user});
    } catch (e) {
        // yield put({type: "USER_FETCH_FAILED", message: e.message});
    }
}

// Initial wizard data for a token pack
function* loadTokenPackInitialData(action) {
    try {
        let tokenPackDetail
        if (action.payload.tokenPackId) {
            tokenPackDetail = yield call(loadTokenPackInitialDataApi, {
                tokenPackId: action.payload.tokenPackId
            });
        } else {
            tokenPackDetail = yield call(getLatestTPNew, {})
        }

        // log.debug('loadTokenPackInitialData saga ', tokenPackDetail)
        yield put({ type: SERVICE_WIZARD_SET_TOKENPACK_DETAIL, data: tokenPackDetail });
        const serviceDetails = yield call(getManyServices, { ids: Object.keys(tokenPackDetail.serviceIds) })
        // log.debug('continue load service details: ', serviceDetails.json)

        let finalServiceDetails = [];
        // TODO: exclude OIDC service wizard
        if (serviceDetails.json) {
            //// log.debug('HA::: json', serviceDetails.json)
            serviceDetails.json.forEach(serviceDetail => {
                if (!serviceDetail.serviceTemplate.connectorIds.includes('4')) {
                    finalServiceDetails.push(serviceDetail);
                }
            });
            //// log.debug('HA::: final service detail', finalServiceDetails)
        }

        yield put({ type: SERVICE_WIZARD_SET_SERVICES_DETAIL, data: finalServiceDetails });
    } catch (e) {
        // yield put({type: "USER_FETCH_FAILED", message: e.message});
    }
}

function* mySaga() {
    yield takeEvery(UPDATE_SERVICE_WIZARD_STATUS, updateServiceStatus);
    yield takeEvery(LOAD_TOKEN_PACK_INITIAL_DATA, loadTokenPackInitialData);
}

export default mySaga;