import {stringify} from 'query-string';
import {DELETE_MANY, fetchUtils, GET_LIST, GET_ONE, UPDATE_MANY,} from 'react-admin';
import LocalStorage from '../../utils/services/storage';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
	/**
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} { url, options } The HTTP request parameters
	 */
	const convertDataRequestToHTTP = (type, resource, params) => {
		let customerId = LocalStorage.instance.getCustomerId();
		let url = '';
		const options = {};
        let tokenPackId = params.filter.tokenPackId;
		switch (type) {
			case GET_LIST: {
				const query = {
					page: params.pagination.page - 1,
					pageSize: params.pagination.perPage,
					sort: params.sort.field,
					order: params.sort.order
				};

				if (params.filter.tab === "history") {
					url = `${apiUrl}/users/${tokenPackId}/${resource}/${params.filter.id}?${stringify(query)}`;

				} else if (params.filter.tab === "troubleshootinglog") {
					url = `${apiUrl}/users/${tokenPackId}/get-device-id/${params.filter.id}`;
				}

				break;
			}
			case GET_ONE: {
				url = `${apiUrl}/users/${tokenPackId}/get-device-id/${params.id}`;
				break;
			}
			default:
				throw new Error(`Unsupported fetch action type ${type}`);
		}
		return {url, options};
	};

	/**
	 * @param {Object} response HTTP response from fetch()
	 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
	 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
	 * @param {Object} params The data request params, depending on the type
	 * @returns {Object} Data response
	 */
	const convertHTTPResponse = (response, type, resource, params) => {
		const {headers, json} = response;
		switch (type) {
			case GET_LIST: {
				const data = {};
				if (params.filter.tab === "troubleshootinglog") {

					data.data = [json];
					data.total = [json].length;

				} else if (params.filter.tab === "history") {
					data.data = json.data.content;
					data.total = json.data.totalElements

				}
				// log.debug(data);
				return data;
			}
			// case GET_ONE:{
			//     const data = {
			//         data: json
			//     };
			//     // log.debug("Data", data);
			//     return data;
			// }
			default:
				return {data: json};
		}
	};

	/**
	 * @param {string} type Request type, e.g GET_LIST
	 * @param {string} resource Resource name, e.g. "posts"
	 * @param {Object} payload Request parameters. Depends on the request type
	 * @returns {Promise} the Promise for a data response
	 */
	return (type, resource, params) => {
		// simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
		if (type === UPDATE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'PUT',
						body: JSON.stringify(params.data),
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}
		// simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
		if (type === DELETE_MANY) {
			return Promise.all(
				params.ids.map(id =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: 'DELETE',
					})
				)
			).then(responses => ({
				data: responses.map(response => response.json),
			}));
		}

		const {url, options} = convertDataRequestToHTTP(
			type,
			resource,
			params
		);
		return httpClient(url, options).then(response =>
			convertHTTPResponse(response, type, resource, params)
		).catch(e => {
			let errorJson = e.body;
			if (errorJson && errorJson.messageKey)
				throw new Error(errorJson.messageKey);
			else
				throw new Error("error.default.internal.server.error");
		});
	};
};
