import React, {createRef, ForwardRefExoticComponent, Fragment, RefAttributes, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import store from "../epics/store";
import {CLEAN_CONNECTORS, fetchConnectorsByType, getTemplateDetail, unshiftConnector} from "../epics/rootEpic";
import {useParams} from 'react-router-dom'
import {ConnectorList, ConnectorTypeKey, ServiceLaunch} from "../epics/styled";
import ConnectorType from "../epics/ConnectorType";
import LoadingSelect, {LoadingSelectData} from "./LoadingSelect";
import DialogCreateConnector, {DialogCreateConnectorRef} from "./DialogCreateConnector";
import {FieldArray} from 'react-final-form-arrays'
import {TextField} from "mui-rff";
import {useForm} from "react-final-form";
import {useTranslate} from 'react-admin'

const styles = makeStyles((theme) => ({

}))

export type Handle<T> = T extends ForwardRefExoticComponent<RefAttributes<infer T2>> ? T2 : never;

const FGConnectors = (props) => {

    const { templateId } = useParams<{templateId: string}>()
    const [template, setTemplate] = useState({} as any)
    const [connectors, setConnectors] = useState({} as ConnectorList)
    const [loadingElem, setLoadingElem] = useState("")
    const [keys, setKeys] = useState(new Array<string>())
    const translate = useTranslate()
    const currentForm = useForm()
    let dialogHandle = createRef<DialogCreateConnectorRef>();

    useEffect(() => {
        store.dispatch(getTemplateDetail(templateId))
        store.subscribe(() => {
            const serviceLaunch = store.getState().serviceLaunch as ServiceLaunch
            setTemplate(serviceLaunch.serviceTemplate)
            setConnectors({ ...connectors, ...serviceLaunch.connectors })
            setLoadingElem(serviceLaunch.loadingElem || "")
        })

        return () => {
            store.dispatch({ type: CLEAN_CONNECTORS })
            setConnectors({})
        }
    }, [])

    useEffect(() => {
        const tmpKeys = new Array<string>()

        template.connectorIds?.forEach(id => {
            const connectorKey = ConnectorType.instance.getById(id)?.key
            if (connectorKey)
            {
                tmpKeys.push(connectorKey)
                setTimeout(() => store.dispatch(fetchConnectorsByType(ConnectorType.instance.getById(id)?.key || "ldap")), 100)

            }
        })
        setKeys(tmpKeys)
    }, [template])

    /*useEffect(() => {
        log.debug("Connectors changed ", connectors)
    }, [connectors])*/

    // @ts-ignore
    return <Fragment>
        <FieldArray name={"connectors"}>
            {({fields}) =>
            keys.map((key, index) => <Fragment>
                <LoadingSelect name={`connectors[${index}].id`} label={translate(`resources.${key}.name`, {smart_count: 1})}
                               loading={loadingElem === key}
                               options={(connectors[key] && connectors[key].length) > 0 ?
                                   connectors[key].map(connector => (
                                       {label: key !== 'oidc' ? connector.name : connector.clientName, value: connector.id} as LoadingSelectData
                                   )) : []}
                               onCreateNew={name => {
                                   dialogHandle?.current?.createConnector(key)
                               }}
                />
                <TextField style={{ display: 'none' }} name={`connectors[${index}].type`} inputRef={() => {
                    currentForm.batch(() => currentForm.change(`connectors[${index}].type`, key))
                }} />
            </Fragment>)
            }
        </FieldArray>
        <DialogCreateConnector ref={dialogHandle}
                               onSubmit={(connType, connName) =>
                                   store.dispatch(unshiftConnector(connType, { name: "(New) " + connName, id: "NEW" }))}/>
    </Fragment>
}

export default FGConnectors