import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import {translate} from 'react-admin';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {makeStyles} from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    default: {
        fontSize: '1.8rem',
        // fontWeight: 'lighter',
        fontFamily: theme.font.default,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.4rem',
            marginTop: 10
        }
    }
}));

const FormTitle = (props) => {
    const {
        title
    } = props

    const classes = styles()

    document.title = title + " | V-OS Cloud"

    return (
        <div className={classes.default} {...props}>
            { title }
        </div>
    );
}


const mapStateToProps = state => ({
});

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
        }
    ),
    withStyles(styles),
    withWidth()
);


export default connect(
    mapStateToProps,
    {
    }
)(FormTitle)

