import React, {Component, Fragment} from 'react'
import {PropTypes} from "prop-types";
import DashboardPageWrapperWithTheme from "../../components/DashboardPageWrapper";
import SamlForm from './form'

const styles = ({

})

export default class VkeySamlEdit extends Component {

    render() {
        const {translate, handleSubmit} = this.props
        return (
            <DashboardPageWrapperWithTheme title={ "resources.saml.edit_title" }>
                <SamlForm {...this.props} />
            </DashboardPageWrapperWithTheme>
        );
    }
}
