import React, {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import LoadingSelect from "./LoadingSelect";
import store from '../epics/store'
import {fetchSubscription, fetchTokenPack, serviceLaunchCancel} from "../epics/rootEpic";
import {useFormState} from 'react-final-form'
import {ServiceLaunch} from "../epics/styled";
import {useTranslate} from 'react-admin'

const styles = makeStyles((theme) => ({

}))

const FGSubscriptionTokenPack = (props) => {

    const [userLaunch, setUserLaunch] = useState({ loading: false } as ServiceLaunch)
    const formState = useFormState()
    const translate = useTranslate()

    useEffect(() => {
        const unsubscribable = store.subscribe(() => {
            setUserLaunch({
                ...userLaunch,
                ...store.getState().serviceLaunch
            })
        })

        store.dispatch(fetchSubscription())

        return () => {
            store.dispatch(serviceLaunchCancel())
            unsubscribable()
        }
    }, [])

    useEffect(() => {
        // Fetch tokenPack inside subscription
        if (formState.values.subscriptionId) store.dispatch(fetchTokenPack(formState.values.subscriptionId))
    }, [formState.values.subscriptionId])

    useEffect(() => {
        props.setSubscription((!userLaunch.loading && userLaunch.subscriptions && userLaunch.subscriptions.length==0 && true), "You must subscribe at least one TokenPack at Home page")
    }, [userLaunch])

    return <Fragment>
        <LoadingSelect name={"subscriptionId"} label={translate(`resources.billing.subscription`, {smart_count: 1})}
                       loading={userLaunch.loading && userLaunch.loadingElem === "subscription"}
                       options={userLaunch.subscriptions ?
                           userLaunch.subscriptions.map(sub => ({label: sub.tierPaymentPlan.description + ` (${sub.numberOfUsers} Users)`, value: sub.id})) : []}
        />
        <LoadingSelect name={"tokenPackId"} label={translate(`resources.tokenpacks.name`, {smart_count: 1})}
                       loading={userLaunch.loading && userLaunch.loadingElem === "tokenPack"}
                       options={userLaunch.subscriptions ?
                           userLaunch.tokenPacks?.map(tokenPack => ({label: tokenPack.name, value: tokenPack.id})) : []}
        />
    </Fragment>
}

export default FGSubscriptionTokenPack