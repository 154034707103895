import React from "react";

const SvgPermissionAsset24Px = props => (
  <svg width={24} height={24} {...props}>
    <path d="M0 0h24v24H0z" data-name="Path 948" fill="none" />
    <path
      d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94V12H5V6.3l7-3.11v8.8z"
      data-name="Path 949"
      fill="#fff"
    />
  </svg>
);

export default SvgPermissionAsset24Px;
