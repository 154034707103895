import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import FormTitle from './FormTitle'
import {
    Button,
    Card,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip
} from '@material-ui/core';
import {useTranslate, useRedirect} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {lightTheme} from '../layout/themes'
import WaitingView from '../external/components/WaitingView'
import HelpIcon from '@material-ui/icons/HelpOutline'
import BackIcon from '@material-ui/icons/ArrowBack'
import {HELP_BASE_PATH} from '../constants'
import log from "../utils/log";

const styles = makeStyles((theme) => ({
    ...lightTheme,
    vkeyIconButton: {
        /*width: 35,
        height: 35,*/
        color: theme.palette.primary.main,
    },
    originalCard: {
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    }
}))

interface DashboardPageWrapperProps extends React.HTMLProps<HTMLDivElement>{
    title?: string
    loading?: boolean,
    maxWidth?: string,
    help?: { resource: string },
    history?: any,
    acceptCallback?: (record: any) => void,
    backURL?: string
}

export type DashboardPageWrapperRef = {
    confirm: (optionsParam: any, acceptCallback: any, rejectCallback: any) => void,
    loading: (bLoading: boolean) => void
} | null;

const DashboardPageWrapper = forwardRef<DashboardPageWrapperRef, DashboardPageWrapperProps>(({ backURL, loading = false, ...props} : DashboardPageWrapperProps, ref ) => {

    const [areConfirming, setAreConfirming] = useState(false)
    const [stateLoading, setStateLoading] = useState(false)
    const [options, setOptions] = useState<any>({})
    const [acceptCallback, setAcceptCallback] = useState<any>(() => { log.debug("acceptCallback do nothing.") })
    const [rejectCallback, setRejectCallback] = useState<any>(() => { log.debug("rejectCallback do nothing.") })
    const translate = useTranslate()
    const classes = styles()
    const redirect = useRedirect();

    useEffect(() => {
        setStateLoading(loading)
    }, [])

    useImperativeHandle(ref, () => ({

         confirm: (optionsParam, acceptCallback, rejectCallback) => {
             setAreConfirming(true)
             setAcceptCallback(acceptCallback)
             setRejectCallback(rejectCallback)
             setOptions(optionsParam)
         },

         loading: (bLoading) => {
             log.info("SetLoading .... ", bLoading)
             setStateLoading(bLoading)
         }

     }));


    const acceptHandler = () => {
        setAreConfirming(false)
        if (acceptCallback)
            acceptCallback(options.record)

        if (props.acceptCallback)
            props.acceptCallback(options.record)
    }

    const rejectHandler = () => {
        setAreConfirming(false)
        if (rejectCallback)
            rejectCallback()
    }

    /*loading(loading) {
        this.setState({loading})
    }*/

    const collectionHas = (a: any, b: any) => { //helper function (see below)
        for(let i = 0, len = a.length; i < len; i ++) {
            if(a[i] == b) return true;
        }
        return false;
    }

    const findParentBySelector = (elm: any, selector: any) => {
        let all = document.querySelectorAll(selector);
        let cur = elm.parentNode;
        while(cur && !collectionHas(all, cur)) { //keep going up until you find a match
            cur = cur.parentNode; //go up
        }
        return cur; //will return null if not found
    }

    const { maxWidth, help } = props

    let mWidth = (maxWidth) ? maxWidth : '100%';
    const history = props.history;

    return (
        <div id="dashboard-wrapper" style={{ height: 'auto', width: '100%', position: 'relative', maxWidth: mWidth, marginTop: "20px", paddingRight: "20px" }} onDoubleClick={e => {
            // // log.debug('dashboard-wrapper clicked ', e.target.matches("tbody tr") || e.target.matches("tbody tr *"))
            const element = e.target as HTMLElement;
            if (element.matches("tbody tr") || element.matches("tbody tr *")) {
                // try to find rowaction
                const currentTr = findParentBySelector(e.target, 'tr')
                let rowAction = currentTr.querySelector('[rowaction]')
                if (!rowAction) rowAction = currentTr.querySelector(".rowaction")
                if (rowAction)
                    rowAction.click()
            }

        }}>

            { props.title ?
                <div style={{ marginBottom: 10, display: 'flex' }}>
                    { (history || backURL) &&
                    <Button className={classes.vkeyIconButton} onClick={() => backURL ? redirect(backURL) : history.goBack()} >
                        <BackIcon/>
                    </Button>
                    }
                    <FormTitle title={ translate(props.title, 2) } />

                    { help && <Tooltip title={ "Open help" }>
                        <IconButton className={classes.vkeyIconButton}
                                    onClick={() => { window.open(`${HELP_BASE_PATH}#${help.resource}`) }}>
                            <HelpIcon/>
                        </IconButton>
                    </Tooltip> }
                </div> : ""
            }
            <Card className={classes.originalCard}>
                <WaitingView loading={stateLoading}>{props.children}</WaitingView>
            </Card>
            {<Dialog
                open={areConfirming}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{ options && options.title ? options.title : "Confirmation" }</DialogTitle>
                <DialogContent style={{ minWidth: 400 }}>
                    <DialogContentText>
                        { options && options.body ? options.body : "Are you sure?" }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={rejectHandler}>
                        { translate('ra.boolean.false') }
                    </Button>
                    <Button color="primary" onClick={acceptHandler}>
                        { translate('ra.boolean.true') }
                    </Button>
                </DialogActions>
            </Dialog>}
        </div>

    );

})

export default DashboardPageWrapper