import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {KeyboardArrowDown} from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
    menuBtn: {
        fontFamily: "Roboto !important",
        fontWeight: 400,
        color: 'white',
        fontSize: "16px",
        textTransform: "capitalize",
        "&:hover,&:focus,&:after": {
            color: 'rgb(255, 157, 0)'
        },

    },
    scrollContainer: {
        height: 400,
        overflow: 'auto',
        marginBottom: theme.spacing(3),
    },
    scroll: {
        position: 'relative',
        width: '230%',
        backgroundColor: theme.palette.background.paper,
        height: '230%',
    },
    legend: {
        marginTop: theme.spacing(2),
        maxWidth: 300,
    },
    paper: {
        maxWidth: 400,
        overflow: 'auto',
    },
    select: {
        width: 200,
    },
    popper: {
        border: "1px solid #9e65bd",
        borderRadius: "5px",
        zIndex: 1,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    hiddenMenu: {
        marginRight: "5px",
        fontFamily: "Roboto !important",
        fontWeight: 400,
    },
    menuItem: {
        '& li': {
            fontSize: "11pt",
            '&:hover,&:focus,&:after': {
                backgroundColor: "rgba(125, 47, 171, 0.5)",
                color: '#fff',
            }
        },
        '& a': {
            fontSize: "11pt",
            '&:hover,&:focus,&:after': {
                backgroundColor: "rgba(125, 47, 171, 0.5)",
                color: '#fff',
            }
        },
        "& svg": {
            // color: "rgba(0, 0, 0, 0.54)",
            // '&:hover,&:focus,&:after': {
            //     color: '#fff',
            // }
        }
    },
    endIcon: {
        marginLeft: 0
    },
    dropdown: {
        marginLeft: 0,
        transition: theme.transitions.create(["transform"], {
            duration: theme.transitions.duration.short
        })
    },
    dropdownOpen: {
        transform: "rotate(-180deg)"
    },
    dropdownClosed: {
        transform: "rotate(0)"
    }

}))
const ButtonMenuPopper = ({
                              component: Component,
                              id: id,
                              buttonText: buttonText,
                              buttonIcon: ButtonIcon,
                              setOpen: setOpen,
                              open: open,
                              ...props
                          }) => {

    const {arrow, placement} = props;
    const classes = useStyle();
    const [arrowRef, setArrowRef] = useState(null);
    const anchorRef = useRef(null);
    const [preventOverflow, setPreventOverflow] = useState('scrollParent');
    const [globalCursor, setGlobalCursor] = useState({
        x: null,
        y: null
    });
    const handleClick = e => {
        setOpen((pre) => !pre)

    }
    useEffect(() => {
    }, [open])
    const handleMouseOut = e => {
        // log.debug(e.currentTarget.getBoundingClientRect());
    }
    const handleMouseMove = event => {
        const e = event;
        let type = e.currentTarget.type ? e.currentTarget.type : "popper";
        let rect = e.currentTarget.getBoundingClientRect();
        let currentX = e.pageX - window.pageXOffset;
        let currentY = e.pageY - window.pageYOffset;
        let top = rect.top;
        let bottom = rect.bottom;
        let left = rect.left;
        let right = rect.right;

        switch (type) {
            case "button":
                if (currentX === undefined || currentY === undefined || currentY - 5 <= top || currentX <= left + 5 || currentX >= right - 15) {
                    setOpen(false);
                } else {
                    setOpen(true);
                }
                break;
            case "popper":
                if (currentX === undefined || currentY === undefined || currentY >= bottom - 5 || currentX <= left || currentX >= right - 5) {
                    setOpen(false);
                }
                break;
            default:
                setOpen(false);
                break;
        }
    }
    const handleClickAway = () => {
        setOpen(false);
    };
    return (
        <div className={classes.hiddenMenu}>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClickAway}
                disableReactTree={false}
            >
                <div>
                    <Button
                        variant={"text"}
                        startIcon={ButtonIcon ? ButtonIcon : null}
                        // onMouseMove={(e) => handleMouseMove(e)}
                        ref={anchorRef}
                        className={classes.menuBtn}
                        // aria-owns={anchorRef ? id : null}
                        // aria-haspopup="true"
                        onClick={e => handleClick("button")}
                        name={id ? id : null}
                        endIcon={<KeyboardArrowDown
                            className={[
                                classes.dropdown,
                                open ? classes.dropdownOpen : classes.dropdownClosed
                            ]}
                        />}
                    >
                        {buttonText}
                    </Button>

                    <Popper
                        onMouseLeave={() => setOpen(false)}
                        // onMouseMove={e => handleMouseMove(e)}
                        id={id ? id : null}
                        open={open ? open : false}
                        anchorEl={anchorRef.current}
                        placement={placement ? placement : "bottom-start"}
                        transition
                        disablePortal={true}
                        className={classes.popper}
                        // onExited={() => setOpen(false)}
                        modifiers={{
                            flip: {
                                enabled: true,
                            },
                            arrow: {
                                enabled: arrow ? arrow : false,
                                element: arrowRef,
                            },
                            preventOverflow: {
                                enabled: preventOverflow !== 'disabled',
                                boundariesElement: preventOverflow,
                            },
                        }}
                    >
                        {arrow ? <span className={classes.arrow} ref={setArrowRef}/> : null}
                        <Paper className={classes.paper}>
                            <Component {...props} className={classes.menuItem}/>
                        </Paper>

                    </Popper>
                </div>

            </ClickAwayListener>
        </div>


    )
}
export default ButtonMenuPopper;