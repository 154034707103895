import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from "react-admin";
import qs from "query-string";
import { GET_OIDC_SECRET } from "./simpleRestProvider";
import log from "../utils/log";
import { BASE_URL } from "./index";

export const GET_SAML2_DEFAULT_CONFIG = "GET_SAML2_DEFAULT_CONFIG";

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        log.info("convertDataRequestToHTTP", type, resource, params);
        let url = "";
        const options = {};
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                url = `${apiUrl}/${resource}?pageNumber=${page -
                    1}&pageSize=${perPage}&${qs.stringify(params.filter)}`;
                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY: {
                let idStr = { ids: params.ids };
                // alert("get many " + qs.stringify(params.ids));
                url = `${apiUrl}/${resource}?${qs.stringify(idStr)}`;
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                url = `${apiUrl}/${resource}?pageNumber=${page -
                    1}&pageSize=${perPage}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "PUT";
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;
            case GET_OIDC_SECRET:
                url = `${apiUrl}/${resource}/get-client-secret/${params.id}`;
                options.method = "GET";
                break;
            case GET_SAML2_DEFAULT_CONFIG:
                url = `${BASE_URL}/idm-config/saml2/${params.type}`;
                options.method = "GET";
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { headers, json } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
            case GET_MANY:
                if (!json.hasOwnProperty("totalElements")) {
                    throw new Error(
                        "The numberOfElements property must be must be present in the Json response"
                    );
                }
                return {
                    data: json.content,
                    total: parseInt(json.totalElements, 10),
                };
            case GET_ONE:
                return {
                    data: json[0],
                };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            case UPDATE:
                return { data: { ...params.data, id: json[0].id } };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data),
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "DELETE",
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }

        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options)
            .then((response) =>
                convertHTTPResponse(response, type, resource, params)
            )
            .catch((e) => {
                log.error("rest-api", resource, type, e);
                const errorJson = e.body;
                if (resource === "oidc") {
                    log.error("rest-api skip oidc", resource, e.body);
                    return;
                } else if (errorJson && errorJson.messageKey)
                    throw new Error(errorJson.messageKey);
                else throw new Error("error.default.internal.server.error");
            });
    };
};
