import React from 'react'
import {createTheme, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import {compose} from "recompose";
import {connect} from "react-redux";
import {lightTheme} from "../../../../layout/themes";
import Switch from "@material-ui/core/Switch";
import ConfirmationDialog from "../../../../internal/wizard_v2/components/ConfirmationDialog";
import {withWidth} from "@material-ui/core";
import {prefixBrand} from "../../../../utils/StringUtils";
// import Cards from "react-credit-cards";
// import 'react-credit-cards/lib/styles.scss'

const styles = theme => ({
	cardDetailRoot: {
		width: '50%',
		minWidth: 300,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			minWidth: 'unset'
		}
	},
	cardDetailContainer: {
		boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
		transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
		backgroundColor: '#f8f8f8',
		borderRadius: 15,
		padding: 16,
		// marginBottom: 16,
		color: '#848484',
		fontSize: '.9rem',
		display: 'flex',
		flexDirection: 'column',
		width: 360,
		height: 200,
		[theme.breakpoints.down('xs')]: {
			height: 110,
			fontSize: '.7rem',
			width: 'unset'
		}
	},
	cardDetailBrand: {
		width: 60,
		position: 'relative'
	},
	cardDetailItemPadding: {
		paddingLeft: 14
	},
	cardDetailPrimaryBadge: {
		position: 'absolute',
		right: -17,
		padding: '5px 16px',
		backgroundColor: 'grey',
		color: 'white',
	},
	cardDetailName: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		whiteSpace: "nowrap",
		overflow: "hidden",
		textTransform: "uppercase",
		// maxWidth: "10ch"
	},
	cardDetailLast4: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	cardDetailExpireDateLabel: {},
	cardDetailExpireDateValue: {},
	cardDetailActions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		width: 'calc(100% + 32px)',
		marginBottom: 16,
		[theme.breakpoints.down('xs')]: {
			width: '100%'
		}
	},
	checked: {
		color: 'var(--main-bg-color) !important'
	},
	bar: {
		backgroundColor: '#5e2785 !important'
	},
	root: {
		marginRight: '-15px !important'
	}
})

class StripeCardDetail extends React.Component {

	setAsDefaultCard = card => event => {
		event.preventDefault()
		if (event.target.checked) {
			this.confirm.confirm()
		} else {

		}
		// log.debug('Do nothing')
	}
	setAsPrimaryCard(card) {
		this.def(card).then(() => {
			this.props.setSelectedCard(card)
		})
	}
	async def(card) {
		await this.props.setPrimaryCard(card.id)
	}

	render() {
		const {classes, card, translate} = this.props
		let n = prefixBrand(card.brand).toString() + '••••••••' + card.last4
		return (
			<div className={classes.cardDetailRoot}>
				{/*<div className={classes.cardDetailContainer}>*/}
				{/*	<div style={{*/}
				{/*		display: 'flex',*/}
				{/*		flexDirection: 'column',*/}
				{/*		justifyContent: 'center',*/}
				{/*		position: 'relative'*/}
				{/*	}}>*/}
				{/*		<img alt={card.brand} className={classes.cardDetailBrand}*/}
				{/*			 src={`/assets/images/card_brand/${card.brand}.png`}/>*/}
				{/*		{card.isPrimary && <div className={classes.cardDetailPrimaryBadge}>Primary</div>}*/}
				{/*	</div>*/}
				{/*	<div className={classes.cardDetailName}>{card.name}</div>*/}

				{/*	<div className={classNames(classes.cardDetailLast4, classes.cardDetailItemPadding)}>*/}
				{/*		<div>**** **** **** {card.last4}</div>*/}
				{/*	</div>*/}
				{/*	<div className={classNames(classes.cardDetailExpireDateLabel, classes.cardDetailItemPadding)}>Expiry*/}
				{/*		date*/}
				{/*	</div>*/}
				{/*	<div className={classNames(classes.cardDetailExpireDateValue, classes.cardDetailItemPadding)}>*/}
				{/*		{card.expMonth > 9 ? card.expMonth : '0' + card.expMonth}/*/}
				{/*		{card.expYear}*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<Cards
					expiry={card.expMonth > 9 ? card.expMonth : '0' + card.expMonth}
					name={card.name}
					number={ n.toString()}
				/>*/}
				<div className={classes.cardDetailActions}>
					<label style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>Set as
						primary</label>
					<Switch
						checked={card.isPrimary}
						onChange={this.setAsDefaultCard(card)}
						value="checkedB"
						color="primary"
						classes={classes}
						style={{
							marginRight: -16
						}}
					/>
				</div>
				<ConfirmationDialog translate={translate}
									width={this.props.width}
									onCancel={() => { // log.debug('onCancel callback')
									}}
									onOk={() => {
										this.setAsPrimaryCard(card)
									}}
									options={{
										title: translate('resources.billing.stripe.card.set.primary.title'),
										body: translate('resources.billing.stripe.card.set.primary.confirm')
									}}
									ref={ref => this.confirm = ref}/>
			</div>
		);
	}

}

const mapStateToProps = state => ({});

const enhance = compose(
	connect(
		mapStateToProps,
		{}
	),
	withStyles(styles),
	withWidth()
);

const EnhanceStripeCardDetail = enhance(StripeCardDetail)

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const StripeCardDetailWithTheme = props => (
	<MuiThemeProvider theme={createTheme(lightTheme)}>
		<EnhanceStripeCardDetail {...props} />
	</MuiThemeProvider>
);

export default StripeCardDetailWithTheme;