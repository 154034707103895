import React from "react";

const SvgSalePackagePro = props => (
  <svg viewBox="0 0 60 50" {...props}>
    <path
      fill="currentColor"
      d="M57.5 22.525L45 22.5a2.507 2.507 0 00-2.5 2.5v22.5A2.507 2.507 0 0045 50h12.5a2.507 2.507 0 002.5-2.5V25a2.486 2.486 0 00-2.5-2.475zM57.5 45H45V27.5h12.5zM50 0H5a4.983 4.983 0 00-5 5v30a5 5 0 005 5h17.5v5h-5v5h20v-5h-5v-5h5v-5H5V5h45v12.5h5V5a5 5 0 00-5-5zM29.925 17.5L27.5 10l-2.425 7.5H17.5l6.175 4.4-2.35 7.275 6.175-4.5 6.175 4.5-2.35-7.275 6.175-4.4z"
      className="sale_package_pro_svg__cls-2"
      data-name="Path 876"
    />
  </svg>
);

export default SvgSalePackagePro;
