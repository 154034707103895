import vkeyColorField from "../components/theme-fields/vkeyColorField";

export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: 'sans-serif',
    },
    font: {
        default: 'sans-serif'
    }
};

export const lightTheme = {
    root: {
        marginTop: "30px"
    },

    palette: {
        secondary: {
            light: '#5f5fc4',
            main: 'rgba(178, 97, 226, 1)',
            dark: '#001064',
            contrastText: '#fff',
        },
        success: {
            light: '#5f5fc4',
            main: 'white',
            dark: '#001064',
            contrastText: '#fff',
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: 'sans-serif',
    },
    font: {
        default: 'sans-serif'
    },
    overrides: {
        RaLayout:{
            contentWithSidebar:{
                marginTop: "13px"
            }
        },
        MuiGrid: {
            container: {
                marginTop: 30
            }
        },
        MuiDrawer: {
            docked: {
                backgroundColor: 'rgba(83, 35, 111, 1)',
                minHeight: '100vh',
                boxShadow: 'rgba(0, 0, 0, .2) 5px 0px 10px 0px',
                marginRight: 10,
            }
        },
        MuiFormControl: {
            root: {
                minWidth: '50%'
            }
        },
        FormInput: {
            input: { width: '50%' }
        },
        MuiTableCell: {
            head: {
                padding: '6px 12px 6px 16px !important',
                fontSize: '.9rem'
            },
            paddingNone: {
                padding: '0 12px !important'
            },
            paddingCheckbox: {

            }
        },
        MuiMenuItem: {
            root: {
                // color: 'white'
            }
        },
        MuiListItemText: {
            root: {
                color: 'white'
            },
            primary: {
                color: 'white'
            },
            secondary: {
                color: 'white'
            }
        },
        MuiListItemIcon: {
            root: {
                color: 'white'
            }
        },
        BulkActionsToolbar: {
            toolbarCollapsed: {
                zIndex: 0
            }
        },
        MuiToolbar: {
            dense: {
                minHeight: 58
            },
            gutters: {
                // paddingLeft: '0px !important',
                // paddingRight: '0px !important'
            }
        },
        MuiAppBar: {
            root: {
                // background: 'none !important',
                // boxShadow: 'unset'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'unset'
            }
        },
        // Tooltip override
        MuiTooltip: {
            tooltip: {
                fontSize: '.8rem',
                marginTop: 0,
                top: 0,
            }
        },
        MuiStepLabel: {
            active: {
                color: 'rgb(63, 81, 181) !important'
            },
            label: {
                color: 'white',
                fontSize: '1rem'
            }
        },
        MuiInputLabel: {
            shrink: {
                fontSize: 20,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: 'max-content',
                whiteSpace: 'nowrap'
            }
        },
        MuiSnackbarContent: {
            root: {
                fontSize: '.95rem',
                textAlign: 'center',
                justifyContent: 'center'
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'none'
            }
        },
        RaSidebar:{
            docked:{
                backgroundColor: 'rgba(83, 35, 111, 1)',
            }
        }
    },
    Login: {
        loginContent: {
            backgroundColor: '#2f1f3b80',
            padding: '30px 60px',
            borderRadius: 5,
            textAlign: 'center',
        },
    },
    SignUp: {
        leftPanel: {
            root: {
                minHeight: 300,
                // backgroundColor: 'white',
                marginRight: 20,
                // borderRight: '1px solid black',
                zIndex: 3,
                color: 'white',
                paddingRight: '7em'
            },
            title: {
                fontSize: '1.8rem',
                fontFamily: 'sans-serif',
                fontWeight: 400
            },
            description: {
                marginTop: '2em',
                fontSize: '1.2rem',
                lineHeight: 1.5,
                letterSpacing: '1.1px',
                fontFamily: 'sans-serif'
            },
            formTitle: { marginBottom: 15, marginLeft: 'auto', marginRight: 'auto', fontSize: 22.75, color: '#33263b' },
            formControl: {
                flexGrow: 1,
                flex: 1,
                paddingLeft: '30px !important'
            },
            inputIcon: { color: 'white', width: 24 },
            btnSubmit: {
                textTransform: 'uppercase',
                background: '#5f2685',
                color: '#ffffff',
                borderRadius: 40,
                width: 240,
                marginTop: 30
            },
        },
        validationError: {
            color: 'red',
            fontSize: '.8em',
            textAlign: 'left',
            fontStyle: 'italic'
        }
    },
    tooltip: {
        fontSize: '13px',
        backgroundColor: 'white !important',
        color: '#545b64 !important',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        maxWidth: 500,
        padding: 10
    },
    vkey: {
        halfWidth: {
            width: '50%'
        },
        fullWidth: {
            width: '100%'
        },
        hoverOpacity: {
            opacity: 0.3,
            transition: 'opacity .6s'
        },
        backgroundColor1: '#5C4477', // header color
        backgroundColor2: '#37003c',
        backgroundColor3: '#ffae00',
        backgroundColor4: '#9b62be',
        backgroundColor5: '#5c2e78',
        backgroundColor6: '#3fdcb1',
        backgroundColor7: '#ffae00',
        backgroundColor8: '#fff', //'#E5CDCE', // Internal background color body part
        backgroundColor9: '#2d1541', // Sidebar parent background color
        backgroundColor10: '#3eddb1',
        backgroundColor11: '#fff',
        backgroundColor12: '#2C1541',
        borderColor1: '#3fdcb1',
        borderColor2: '#4e2768',
        borderColor3: '#574467',
        borderColor4: '#5f3363',
        borderColor5: '#4d3a5d',
        borderColor6: '#5c2e78',
        borderColor7: '#9fedd8',
        borderColor8: '#555',
        borderColor9: '#4c3f59',
        borderColor10: '#ccc',
        borderColor11: '#975FB9',
        borderColor12: '#7e7e7e',
        borderColor13: '#fff',
        textColor1: '#a295a3', // Disabled text color
        textColor2: '#ffae00',
        textColor3: '#eb9f09',
        textColor4: '#9b62be', // Color for sidebar unselected, icon table color
        textColor5: '#aca7b1',
        textColor6: '#6b6075',  // Footer text color
        textColor7: '#e0dee2',
        textColor8: '#3fdcb1',
        textColor9: '#333', // Item body table color, hint select field
        textColor10: '#000',
        textColor11: '#5c2e78',
        textColor12: '#4dceff',
        textColor13: '#a5004e',
        textColor14: '#fff', // Breadscrumb color
        textColor15: '#260d28',
        textColor16: '#E75411',
        textColor17: '#027554',
        textColor18: '#999',
        textColor19: '#7e7e7e',
        textColor20: '#a11',
        textColor21: '#faa',
        userProfileColor: '#260d28', // User Profile color
        fontLight: 'Montserrat-Light',
        fontRegular: 'Montserrat-Regular',
        fontBold: 'Montserrat-Bold',
        fontBlack: 'Montserrat-Black',
        fontSemiBold: 'Montserrat-SemiBold',
        titleProfileColor: '#7B4B90', // Purple color,
        buttonProfileColor: '#328979',
        buttonSubmitColor: '#B47D34',
        inputProfileBackgroundColor: '#251134',
        titleColor: '#E79C04',
        chipColor: '#5C2E77',
        chipColorSelected: '#4A148C',
        chipColorUnselected: 'rgb(70, 49, 87)',
        chipText: '#56B1B2',
        groupDialogColor: '#311842',
        statusSecondary: 'rgba(63, 220, 177, 0.26)',
        gotoPageInput: '#7B4B90', // Purple color,
        labelSelectField: '#999',
        tableMaxHeight: '500px',
        trashIcon: 'red',
        errorColor: 'red',
        purpleTitle: '#975FB9',
        tableActionIcon: '#975FB9',
        borderInputColor: '#555',
        buttonActionColor: '#7e7e7e',
        buttonActionBorderColor: 'rgb(126, 126, 126)',
        dialogMessageColor: '#fff',
        disabled: '#240F36',
        textError: '#ab115a',
        snackBarBackground: '#2d1541'
    },
    button: {
        borderColor: '#ccc',
        textColor: '#fff',
        textDisabledColor: '#555'
    },
    card: {
        cardColor: '#F2F2F2', //  '#2C1541', // Card background internal color,
        cardDivider: '#7E7980' // Gray color
    },
    orginalCard: {
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    }
};

