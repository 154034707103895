import React from "react";

const SvgTokenPack = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="token_pack_svg__clip-path">
        <path
          id="token_pack_svg__Rectangle_1612"
          d="M0 0h24v24H0z"
          data-name="Rectangle 1612"
          transform="translate(411 738)"
          stroke="#707070"
          fill="currentColor"
        />
      </clipPath>
      <style>{".token_pack_svg__cls-3{fill:currentColor}"}</style>
    </defs>
    <g
      id="token_pack_svg__Mask_Group_38"
      data-name="Mask Group 38"
      transform="translate(-411 -738)"
      clipPath="url(#token_pack_svg__clip-path)"
    >
      <g id="token_pack_svg__tokenpack" transform="translate(411 741.266)">
        <g id="token_pack_svg__Layer_2" data-name="Layer 2">
          <g id="token_pack_svg__tokenpack-2" data-name="tokenpack">
            <path
              id="token_pack_svg__Path_791"
              d="M19.1 6V1.612A1.467 1.467 0 0017.864 0H1.233A1.466 1.466 0 000 1.612V6c1.157 0 2.1 1.222 2.1 2.733S1.157 11.47 0 11.47v4.385a1.467 1.467 0 001.233 1.612h16.631a1.468 1.468 0 001.236-1.612V11.47c-1.159 0-2.1-1.225-2.1-2.736S17.94 6 19.1 6z"
              className="token_pack_svg__cls-3"
              data-name="Path 791"
            />
            <path
              id="token_pack_svg__Path_792"
              d="M32.52 6V1.612A1.487 1.487 0 0031.211 0h-2.486a1.882 1.882 0 01.446 1.243v5.366a2.126 2.126 0 000 4.25v5.36a1.888 1.888 0 01-.446 1.245h2.486a1.489 1.489 0 001.309-1.612V11.47a2.521 2.521 0 01-2.22-2.735A2.523 2.523 0 0132.52 6z"
              className="token_pack_svg__cls-3"
              data-name="Path 792"
              transform="translate(-8.52)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgTokenPack;
