import {createTheme, MuiThemeProvider, Tooltip} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Children, cloneElement } from 'react';
import ReactTooltip from 'react-tooltip'
import IconInfo from '@material-ui/icons/Info'
import FaInfoIcon from '../Icons/InfoCircleSolid'
import {compose} from "recompose";
import {connect} from "react-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {lightTheme} from "../layout/themes";
import { translate } from 'react-admin'

const styles = theme => ({
    halfWidth: theme.vkey.halfWidth,
    fullWidth: {
        width: '100%'
    },
    tooltip: theme.tooltip,
    default: {
        [theme.breakpoints.down('xs')]: {
        },
        marginLeft:"20px"
    }
})

const TooltipWrapper = withStyles(styles)(({ classes, children, ...props }) => (
    <div className={classes.default}>
    {Children.map(children, input => {

        if (input == null) return input
        const title = (!!input && !!input.props) ? input.props.title : null
        const required = (!!input && !!input.props) ? input.props.required : false

        let isHalfWidth = false // (!!input && !!input.props && !input.props.fullWidth)
        let className = isHalfWidth ? classes.halfWidth : classes.fullWidth

        let labelWidth = -1
        let { label, width, fontSize, pStyle } = input.props
        // log.debug("pStyle: ", pStyle)
        if (!label || label === '')
            label = props.label
        
        if (typeof width == 'number') {
            width = `${width}%`
        }

        if (label && label.width) {
            labelWidth = label.width(fontSize ? fontSize : 25, pStyle) //Calculator with in fontSize 20
            if (required)
                labelWidth += 1
            // // log.debug("input width: ", input)
        }

        if (title || required) {
            let newProps = { ...props, label: required ? `${label} ` : label, className: classes.fullWidth, title: "", InputLabelProps: {shrink: true} }
            // newProps['data-tip'] = title
            let labelStyle = labelWidth > -1 ? {
                position: 'absolute',
                left: labelWidth + 5,
                top: 15,
            } : {}

            return (
                <div style={{ ...input.props.style, width: width ? width : "100%",
                    display: 'inline-flex', position: 'relative', marginRight: width ? 15 : 'auto' }}>
                     {/*<Tooltip title={title} disableTouchListener={true} disableFocusListener={true} className={classes.tooltip}>*/}
                    <div  className={className} style={{ width: (width || props.width === 'xs' || props.width === 'sm') ? "100%" : `60%` }}>
                        { cloneElement(input, newProps) }
                    </div>
                    {title && <span data-tip={title} style={labelStyle}>
                        <FaInfoIcon style={{color: 'gray', width: 12, height: 14, opacity: .4}}/>
                    </span>
                    }
                     {/*</Tooltip>*/}
                </div>
            )
        }
        return (
            <div style={{...input.props.style, width: width ? width : "100%", display: 'inline-flex' }}>
                 <div /* className={className} */ style={{ width: (width || props.width === 'xs' || props.width === 'sm') ? "100%" : `60%` }}>
                    {cloneElement(input, { ...props, className: className, title: "", InputLabelProps: {shrink: true} })}
                </div>
            </div>
        )
    })}
        <div ><ReactTooltip place="right" delayShow={100} className={classes.tooltip} html={true} /></div>
    </div>
));

const mapStateToProps = state => ({

})

const enhance = compose(
    translate,
    connect(
        mapStateToProps,
        {
        }
    ),
    withStyles(styles),
    withWidth()
);

const EnhancedTooltipWrapper = enhance(TooltipWrapper);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const TooltipWrapperWithTheme = props => (
    <MuiThemeProvider theme={createTheme(lightTheme)}>
        <EnhancedTooltipWrapper {...props} />
    </MuiThemeProvider>
);

export default TooltipWrapperWithTheme;
