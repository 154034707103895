import log from "./log";

export const capitalizeFirstLetter = str => {
	if (!str || typeof str !== 'string') {
		log.warn('capitalizeFirstLetter exception ', typeof str, str)
		return
	}
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const pascalToUnderscore = str => {
	return str.replace(/\.?([A-Z])/g, function (x, y) {
		return "_" + y.toLowerCase()
	}).replace(/^_/, "")
}
export const slugify = text => {
	return text
		.toString()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.toLowerCase()
		.trim()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '')
		.replace(/--+/g, '-')
}
export const uniqueName = text => {
	text = text
		.toString()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.toLowerCase()
		.trim()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '')
		.replace(/--+/g, '-')

	return [text, randomString(8)].join('-')
}

export const validURL = str => {
	var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return !!pattern.test(str);
}

export const trim = text => {
	return text.replace(' ', '').trim()
}
export const prefixBrand = brand => {
	switch (brand.toLowerCase()) {
		case 'jcb':
			return 3566
		case 'amex':
			return 3782
		case 'dinersclub':
			return 3056
		case 'visa':
			return 4242
		case 'mastercard':
			return 5555
		case 'maestro':
			return 5666
		case 'discover':
			return 6011
		case 'unionpay':
			return 6200
		default:
			return 4242
	}
}
export const brandToRegex = brand => {
	log.debug("thisbrand: ", brand)
	brand = brand.toLowerCase();
	switch (brand) {
		case 'jcb':
			return '^(?:2131|1800|35)[0-9]{0,}$'
		case 'amex':
			return '^3[47][0-9]{0,}$'
		case 'diners':
			return '^3(?:0[0-59]{1}|[689])[0-9]{0,}$'
		case 'visa':
			return '^4[0-9]{0,}$'
		case 'mastercard':
			return '^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$'
		case 'maestro':
			return '^(5[06789]|6)[0-9]{0,}$'
		case 'discover':
			return '^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$'
		default:
			return '^4[0-9]{0,}$'
	}

}

export function generatePassword() {
	var length = 8,
		charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()",
		retVal = "";
	for (var i = 0, n = charset.length; i < length; ++i) {
		retVal += charset.charAt(Math.floor(Math.random() * n));
	}
	return retVal;
}

export function randomString(len = 5) {
	var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
		retVal = "";
	for (var i = 0, n = charset.length; i < len; ++i) {
		retVal += charset.charAt(Math.floor(Math.random() * n));
	}
	return retVal;
}
