import {stringify} from "query-string";
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY
} from "react-admin";

import LocalStorage from '../utils/services/storage';
import log from '../utils/log';

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&pageSize=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson, customRequestConvert, customResponseConvert = undefined) => {
  /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
  const convertDataRequestToHTTP = (type, resource, params) => {

    let customerId = LocalStorage.instance.getCustomerId()

    let converted = customRequestConvert(type, resource, params)
    let {url, options} = converted
    if (url && url != "") {
      // was converted 
      // log.debug('base spring data provider, action was overrided, ', url, options)
    }

    else switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            // sort: JSON.stringify([field, order]), // Currently disable sort
            // pagination: JSON.stringify({ page: `${page}` }),
            ...params.filter,
            page: page - 1,
            pageSize: perPage
            // filter: JSON.stringify(convertUserSearchFilter(resource, params.filter)),
        };
        url = `${apiUrl}/${resource}/${customerId}/search?${stringify(query)}`;
        // url = `${apiUrl}/${resource}/${customerId}/search?page=${page}&pageSize=${perPage}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${customerId}/${params.id}`;
        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ id: params.ids })
        };
        let idStr = "";
        const queryString = params.ids.map(id => idStr + `id=${id}`);
        url = `${apiUrl}/${resource}?${idStr}}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        url = `${apiUrl}/${resource}?page=${page}&pageSize=${perPage}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${customerId}/${params.id}`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}/${customerId}`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      case DELETE_MANY: 
        // log.debug('Delete many with params: ', params)
        url = `${apiUrl}/${resource}/${customerId}/delete-many`
        options.method = "DELETE"
        options.body = JSON.stringify({ ids: params.ids })
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
        
    }
    return { url, options };
  };

  /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response;

    let customRsp;
    if (customResponseConvert) {
      customRsp = customResponseConvert(response, type, resource, params);
    }

    if (customRsp) {
      // log.debug('--overried response--', customRsp);
      return customRsp;
    } else switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!json.data.hasOwnProperty("totalElements")) {
          throw new Error(
            "The numberOfElements property must be must be present in the Json response"
          );
        }
        return {
          data: json.data.content,
          total: parseInt(json.data.totalElements, 10)
        };
      case CREATE:
        return { data: { ...params.data, id: json.data.id } };

      default:
        return { data: json.data };
    }
  };

  /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    /*
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    */

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params)
    ).catch(e => {
      
      log.error("rest-api", resource, type, e);
      const errorJson = e.body;
      if (errorJson && errorJson.messageKey)
        throw new Error(errorJson.messageKey);

      else
        throw new Error("error.default.internal.server.error");

    });
  };
};
