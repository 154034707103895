import baseSpringDataProvider from '../../dataProvider/baseSpringDataProvider'
import {
    fetchUtils,
    GET_LIST,
    UPDATE,
    CREATE,
    GET_ONE,
    DELETE,
    DELETE_MANY
} from 'react-admin'
import { stringify } from "query-string";
import LocalStorage from '../../utils/services/storage';

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {

    const convertUserSearchFilter = (resource, payload) => {

    }

    return baseSpringDataProvider(apiUrl, httpClient, (type, resource, params) => {
        let customerId = LocalStorage.instance.getCustomerId()
        // log.debug('User dataProvider custom action type: ', type, params)
        // log.debug('Debug current token pack id ', LocalStorage.instance.getTokenPackId())
        let result = {
            url: "",
            options: {

            }
        }

        switch (type) {
            case GET_LIST:
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {
                    // sort: JSON.stringify([field, order]), // Currently disable sort
                    // pagination: JSON.stringify({ page: `${page}` }),
                    ...params.filter,
                    page: page - 1,
                    pageSize: perPage
                    // filter: JSON.stringify(convertUserSearchFilter(resource, params.filter)),
                };
                result.url = `${apiUrl}/${resource}/${customerId}/${LocalStorage.instance.getTokenPackId()}/search?${stringify(query)}`;
                // url = `${apiUrl}/${resource}/${customerId}/search?page=${page}&pageSize=${perPage}`;
                break;

            case UPDATE:
                result.url = `${apiUrl}/${resource}/${customerId}/${LocalStorage.instance.getTokenPackId()}/${params.id}`;
                result.options.method = "PUT";
                result.options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                result.url = `${apiUrl}/${resource}/${customerId}/${LocalStorage.instance.getTokenPackId()}`;
                result.options.method = "POST";
                result.options.body = JSON.stringify(params.data);
                break;

            case GET_ONE:
                result.url = `${apiUrl}/${resource}/${customerId}/${LocalStorage.instance.getTokenPackId()}/${params.id}`;
                break;

            case DELETE:
                result.url = `${apiUrl}/${resource}/${customerId}/${LocalStorage.instance.getTokenPackId()}/delete-many`;
                result.options.method = "DELETE";
                result.options.body = JSON.stringify({ ids: [params.id] })
                break;

            case DELETE_MANY:
                // log.debug('Delete many with params: ', params)
                result.url = `${apiUrl}/${resource}/${customerId}/${LocalStorage.instance.getTokenPackId()}/delete-many`
                result.options.method = "DELETE"
                result.options.body = JSON.stringify({ ids: params.ids })
                break;
            default:
                // log.debug('Use baseSpringDataProvider ')
        }

        return result
    })

}