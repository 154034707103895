export const UPDATE_LOADING = 'component/multipleUserSelection/loading/UPDATE'

export const UPDATE_USER_LIST = 'component/multipleUserSelection/userList/UPDATE'

const defaultState = {
    userList: [],
    loading: false,
    mapUsers: {}
}

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_LOADING:
            return {
                ...state,
                loading: action.data
            }

        case UPDATE_USER_LIST:
            const mapUsers = {}
            action.data.forEach(u => mapUsers[u.id] = u)

            return {
                ...state,
                userList: action.data,
                mapUsers
            }

        default:
            return state
    }
}

export default reducer