import React from "react";

const SvgMenuUsers = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="menu_users_svg__clip-path">
        <path
          id="menu_users_svg__Rectangle_1612"
          d="M0 0h24v24H0z"
          data-name="Rectangle 1612"
          transform="translate(411 810)"
          stroke="#707070"
          fill="#fff"
        />
      </clipPath>
      <style>{".menu_users_svg__cls-3{fill:#fff}"}</style>
    </defs>
    <g
      id="menu_users_svg__Mask_Group_39"
      data-name="Mask Group 39"
      transform="translate(-411 -810)"
      clipPath="url(#menu_users_svg__clip-path)"
    >
      <g id="menu_users_svg__users" transform="translate(411 814.545)">
        <g id="menu_users_svg__Layer_2" data-name="Layer 2">
          <g id="menu_users_svg__users-2" data-name="users">
            <circle
              id="menu_users_svg__Ellipse_335"
              cx={3.568}
              cy={3.568}
              r={3.568}
              className="menu_users_svg__cls-3"
              data-name="Ellipse 335"
              transform="translate(5.75)"
            />
            <circle
              id="menu_users_svg__Ellipse_336"
              cx={2.555}
              cy={2.555}
              r={2.555}
              className="menu_users_svg__cls-3"
              data-name="Ellipse 336"
              transform="translate(14.697 1.542)"
            />
            <path
              id="menu_users_svg__Path_793"
              d="M9.318 13.006A10.25 10.25 0 000 19h18.637a10.255 10.255 0 00-9.32-5.991zm6.025 4.732H3.027v-1.153h12.315z"
              className="menu_users_svg__cls-3"
              data-name="Path 793"
              transform="translate(0 -4.087)"
            />
            <path
              id="menu_users_svg__Path_794"
              d="M23.723 11.976a7.272 7.272 0 00-3.388.835 10.243 10.243 0 013.073 1.863h4.476v.536h-3.92a10.229 10.229 0 01.911 1.059h5.516a7.335 7.335 0 00-6.668-4.293z"
              className="menu_users_svg__cls-3"
              data-name="Path 794"
              transform="translate(-6.391 -3.764)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMenuUsers;
