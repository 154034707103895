import { fetchUtils, useLogout } from 'react-admin';
import LocalStorage, {SCOPE_CUSTOMER, SCOPE_VKEY} from '../utils/services/storage';
import { PUBLIC_APIS } from './constants'
import constants from "../constants";
import authProvider from "../authProvider";
const httpClient = (url, options = {}) => {

    let token = LocalStorage.instance.getToken();
    /*if (LocalStorage.instance.getCustomerAccessToken() != null && LocalStorage.instance.getOriginalScope() === SCOPE_VKEY && LocalStorage.instance.getCurrentScope() === SCOPE_CUSTOMER) {
        console.log("Using customer access token")
        token = LocalStorage.instance.getCustomerAccessToken()
    }
    if (LocalStorage.instance.getCustomerAccessToken() && (LocalStorage.instance.getOriginalScope() === SCOPE_VKEY && LocalStorage.instance.getCurrentScope() === SCOPE_VKEY)) {
        LocalStorage.instance.setCustomerAccessToken(null)
    }*/
    if (!token) {
        const API_URL = constants.API_URL;
        const path = url.replace(API_URL, '')
        let isPublicAPI = false;
        for (let i=0; i<PUBLIC_APIS.length; i++) {
            const api = PUBLIC_APIS[i]
            if (path.startsWith(api))
            {
                isPublicAPI = true
                break
            }
        }
        if (!isPublicAPI) {
            authProvider.logout()
        }
    }

    if (!options.headers) {
        options.headers = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Sec-Fetch-Site': 'none',
            // 'Access-Control-Expose-Headers': 'Content-Range',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS',
            'Authorization': 'Bearer ' + token
        });
    }

    // options.headers.set('Access-Control-Expose-Headers', 'Content-Range');
    // options.headers.set('Sec-Fetch-Site', 'none');

    // add your own headers here
    return fetchUtils.fetchJson(url, options);
}
export const API_URL = constants.API_URL
export default httpClient;
