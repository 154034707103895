import React, {forwardRef, useImperativeHandle} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export type EmailFormDialogRef = {
    show: (email: string) => void
} | null;
type EmailFormDialogProps = {
    okCallback: (email: string) => void
}

const EmailFormDialog = forwardRef<EmailFormDialogRef, EmailFormDialogProps>(({ okCallback }, ref) => {

    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [error, setError] = React.useState({ validate: true,  message: ""})
    const emailInputRef = React.useRef<HTMLInputElement>()

    useImperativeHandle(ref, () => ({
        show(mail: string) {
            setEmail(mail)
            setOpen(true)
        }
    }));

    const close = () => setOpen(false)

    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Send activation code via email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To send activation code to your user, please enter his/her email address here.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="dialog-email-input"
                        label="Email Address"
                        type="email"
                        defaultValue={email}
                        fullWidth
                        inputRef={emailInputRef}
                        error={!error.validate}
                        helperText={error.message}
                        onChange={() => setError({ validate: true,  message: ""})}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="default">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        const value = emailInputRef?.current?.value
                        if (value && value !== '') {
                            okCallback(value);
                            close()
                        } else {
                            setError({ validate: false,  message: "Email must not be empty."})
                        }

                    }} color="primary">
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default EmailFormDialog