import {Button as MuiButton} from "@material-ui/core";
import AutoRenewIcon from "@material-ui/icons/Autorenew";
import GetAppIcon from '@material-ui/icons/GetApp';
import React, {cloneElement, createRef, useEffect} from 'react';
import {Button, sanitizeListRestProps, TopToolbar, useListContext, useMutation, useRefresh} from 'react-admin';
import {useParams} from 'react-router-dom';
import {ACTION_DOWNLOAD_ZIP, ACTION_GENERATE, ACTION_RE_GENERATE} from '../../dataProvider/activationCodeDataProvider';
import log from '../../utils/log';
import ConfirmDialog, {ConfirmDialogRef} from "./ConfirmDialog";
import {downloadFileWithAuth} from './ListActivationCode';

const ACListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        callback,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();

    const {tokenPackId} = useParams<{ tokenPackId: string }>();

    const refresh = useRefresh()

    const [generateActivationCode, {loading, loaded}] = useMutation({
        type: total === 0 ? ACTION_GENERATE : ACTION_RE_GENERATE,
        resource: 'activation-code',
        payload: {id: tokenPackId, data: {all: true, tokenPackId}}
    });

    const [downloadZip, downloadZipState] = useMutation({
        type: ACTION_DOWNLOAD_ZIP,
        resource: 'activation-code',
        payload: {data: {all: true, tokenPackId}}
    }, {
        onSuccess: ({data}) => {
            log.info("Done", data)
            // notify(`Zip ${selectedIds.length} activation codes`)
            // unselectAll('activation-code')
            refresh()
            downloadFileWithAuth(tokenPackId, data.fileName)
        }
    })

    useEffect(() => {
        log.info("generateActivationCode trigger")
        if (!loading && loaded) refresh()
    }, [loading, loaded])

    useEffect(() => {
        callback(loading || downloadZipState.loading)
    }, [loading, downloadZipState.loading])

    const confirmDialogRef = createRef<ConfirmDialogRef>()

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button
                disabled={loading}
                onClick={() => {
                    if (total === 0) {
                        generateActivationCode();
                    } else {
                        confirmDialogRef?.current?.setContent(
                            "Re-generate all activation codes?",
                            "All current activation codes will be unavailable."
                        )
                        confirmDialogRef?.current?.show()
                    }
                }}
                label={total > 0 ? "Re-generate all" : "Generate"}
            >
                <AutoRenewIcon/>
            </Button>
            <MuiButton
                color="default"
                variant="text"
                onClick={downloadZip}
                startIcon={<GetAppIcon/>}>
                Download All
            </MuiButton>
            <ConfirmDialog ref={confirmDialogRef} okCallback={() => {
                generateActivationCode()
            }}/>
        </TopToolbar>
    );
};

export default ACListActions