import React from "react";

const SvgOpenid24Px = props => (
  <svg viewBox="0 0 48 48" width={24} height={24} {...props}>
    <path
      fill="#9E9E9E"
      d="M44 27l-1-9-2.9 1.9c-2.7-1.7-6.1-2.9-9.9-3.5 0 0-1.9-.4-4.4-.4s-4.8.3-4.8.3C11.3 17.5 4 23 4 29.6 4 36.4 11.5 42 23 43v-3.9c-7.9-1.1-12.9-4.8-12.9-9.5 0-4.4 4.6-8.1 10.9-9.3 0 0 4.9-1.1 9.2.2 2.1.5 4 1.2 5.6 2.2L32 25l12 2z"
    />
    <path d="M23 8v35l6-3V5z" />
    <path fill="#FF9800" d="M23 8v35l6-3V5z" />
  </svg>
);

export default SvgOpenid24Px;
