const memStorage = {
    set,
    get,
    getAll,
    remove,
    clearAll,
  }
  export default memStorage

interface IHash {
    [details: string] : any;
} 

let storage : IHash = {}
  
function set(key:string, value: any) {
    storage[key] = value
}

function get(key: string) {
return storage[key]
}

function getAll() {
return storage.getAll
}

function remove(key: string) {
delete storage[key]
}

function clearAll() {
storage = {}
}
