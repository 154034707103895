import { GET_ONE } from 'react-admin';
import dataProvider, { BASE_URL } from '../../../dataProvider';
import httpClient from '../../../dataProvider/httpClient';
import log from '../../../utils/log';
import LocalStorage from "../../../utils/services/storage";
import { WIZARD_STATUS_NEW } from '../constants';

export const loadTokenPackInitialData = async (payload) => {
    // Get data token-pack for step 3
    let rsp = await dataProvider(GET_ONE, 'token-pack', { id: payload.tokenPackId })
    log.info('Start for token pack ', payload.tokenPackId, rsp)
    return rsp.data
}

export const getLatestTPNew = async (payload) => {
    // Get newest token pack wizard = NEW
    const { json: { data } } = await httpClient(`${BASE_URL}/idm-config/token-pack/${LocalStorage.instance.getCustomerId()}/get-by-wizard-status/${WIZARD_STATUS_NEW}`)
    if (data.length > 0)
        return data[0]

    return {}
}