import { post } from "axios";
import {
    CREATE, DELETE,
    DELETE_MANY, fetchUtils,
    GET_LIST, GET_MANY,
    GET_MANY_REFERENCE, GET_ONE, UPDATE,
    UPDATE_MANY
} from "react-admin";
import LocalStorage from '../../utils/services/storage';
import log from "../../utils/log";

//export const DELETE_THEME_MANY = 'DELETE_THEME'
export const GET_DEFAULT = 'GET_DEFAULT';
export const APPROVE_THEME = 'APPROVE_THEME';
export const REJECT_THEME = 'REJECT_THEME';

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
       * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
       * @param {String} resource Name of the resource to fetch, e.g. 'posts'
       * @param {Object} params The data request params, depending on the type
       * @returns {Object} { url, options } The HTTP request parameters
       */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = "";
        const options = {};
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const payload = {
                    page: page - 1,
                    limit: perPage,
                    ...params.filter
                };

                const customerId = LocalStorage.instance.getCustomerId();
                if (customerId && customerId.length > 0) {
                    url = `${apiUrl}/${resource}/${customerId}/search`;
                } else {
                    url = `${apiUrl}/${resource}/search`;
                }

                options.method = "POST";
                options.body = JSON.stringify(payload);
                break;
            }
            case GET_ONE:
                const payload = {
                    id: params.id
                };
                url = `${apiUrl}/${resource}/detail`;

                options.method = "POST";
                options.body = JSON.stringify(payload);
                break;
            case GET_MANY: {
                const query = {
                    filter: JSON.stringify({ id: params.ids })
                };
                let idStr = "";
                const queryString = params.ids.map(id => idStr + `id=${id}`);
                url = `${apiUrl}/${resource}?${idStr}}`;
                url = `${apiUrl}/${resource}/details`;
                options.body = JSON.stringify(params.ids);
                options.method = "POST";
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const payload = {
                    page: page - 1,
                    limit: perPage,
                    [params.target]: params.id
                };
                url = `${apiUrl}/${resource}/search`;
                options.method = "POST";
                options.body = JSON.stringify(payload);
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/update`;
                options.method = "PUT";
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${apiUrl}/${resource}/create`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;
            case GET_DEFAULT:
                url = `${apiUrl}/${resource}/default`;
                options.method = 'GET';
                break;
            case REJECT_THEME:
                url = `${apiUrl}/${resource}/reject`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;

            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
       * @param {Object} response HTTP response from fetch()
       * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
       * @param {String} resource Name of the resource to fetch, e.g. 'posts'
       * @param {Object} params The data request params, depending on the type
       * @returns {Object} Data response
       */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { headers, json } = response;
        switch (type) {
            case GET_MANY_REFERENCE:
            case GET_LIST:
                if (!json.hasOwnProperty("totalElements")) {
                    throw new Error(
                        "The numberOfElements property must be must be present in the Json response"
                    );
                }
                return {
                    data: json.contents,
                    total: parseInt(json.totalElements, 10)
                };
            case CREATE:
                return { data: { ...params.data, id: json.id } };
            // return { data: { ...json } };
            default:
                log.debug("type; ", type)
                log.debug(' theme resp', json);
                return { data: json };
        }
    };

    /**
       * @param {string} type Request type, e.g GET_LIST
       * @param {string} resource Resource name, e.g. "posts"
       * @param {Object} payload Request parameters. Depends on the request type
       * @returns {Promise} the Promise for a data response
       */
    const requestHandler = (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead

        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: "PUT",
                    body: JSON.stringify(params.data)
                })
                )
            ).then(responses => ({
                data: responses.map(response => response.json)
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id => {

                    let body = {};
                    body.themeId = id;
                    body.customerId = params.customerId;

                    // check in local storage
                    const customerId = LocalStorage.instance.getCustomerId();
                    if (body.customerId == undefined || body.customerId == null) {
                        body.customerId = customerId;
                    }

                    let rsp = httpClient(`${apiUrl}/${resource}/delete`, {
                        method: "DELETE",
                        body: JSON.stringify(body)
                    });

                    // // log.debug('resp');
                    // // log.debug(rsp);

                    return rsp;

                })
            ).then(responses => {
                //// log.debug('delete theme resp');
                //// log.debug(responses);

                return { data: responses.map(response => response.json) }
            });
        }

        const { url, options } = convertDataRequestToHTTP(type, resource, params);
        return httpClient(url, options)
            .then(response => convertHTTPResponse(response, type, resource, params))
            .catch(e => {
                const errorJson = e.body;
                log.debug("API Request error: ")
                log.debug(errorJson)
                log.debug(type)
                log.debug(resource)
                log.debug(params)
                if (errorJson && errorJson.messageKey)
                    throw new Error(errorJson.messageKey);

                else
                    throw new Error("error.default.internal.server.error");

            });
    };

    const addUploadFeature = (type, resource, params) => {
        if (type === 'CREATE' || type === 'UPDATE') {

            // if (params.data.iconUrl) {
            //   // only freshly dropped pictures are instance of File
            //   ////// // log.debug(params.data.iconUrl);
            //   ////// // log.debug('1');

            //   return convertFileToBase64(params.data.iconUrl)
            //     .then(base64 => {
            //       ////// // log.debug(base64);
            //       return requestHandler(type, resource, {
            //         ...params,
            //         data: {
            //           ...params.data,
            //           iconUrl: base64,
            //         }
            //       });
            //     });

            // } else {
            //   return requestHandler(type, resource, params);
            // }

            //// // log.debug(999)

            let url = '';
            if (type === 'UPDATE') {
                url = `${apiUrl}/template/` + params.data.id + `/update`;
            } else {
                url = `${apiUrl}/template/create`;
            }

            if (url.indexOf('/service-mgmt/') !== -1) {
                url = url.replace(new RegExp('/service-mgmt/', 'g'), '/zuul/service-mgmt/')
            }

            const formData = new FormData();
            //Object.keys(params.data).forEach(k => {
            //  formData.append(k, params.data[k]);
            //})

            let entity = { ...params.data };
            // remove iconUrl on create mode
            if (type === 'CREATE') {
                delete entity.iconUrl;
            }

            formData.append('json', JSON.stringify(entity));

            if (params.data.iconUrl && params.data.iconUrl.rawFile) {
                formData.append('iconFile', params.data.iconUrl.rawFile)
            }

            if (type === 'UPDATE' && params.data.iconUrlTemp && params.data.iconUrlTemp.rawFile) {
                formData.append('iconFile', params.data.iconUrlTemp.rawFile)
            }

            //formData.append('bannerfile', params.data.iconUrl.rawFile)
            //// // log.debug(params.data.iconUrl.rawFile)
            let token = LocalStorage.instance.getToken();

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Transfer-Encoding': 'chunked',
                    'Authorization': 'Bearer ' + token
                }
            }

            return post(url, formData, config)
                .then(rsp => {
                    return { data: rsp.data, id: rsp.data.id }
                }).catch(error => {

                    let response = error.response;
                    let errorMessage = {};
                    if (response) {
                        errorMessage = response.data;
                    }

                    throw new Error(
                        errorMessage.message
                    );

                });

        } else {
            // for other request types and resources, fall back to the default request handler
            return requestHandler(type, resource, params);
        }

    };

    return requestHandler;

};