import React, {useEffect} from "react";

const Scheme = (props) => {

    const submitRequest = (buttonId) => {
        let d = (window.parent)?window.parent.document:window.document
        if (d.getElementById(buttonId) == null || d.getElementById(buttonId) == undefined) return;
        if (d.getElementById(buttonId)?.dispatchEvent) {
            let e = d.createEvent("MouseEvents");
            e.initEvent("click", true, true);
            d.getElementById(buttonId)?.dispatchEvent(e);
        }
        else {
            d.getElementById(buttonId)?.click();
        }
    }

    const exec = () => {
        let d = (window.parent)?window.parent.document:window.document
        let f = d.getElementById('customUrlLink')
        if (f ) {f.parentNode?.removeChild(f);}
        let a = d.createElement('a');
        a.href =  'vkey://onboarding?data=eyJ0eXBlIjoiVktFWV9TRUFNTEVTU19RUkNPREUiLCJkYXRhIjp7InNlc3Npb25JZCI6Inh4eHh4eHh4eCIsInR5cGUiOiJBVVRIIn19';
        a.innerHTML = "Link"
        a.setAttribute('id',        'customUrlLink');
        a.setAttribute("style", "display:none; ");
        d.body.appendChild(a);
        submitRequest("customUrlLink");
    }

    useEffect(() => {
        exec();
    }, [])

    return (
        <input type='button' value='Test Custom Url' onClick={() => exec()} />
    )
}

export default Scheme