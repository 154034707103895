import React from "react";

const SvgMenuAccounts = props => (
  <svg width={24} height={24} {...props}>
    <defs>
      <clipPath id="menu_accounts_svg__clip-path">
        <path
          id="menu_accounts_svg__Rectangle_1612"
          d="M0 0h24v24H0z"
          data-name="Rectangle 1612"
          transform="translate(411 882)"
          stroke="#707070"
          fill="#fff"
        />
      </clipPath>
      <style>{".menu_accounts_svg__cls-3{fill:#fff}"}</style>
    </defs>
    <g
      id="menu_accounts_svg__Mask_Group_40"
      data-name="Mask Group 40"
      transform="translate(-411 -882)"
      clipPath="url(#menu_accounts_svg__clip-path)"
    >
      <g id="menu_accounts_svg__accountss" transform="translate(413.426 882)">
        <g id="menu_accounts_svg__Layer_2" data-name="Layer 2">
          <g id="menu_accounts_svg__accounts">
            <path
              id="menu_accounts_svg__Path_795"
              d="M4.589 23.489A3.086 3.086 0 011.5 20.4V4.348A2.389 2.389 0 000 6.565v16.412a2.389 2.389 0 002.393 2.389h11.823a2.393 2.393 0 002.337-1.878z"
              className="menu_accounts_svg__cls-3"
              data-name="Path 795"
              transform="translate(0 -1.366)"
            />
            <path
              id="menu_accounts_svg__Path_796"
              d="M17.92 0H6.1a2.393 2.393 0 00-2.4 2.393V18.8a2.393 2.393 0 002.4 2.394h11.82a2.393 2.393 0 002.392-2.394V2.393A2.393 2.393 0 0017.92 0zm-5.767 3.808a3.209 3.209 0 11-3.205 3.2 3.209 3.209 0 013.205-3.2zm4.554 13.736h-9.09v-1.486a4.992 4.992 0 019.077 0z"
              className="menu_accounts_svg__cls-3"
              data-name="Path 796"
              transform="translate(-1.164)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgMenuAccounts;
