import React from 'react';
import { TextField } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import {
    colorPicker as colorPickerAction,
    updateText as updateTextAction
} from '../../wla_v2/reducers/themeDetailReducer';

const styles = theme => ({

});

var colorSetTimer;

const renderTextField = ({
    id,
    label,
    screenIds,
    input,
    color,
    updateText,
    colorPicker,
    handleColorChange,
    form,
    meta: { touched, invalid, error },
    ...custom
}) => {
    return (
        <div style={{ display: 'inline-flex', width: '100px'}}>
            <TextField xs={6} md={6}
                sortable={false}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
                type='text'
                onKeyUp={e => {

                    //// log.debug('color text input:', input.value);

                    clearTimeout(colorSetTimer);
                    colorSetTimer = setTimeout(() => colorPicker({
                        value: input.value,
                        id,
                        screenIds,
                        path: input.name
                    }), 500);
                }}
            />
        </div>
    );
};

// export default renderTextField;
const mapStateToProps = state => ({
    theme: state.theme,

});

const enhance = compose(
    connect(
        mapStateToProps,
        {
            updateText: updateTextAction,
            colorPicker: colorPickerAction
        }
    ),
    translate,
    withStyles(styles)
);

export default enhance(renderTextField);
