import React, {Fragment, useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import * as animationData from '../../external/loading.json'
import {makeStyles} from "@material-ui/core/styles";
import lottieWeb from "lottie-web";

const styles = makeStyles({
    waiting: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        top: 0,
        left: 0
    },
    default: {

    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 99,
        background: 'radial-gradient(circle, rgba(218,214,216,1) 0%, rgba(255,255,255,1) 100%)',
        opacity: .3,
    }
})

interface WaitingViewProps {
    loading: boolean,
    waitingMsg?: string
}

const WaitingView:React.FunctionComponent<WaitingViewProps> = (props) => {

    const { loading, children, waitingMsg } = props
    const classes = styles()
    const [anim, setAnim] = useState(null as any)
    const containerRef = useRef()

    useEffect(() => {
        const container = document.getElementById("waiting-view-container");
        if (container != null) {
            setAnim(lottieWeb.loadAnimation({
                container: container, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: (animationData as any).default,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            }))
        }

        return () => {
            if (anim)
                anim.destroy()
        }
    }, [])

    useEffect(() => {
        if (loading && anim != null) {
            anim.play()
        }

        if (!loading && anim != null) {
            anim.pause();
        }
    }, [loading, anim])

    return (
        <Fragment>
            <div className={loading ? classNames(classes.waiting, classes.default) : classes.default}>
                <div style={{ width: '100%', display: loading ? 'flex' : "none", justifyContent: 'center', zIndex: 100}} >
                    {/*<Lottie options={defaultOptions} width={100} height={100} />*/}
                    <div id={"waiting-view-container"} style={{ width: 200, height: 200 }}></div>
                </div>

                { loading && waitingMsg &&
                <div style={{
                    textAlign: "center", marginTop: 5, maxWidth: 400, marginLeft: 'auto', marginRight: 'auto',
                    textShadow: "rgb(95, 61, 148) 2px 2px 2px"
                }}>{ waitingMsg }</div>
                }

                {loading &&
                <div className={classes.overlay} />
                }
            </div>

            { children }
        </Fragment>
    );
}

export default WaitingView;