export const UPDATE_LIST_SALE_PACKAGES = 'home/salePackages/UPDATE'

export const UPDATE_LIST_FEATURES = 'home/features/UPDATE'

export const UPDATE_LOADING = 'home/loading/UPDATE'

export const UPDATE_STEPS = 'home/steps/UPDATE'

export const UPDATE_HOVERED_PACKAGE = 'home/hovered/UPDATE'

export const UPDATE_SELECTED_PACKAGE = 'home/selected/UPDATE'

export const UPDATE_ORDER_DETAIL = 'home/oderDetail/UPDATE'

export const UPDATE_CURRENT_STEP = 'home/currentStep/UPDATE'

export const UPDATE_MESSAGE = 'home/message/UPDATE'

export const UPDATE_PERIODS = 'home/period/UPDATE'

export const UPDATE_CARDS = 'home/cards/UPDATE'

export const UPDATE_SUBSCRIPTION_DETAIL = 'home/subscriptionDetail/UPDATE'

export const UPDATE_CURRENT_ACTIVE_USERS_SUBSCRIPTION = 'home/currentActiveUsersSubscription/UPDATE'

export const UPDATE_REVIEW_PLAN = 'home/reviewPlan/UPDATE'

export const UPDATE_PRE_CHECK_PAYMENT = 'home/preCheckPayment/UPDATE'

export const UPDATE_REMOVE_USERS = 'home/extendSubscription/UPDATE_REMOVE_USERS'

export const CLEAR_HOME_DATA = 'home/store/CLEAR'

export const UPDATE_EXIST_SUBSCRIPTION = 'home/cards/UPDATE_EXIST_SUBSCRIPTION'

const defaultState = {
    salePackages: [],
    periods: [],
    features: [],
    hoveredPackage: false,
    selectedPackage: false,
    orderDetail: false,
    steps: [],
    currentStep: 0,
    reviewPlan: {},
    loading: true,
    message: false,
    cards: [],
}

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_LIST_SALE_PACKAGES:
            return {
                ...state,
                salePackages: action.data,
                hoveredPackage: !state.hoveredPackage && action.data.length > 0 ? action.data[0] : false
            }

        case UPDATE_LIST_FEATURES:
            return {
                ...state,
                features: action.data
            }

        case UPDATE_LOADING:
            return {
                ...state,
                loading: action.data
            }

        case UPDATE_STEPS:
            return {
                ...state,
                steps: action.data
            }

        case UPDATE_HOVERED_PACKAGE:
            return {
                ...state,
                hoveredPackage: action.data
            }

        case UPDATE_SELECTED_PACKAGE:
            return {
                ...state,
                // currentStep: action.data && state.currentStep <= 1 ? 1 : 0,
                // hoveredPackage: state.salePackages.length > 0 ? state.salePackages[0] : false,
                selectedPackage: action.data,
                orderDetail: action.data ? state.orderDetail : false
            }

        case UPDATE_CURRENT_STEP:
            return {
                ...state,
                currentStep: action.data
            }

        case UPDATE_MESSAGE:
            return {
                ...state,
                message: action.data
            }

        case UPDATE_ORDER_DETAIL:
            return {
                ...state,
                orderDetail: {
                    ...state.orderDetail,
                    ...action.data
                }
            }

        case UPDATE_PERIODS:
            return {
                ...state,
                periods: action.data ? action.data : []
            }

        case UPDATE_CARDS:
            return {
                ...state,
                cards: action.data
            }

        case UPDATE_SUBSCRIPTION_DETAIL:
            return {
                ...state,
                subscriptionDetail: action.data
            }

        case UPDATE_CURRENT_ACTIVE_USERS_SUBSCRIPTION:
            return {
                ...state,
                currentActiveUsersSubscription: action.data
            }

        case UPDATE_REVIEW_PLAN:
            const reviewPlanData = {
                ...action.data,
                paymentPlanIds: [ state.selectedPackage.id, action.data.periodId ],
                selectedPeriod: state.periods.filter(p => p.id === action.data.periodId)[0]
            }

            return {
                ...state,
                reviewPlan: reviewPlanData
            }


        case UPDATE_PRE_CHECK_PAYMENT:
            return {
                ...state,
                preCheckPayment: action.data
            }

        case UPDATE_REMOVE_USERS:
            return {
                ...state,
                removeUsers: action.data
            }

        case UPDATE_EXIST_SUBSCRIPTION:
            return {
                ...state,
                subscriptions: action.data
            }

        case CLEAR_HOME_DATA:
            return {
                ...defaultState
            }

        default:
            return state
    }
}

export const updateSalePackages = (salePackages) => {
    return { type: UPDATE_LIST_SALE_PACKAGES, data: salePackages }
}

export const updateFeatures = (features) => {
    return { type: UPDATE_LIST_FEATURES, data: features }
}

export const setLoading = (loading) => {
    return { type: UPDATE_LOADING, data: loading }
}

export const updateSteps = (steps) => {
    return { type: UPDATE_STEPS, data: steps }
}

export const updateHoveredPackage = (iHoveredPackage) => {
    return { type: UPDATE_HOVERED_PACKAGE, data: iHoveredPackage }
}

export const updateSelectedPackage = (selectedPackage) => {
    return { type: UPDATE_SELECTED_PACKAGE, data: selectedPackage }
}

export const updateCurrentStep = (currentStep) => {
    return { type: UPDATE_CURRENT_STEP, data: currentStep }
}

export const updateRemoveUsers = (userIds) => {
    return { type: UPDATE_REMOVE_USERS, data: userIds }
}

/**
 * @param message - { code: ..., message: ..., messageKey: ... }
 */
export const updateMessage = (message) => {
    return { type: UPDATE_MESSAGE, data: message }
}

export const updateOrderDetail = (order) => {
    return { type: UPDATE_ORDER_DETAIL, data: order }
}

export const updateSubscriptionDetail = (sub) => {
    return { type: UPDATE_SUBSCRIPTION_DETAIL, data: sub }
}

export const updateReviewPlan = (data) => { // { periodId, numberOfUsers }
    return { type: UPDATE_REVIEW_PLAN, data }
}

export const clearHomeData = () => {
    return { type: CLEAR_HOME_DATA }
}

export default reducer