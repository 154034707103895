import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import dataProvider from '../../../../dataProvider'
import { GET_LIST } from 'react-admin'
import { UPDATE_LOADING, UPDATE_USER_LIST } from './multipleUserSelectionReducer'

export const COMPONENT_MULTIPLE_USER_SEARCH = 'COMPONENT_MULTIPLE_USER_SEARCH'

const searchUsersApi = (body) => {
    return dataProvider(GET_LIST, 'users', body)
}

function* searchUsers(action) {
    yield put({ type: UPDATE_LOADING, data: true })
    const { data } = yield call(searchUsersApi, action.payload)
    // log.debug('Search user done ', data)
    yield put({ type: UPDATE_USER_LIST, data: data })
    yield put({ type: UPDATE_LOADING, data: false })
}

function* mySaga() {
    yield takeLatest(COMPONENT_MULTIPLE_USER_SEARCH, searchUsers)
}

export default mySaga