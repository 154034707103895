import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { BASE_URL } from "../dataProvider";
import httpClient from "../dataProvider/httpClient";
import LocalStorage, { SCOPE_CUSTOMER, SCOPE_VKEY } from '../utils/services/storage';
import {
    updateDashboardView as updateDashboardViewAction,
    updateScope as updateScopeAction,
    updateSupportServiceFeatures as updateSupportServiceFeaturesAction
} from './menuReducers';
import CustomerIdmMenu from './menus/CustomerIdmMenu';
import CustomerMyAccountMenu from "./menus/CustomerMyAccountMenu";
import VKeyIdmMenu from './menus/VKeyIdmMenu';
import log from "../utils/log";

const styles = makeStyles({
    active: {
        backgroundColor: '#533475',
    },
    default: {
        color: 'white'
    },
    contextContent: {
        position: 'fixed',
        bottom: 15,
        color: 'white',
        fontSize: '1.2rem',
        // padding: 16,
        maxWidth: 250,
        textAlign: 'center',
        width: '100%'
    }
})

function Menu (props) {
    let { onMenuClick, logout, dashboard, scope, history } = props;
    // const { permissions } = usePermissions();
    // useLayoutEffect(() => {
    //     dashboard = dashboard || IDM_DASHBOARD;
    // })
    scope = scope || LocalStorage.instance.getCurrentScope();
    // dashboard = dashboard ? dashboard : window.location.pathname.indexOf("/my-account") !== -1 ? MY_ACC_DASHBOARD : IDM_DASHBOARD;
    let originalScope = LocalStorage.instance.getOriginalScope()
    let currentScope = scope || LocalStorage.instance.getCurrentScope()
    const location = useLocation();

    useEffect(() => {
        try{
            const customerId = LocalStorage.instance.getCustomerId();
            if( customerId ){
                // dashboard = dashboard ? dashboard : window.location.pathname.indexOf("/my-account") !== -1 ? MY_ACC_DASHBOARD : IDM_DASHBOARD;
                httpClient(`${BASE_URL}/billing/${LocalStorage.instance.getCustomerId()}/check/support_features`, {
                    method: 'GET'
                }).then(res => {
                    log.debug(res)
                    props.updateSupportServiceFeatures(res.json)
                }).catch(e => log.error(e))
            }
        } catch(e) {
            log.debug(e);
        }
    }, [])

    return (
        <div id="left-menu" style={{marginTop: "15px"}}>
            {' '}
            {/*{<DashboardMenuItem onClick={onMenuClick} style={styles.default} activeStyle={styles.active} />}*/}

            { location.pathname.indexOf("/my-account") === -1 &&
             originalScope === SCOPE_VKEY && currentScope === SCOPE_CUSTOMER &&
                <CustomerIdmMenu originalScope={originalScope} currentScope={currentScope} />
            }
            { location.pathname.indexOf("/my-account") === -1 &&
            originalScope === SCOPE_CUSTOMER && scope === originalScope &&
                <CustomerIdmMenu originalScope={originalScope} currentScope={currentScope} />
            }
            {  location.pathname.indexOf("/my-account") === -1 &&
            originalScope === SCOPE_VKEY && currentScope === originalScope &&
                <VKeyIdmMenu originalScope={originalScope} currentScope={currentScope} />
            }

            {  location.pathname.indexOf("/my-account") !== -1  &&
                <CustomerMyAccountMenu originalScope={originalScope} currentScope={scope} />
            }

        </div>
    );
}


const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    serviceLaunchData: state.serviceLaunchData,
    scope: state.menu.scope,
    dashboard: state.menu.dashboard
});

export default connect(
    mapStateToProps,
    {
    updateScope: updateScopeAction,
    updateDashboardView: updateDashboardViewAction,
    updateSupportServiceFeatures: updateSupportServiceFeaturesAction
})(Menu)