import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import arrayMutators from 'final-form-arrays';
import { TextField } from "mui-rff";
import React, { Fragment, useEffect, useState } from "react";
import { useNotify, useRedirect } from 'react-admin';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router-dom';
import VkeyFormToolbar from "../components/VkeyFormToolbar";
import httpClient, { API_URL } from "../dataProvider/httpClient";
import log from "../utils/log";
import LocalStorage from "../utils/services/storage";
import FGConnectors from "./component/FGConnectors";
import FGSubscriptionTokenPack from "./component/FGSubscriptionTokenPack";
import ServiceTemplateList from "./component/ServiceTemplateList";
import WaitingView from "./component/WaitingView";
import { cleanError, postCreateConnector } from "./epics/rootEpic";
import store from "./epics/store";
import { ConfirmDialog, WarningConnectorDialog } from "./ServiceLaunchForm";

const styles = makeStyles((theme) => ({
    root: {

    }
}))


const validate = (values) => {
    log.info("Inside validate ", values)
    const errors = {};
    ['name'].forEach(field => {
        if (!values[field]) {
            errors[field] = ['Required field'];
        }
    });

    log.info("Validate error = ", errors)
    const finalErrors = Object.keys(errors).length ? errors : connectorValidate(values)
    return finalErrors
}

const connectorValidate = async values => {
    const { json } = await httpClient(`${API_URL}/service-mgmt/service-launch/validate-create-service`, {
        method: "POST",
        body: JSON.stringify(values)
    })

    if (values.force === 'false' && json.success == false)
        return { force: `The connector ${json.usedConnectors[0].connectorName} is using for service ${json.usedConnectors[0].serviceInstanceName} now. 
        At the same time, one connector is only assigned for one service only. 
        If you assign it to new service, the last assignment will be removed automatically. 
        The old service will no longer work correctly.` }
}

const CreateForm:React.FC<{ record?: any }> = ((props) => {

    const { templateId } = useParams<{templateId: string}>()
    const redirectTo = useRedirect()
    const [loading, setLoading] = useState(false)
    const [loadingElem, setLoadingElem] = useState("")
    const [serviceInstance, setServiceInstance] = useState<any>(null)
    const [template, setTemplate] = useState({} as any)
    const notify = useNotify()
    const [isNoSubOrToken, setIsNoSubOrToken] = useState({
        open: false,
        error: "",
    });
    const setSubscription = (open, error) => {
        if (!loading) {
            setIsNoSubOrToken({open: open, error: error})
        }
    }

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            setLoading(store.getState().serviceLaunch.loading)
            setLoadingElem(store.getState().serviceLaunch.loadingElem)
            setServiceInstance(store.getState().serviceLaunch.serviceInstance)
            setTemplate(store.getState().serviceLaunch.serviceTemplate)
            if (store.getState().serviceLaunch.error !== "") {
                notify(store.getState().serviceLaunch.error, "warning")
                log.warn(store.getState().serviceLaunch.error)
                store.dispatch(cleanError())
            }

        })

        return () => {
            unsubscribe()
        }
    }, [])

    useEffect(() => {
        if (serviceInstance && serviceInstance.id) {
            redirectTo('/service-launch/' + serviceInstance.id)
        }
    }, [serviceInstance])

    const onSubmit = values => {
        const {connectors} = store.getState().serviceLaunch
        for (let i=0; i<values.connectors.length; i++) {
            if (values.connectors[i].id === 'NEW')
            {
                values.connectors[i]["name"] = connectors[values.connectors[i].type][0].name
            }
        }
        store.dispatch(postCreateConnector(values))
    }

    return (
        <Fragment>
            <WaitingView loading={loading && loadingElem === 'root'}>
                <Form
                    initialValues={{ serviceTemplateId: templateId, customerId: LocalStorage.instance.getCustomerId() }}
                    onSubmit={onSubmit}
                    mutators={{
                        ...arrayMutators
                    }}
                    validate={validate}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <label className={"MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-filled"}
                                       style={{ marginBottom: 8 }}>Overview</label>
                                { template && <div style={{ width: '60%', marginBottom: 24, fontSize: '0.75em', color: 'rgba(0, 0, 0, 0.54)' }}>
                                    { template.shortDescription }
                                </div> }

                                <div style={{ width: '60%' }}>
                                    <FGSubscriptionTokenPack setSubscription={setSubscription}/>
                                    <TextField name={"name"} label={"Name"} style={{ marginBottom: "16px" }} />
                                    <FGConnectors />
                                    <ConfirmDialog open={isNoSubOrToken.open} error={isNoSubOrToken.error}/>
                                </div>

                            </div>
                            <Field name="force" allowNull={true} defaultValue={"false"} >
                                {({ input, meta }) => (
                                    <>
                                        <div style={{ display: 'none' }}>
                                            <label>Force</label>
                                            <input {...input} type="text" placeholder="Force" />
                                            {meta.error && meta.touched && <span>{meta.error}</span>}
                                            <WarningConnectorDialog open={!!meta.error} error={meta.error} record={props.record} />
                                        </div>
                                        <div>
                                            {meta.error && <span style={{ fontSize: "0.875em", color: "red" }}>{"* " + meta.error}</span>}
                                        </div>
                                    </>
                                )}
                            </Field>

                            <VkeyFormToolbar style={{display: 'flex', margin: "16px -16px -24px -16px" }}
                                             handleSubmitWithRedirect={obj => {
                                                 form.submit()
                                             }} record={values}
                                             basePath={"/service-launch/create"}
                                             // redirect={"list"}
                            />
                        </form>
                    )}
                />
            </WaitingView>
        </Fragment>
    )
})

const ServiceInstanceCreate = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>((props, ref) => {
    const { templateId } = useParams<{templateId: string}>()
    const redirectTo = useRedirect()
    const classes = styles()

    return (
        <div style={{ height: '100%', position: "relative", marginTop: 16 }}>
            { !templateId && <ServiceTemplateList onSelect={tID => redirectTo(`/service-launch/create/${tID}`)} /> }
            { templateId &&
                <Fragment>

                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <CreateForm />
                        </CardContent>
                    </Card>

                </Fragment>
            }
        </div>
    )
})

export default ServiceInstanceCreate