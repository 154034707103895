import deepSet from '../utils/deepSet';
import updateCommon from '../utils/updateCommon';
import log from "../../utils/log";

const LOAD = 'theme/edit/LOAD';
// Using when color/image is picked and change value from store
const UPDATE_PROPERTY = 'theme/edit/UPDATE_PROPERTY';
const COLOR_PICKER_UPDATE = 'theme/edit/COLOR_PICKER_UPDATE';
const UPDATE_TEXT = 'theme/edit/TEXT_UPDATE';
const IMAGE_PONDS = 'theme/edit/IMAGE_PONDS';
const ADD_IMAGE = 'theme/edit/ADD_IMAGE';
const SAVE = 'theme/edit/SAVE';

export const PROPERTY_TYPE_COLOR = 'COLOR';
export const PROPERTY_TYPE_TEXT = 'TEXT';
const COMMON_TAB_ID = 'commonDefs';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD:
            //// log.debug('Inside LOAD ', state, action.data);
            const screens = action.data && action.data.themeDefinition ? action.data.themeDefinition.screenDefs : [];
            if (screens.findIndex(item => item.id === 'common') < 0) {
                screens.unshift(action.data.themeDefinition.commonDefs);
            }

            return {
                ...state,
                data: action.data
            };

        case SAVE:
            return {
                ...state,
                save: {
                    ...state.images,
                    ...action.data
                }
            };

        case UPDATE_PROPERTY:
            // // log.debug('Inside UPDATE_PROPERTY state: ', state, action.data);
            // Only update property in state.data
            const newData = { ...state.data };
            const { type, value } = action.data;
            let path = '',
                screenIds = '',
                id = '';

            // // log.debug('color picker state ', state);

            switch (type) {
                case PROPERTY_TYPE_COLOR:
                    ({ id, path, screenIds } = state.colorPicker);
                    break;
                case PROPERTY_TYPE_TEXT:
                    //// // log.debug('path of type text ');
                    break;
                default:
                    log.warn(`Update property type ${type} is on processing`);
                    break;
            }
            // // log.debug('id path screen ', id, path, screenIds);
            if (screenIds === COMMON_TAB_ID || screenIds === 'common') {
                updateCommon(newData, id, value);
            }

            deepSet(newData, path, value);

            return {
                ...state,
                data: {
                    ...state.data,
                    ...newData
                }
            };

        // All all about color picker
        case COLOR_PICKER_UPDATE:
            {
                const newData = { ...state.data };
                const { path, value, id, screenIds } = action.data;

                if (screenIds === COMMON_TAB_ID || screenIds === 'common') {
                    updateCommon(newData, id, value);
                }
                deepSet(newData, path, value);

                return {
                    ...state,
                    colorPicker: {
                        ...state.colorPicker,
                        ...action.data
                    }
                };
            }
        case UPDATE_TEXT:
            // log.debug('Inside UPDATE_TEXT state: ', state, action.data);
            const textData = { ...state.data }; // Clone data object
            const { textPath, textValue } = action.data;
            // log.debug("textPath:", textPath)
            // log.debug("textValue:",textValue)
            deepSet(textData, textPath, textValue);

            return {
                ...state,
                updateText: {
                    ...state.updateText,
                    ...action.data
                }
            };

        case IMAGE_PONDS:
            log.debug('Inside add IMAGE_PONDS action data', action.data);

            return {
                ...state,
                imagePonds: {
                    ...state.imagePonds,
                    ...action.data
                }
            };

        case ADD_IMAGE:
            log.debug('Inside IMAGE state ', action.data);
            let newImg = { ...state.images }; // Clone data object
            const { iconData, iconPath } = action.data;

            if (iconData && iconPath) {
                newImg[iconPath] = iconData;
            }
            return {
                ...state,
                images: {
                    ...state.images,
                    ...newImg
                }
            };

        default:
            return state;
    }
};

/**
 * Simulates data loaded into this reducer from somewhere
 */
export const load = data => {
    // // log.debug('inside load ', data);
    return { type: LOAD, data };
};

export const save = data => {
    return { type: SAVE, data };
};

/**
 * Using when color/image is picked and change value from store
 */
export const updateProperty = (type, value) => {
    // // // log.debug('Inside updateProperty ', UPDATE_PROPERTY, type, value);
    return { type: UPDATE_PROPERTY, data: { type, value } };
};

/**
 * Change store state to display colorPicker
 * @param {*} payload 
 */
export const colorPicker = payload => {
    // // log.debug('Inside colorPicker payload', payload);
    return { type: COLOR_PICKER_UPDATE, data: payload };
};

export const updateText = payload => {
    // //// // log.debug('Inside updateText payload', payload);
    return { type: UPDATE_TEXT, data: payload };
};

export const updateImagePonds = payload => {
    // //// // log.debug('Inside updateImagePonds payload', payload);
    return { type: IMAGE_PONDS, data: payload };
};

export const addImage = payload => {
    // //// // log.debug('Inside add image payload', payload);
    return { type: ADD_IMAGE, data: payload };
};

export default reducer;