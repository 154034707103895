

export const portalTheme = {
    palette: {
        secondary: {
            light: '#5f5fc4',
            main: '#5a457d',
            dark: '#001064',
            contrastText: '#fff',
        },
        success: {
            light: '#5f5fc4',
            main: 'white',
            dark: '#001064',
            contrastText: '#fff',
        }
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: 'sans-serif',
    },
    font: {
        default: 'sans-serif'
    },
    overrides: {
        MuiDrawer: {
            docked: {
                background: 'linear-gradient(to bottom, #8a73af, #6c5986)',
                minHeight: '100vh',
                boxShadow: 'rgba(0, 0, 0, .2) 5px 0px 10px 0px',
                marginRight: 10,
            }
        },
        MuiFormControl: {
            root: {
                minWidth: '50%'
            }
        },
        FormInput: {
            input: { width: '50%' }
        },
        MuiTableCell: {
            head: {
                padding: '0 12px !important',
                fontSize: '.9rem'
            },
            paddingNone: {
                padding: '0 12px !important'
            }
        },
        MuiMenuItem: {
            root: {
                // color: 'white'
            }
        },
        MuiListItemText: {
            root: {
                color: '#ffffff'
            },
            primary: {
                color: '#ffffff'
            },
            secondary: {
                color: '#ffffff'
            }
        },
        MuiListItemIcon: {
            root: {
                color: '#ffffff'
            }
        },
        BulkActionsToolbar: {
            toolbarCollapsed: {
                zIndex: 0
            }
        },
        MuiToolbar: {
            dense: {
                minHeight: 58
            }
        },
        MuiAppBar: {
            root: {
                // background: 'none !important',
                // boxShadow: 'unset'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'unset'
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '.8rem',
                marginTop: 0,
                top: 0
            }
        },
        MuiStepLabel: {
            active: {
                color: 'rgb(63, 81, 181) !important'
            },
            label: {
                color: 'white',
                fontSize: '1rem'
            }
        }
    },
    menu: {
        default: {
            color: '#ffffff'
        },
        active: {
            backgroundColor: '#564f60'
        }
    },
    Login: {
        loginContent: {
            backgroundColor: '#2f1f3b80',
            padding: '30px 60px',
            borderRadius: 5,
            textAlign: 'center',
            width: 'auto',
        },
    },
    SignUp: {
        leftPanel: {
            root: {
                minHeight: 300,
                // backgroundColor: 'white',
                marginRight: 20,
                // borderRight: '1px solid black',
                zIndex: 3,
                color: 'white',
                paddingRight: '7em'
            },
            title: {
                fontSize: '1.8rem',
                fontFamily: '-webkit-body',
                fontWeight: 400
            },
            description: {
                marginTop: '2em',
                fontSize: '1.2rem',
                lineHeight: 1.5,
                letterSpacing: '1.1px',
                fontFamily: 'Roboto-Light'
            }
        }
    },
    tooltip: {
        fontSize: '13px',
        backgroundColor: 'white !important',
        color: '#545b64 !important',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        maxWidth: 500,
        padding: 10
    },
    button: {
        borderColor: '#ccc',
        textColor: '#fff',
        textDisabledColor: '#555'
    },
    card: {
        cardColor: '#F2F2F2', //  '#2C1541', // Card background internal color,
        cardDivider: '#7E7980' // Gray color
    },
    orginalCard: {
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    },
    MuiFilledInput: {
        root: {
            backgroundColor: 'none'
        }
    }
};