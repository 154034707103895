import React, {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {Link, MenuItem} from "@material-ui/core";
import {Select} from "mui-rff";
import lottieWeb, {AnimationItem} from "lottie-web";
import * as animationData from "./field-loading.json";
import {SelectProps} from "mui-rff/src/Select";
import {useForm} from "react-final-form";

const styles = makeStyles(theme => ({
    root: {
        position: "relative",
        marginBottom: theme.spacing(2)
    },
    waitingAnim: {
        position: "absolute",
        bottom: 0,
        right: 10,
        width: 48,
        zIndex: 1,
        color: 'black'
    }
}))


export interface LoadingSelectData {
    label: string,
    value: string,
    subtitle1?: string,
    subtitle2?: string
}

interface LoadingSelectProps extends SelectProps {
    loading?: boolean,
    options?: Array<LoadingSelectData>,
    handleChange?: (value) => void,
    onCreateNew?: (name: string) => void
}

const LoadingSelect = React.forwardRef<HTMLSelectElement, LoadingSelectProps>
(({loading, onCreateNew, options = [], ...props}, ref) => {

    const classes = styles()
    const [elemWaitingId, setElemWaitingId] = useState("waiting-" + props.name)
    const [anim, setAnim] = useState<AnimationItem>()
    const [stateOptions, setStateOptions] = useState(new Array<LoadingSelectData>())
    const currentForm = useForm()
    const isEqualOpts: (arr1: Array<LoadingSelectData>, arr2: Array<LoadingSelectData>) => boolean = (arr1, arr2) => {
        let result = true
        if (arr1.length !== arr2.length)
            return false

        for (let i = 0; i < arr1.length; i++) {
            result = result && (arr1[i].value === arr2[i].value)
        }
        return result
    }

    useEffect(() => {
        const container = document.getElementById(elemWaitingId);
        if (container != null) {
            const tempAnim = lottieWeb.loadAnimation({
                container: container, // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: (animationData as any).default,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            })
            tempAnim.hide()
            setAnim(tempAnim)
        }

        return () => {
            if (anim)
                anim.destroy()
        }
    }, [])

    useEffect(() => {
        if (anim) {
            if (loading) {
                anim.show()
            } else {
                anim.hide()
            }
        }
    }, [loading])

    useEffect(() => {
        if (options.length > 0 && !isEqualOpts(options, stateOptions)) {
            setStateOptions(options)
            currentForm.batch(() => {
                if (props.name.indexOf("connectors") < 0)
                    currentForm.change(props.name, options[0].value)
            })
        }
    }, [options])

    return (
        <div className={clsx(props.className, classes.root)}>
            <Select displayEmpty={true} {...props} disabled={loading}
                    style={{marginBottom: onCreateNew ? '0px' : '8px', zIndex: 1}} ref={ref}>
                {
                    stateOptions.map((opt, index) =>
                        <MenuItem value={opt.value} key={opt.value}>{opt.label}</MenuItem>)
                }
            </Select>
            <div id={elemWaitingId} className={classes.waitingAnim}></div>
            {onCreateNew &&
            <Fragment>
                <Link href="#" variant="body2"
                      onClick={(e) =>
                          onCreateNew ? onCreateNew(props.name) : e.preventDefault()}
                >
                    {'Create new'}
                </Link>
            </Fragment>
            }
        </div>
    )
})

export default LoadingSelect