import React, { Fragment } from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';


const OIDCReferenceInput = props => (
    <Fragment>
        <ReferenceInput {...props} style={{ width: '100%'}} >
            <SelectInput optionText="clientName" />
        </ReferenceInput>
    </Fragment>
);

export default OIDCReferenceInput;