import { DELETE_MANY, fetchUtils, UPDATE_MANY } from "react-admin";
import LocalStorage from "../utils/services/storage";
import { convertDataRequestToHTTP, convertHTTPResponse } from "./common";
import log from '../utils/log';

export const ACTION_GENERATE = "ACTION_GENERATE";

export const ACTION_SEND_MAIL = "ACTION_SEND_MAIL";

export const ACTION_GET_LIST_GROUP = "ACTION_GET_LIST_GROUP";

export const ACTION_RE_GENERATE = "ACTION_RE_GENERATE";

export const ACTION_DOWNLOAD_ZIP = "ACTION_DOWNLOAD_ZIP";

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    const customConvertRequest = (type, resource, params) => {
        let url = "";
        let options = {};
        switch (type) {
            case ACTION_GENERATE:
                url = `${apiUrl}/${resource}/generate/${LocalStorage.instance.getCustomerId()}/${
                    params.id
                }`;
                options.method = "PUT";
                break;

            case ACTION_SEND_MAIL:
                url = `${apiUrl}/${resource}/${LocalStorage.instance.getCustomerId()}/send-mail`;
                options.body = JSON.stringify(params.data);
                options.method = "POST";
                break;

            case ACTION_GET_LIST_GROUP:
                url = `${apiUrl}/${resource}/${LocalStorage.instance.getCustomerId()}/get-token-pack-ldap-group/${
                    params.id
                }`;
                break;

            case ACTION_RE_GENERATE:
                url = `${apiUrl}/${resource}/${LocalStorage.instance.getCustomerId()}/re-generate/${
                    params.data.tokenPackId
                }`;
                options.method = "PUT";
                options.body = JSON.stringify(params.data);
                break;

            case ACTION_DOWNLOAD_ZIP:
                url = `${apiUrl}/${resource}/${LocalStorage.instance.getCustomerId()}/zip-token-pack-ac/${
                    params.data.tokenPackId
                }`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;

            default:
                let defaultResponse = convertDataRequestToHTTP(
                    apiUrl,
                    type,
                    resource,
                    params
                );
                url = defaultResponse.url;
                options = defaultResponse.options;
        }

        return { url, options };
    };

    const customConvertHTTPResponse = (response, type, resource, params) => {
        switch (type) {
            default:
                return convertHTTPResponse(response, type, resource, params);
        }
    };
    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "PUT",
                        body: JSON.stringify(params.data),
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map((id) =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "DELETE",
                    })
                )
            ).then((responses) => ({
                data: responses.map((response) => response.json),
            }));
        }

        const { url, options } = customConvertRequest(type, resource, params);
        return httpClient(url, options)
            .then((response) =>
                customConvertHTTPResponse(response, type, resource, params)
            )
            .catch((e) => {
                log.error("rest-api", resource, type, e);
                const errorJson = e.body;
                if (errorJson && errorJson.messageKey)
                    throw new Error(errorJson.messageKey);
                else throw new Error("error.default.internal.server.error");
            });
    };
};
